import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { AppTheme } from '../../../styles/AppTheme';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { Helper } from '../../../common/helpers/Helper';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { CommonTheme } from '../../../styles/Styles';
import { Controller } from '../../../common/Controller';
import { AppConfig } from '../../../AppConfig';
import TableCard from '../../../common/components/tables/TableCard';
import { FormControl, IconButton, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TabChartCard } from '../../../common/components/charts/TabChartCard';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { TabTableCard } from '../../../common/components/tables/TabTableCard';

const vsprintf = require('sprintf-js').vsprintf

function generateColor(fraction) {
  const minAlpha = 0.3;
  const alpha = Math.max((fraction * fraction).toFixed(2), minAlpha);
  return `rgba(90, 140, 245, ${alpha})`
}

function getPieChartColor(key, index, numKeys) {
  return generateColor(Number(index + 1) / numKeys)
}

function fillMissingReportData(data, count, dimension, defaultValues) {
  const newData = [];
  for (let i = 0; i < count; i++) {
    const found = data.find(item => parseInt(item[dimension]) === i);
    newData.push(found ? {...found, [dimension]: i.toString()} : { [dimension]: i.toString(), ...defaultValues });
  }
  return newData;
}

function filterReportDataTillCurrentHour(data) {
  const currentHour = new Date().getHours();
  return data.filter(item => parseInt(item.nthHour) <= currentHour);
}

function createCustomCategoryReport(report, fieldMapping) {
  const categoryTotals = fieldMapping.categories.reduce((acc, category) => {
    acc[category.title] = 0
    return acc
  }, {})

  report.data.forEach(data => {
    if(!data[fieldMapping.key]) {
      return
    }
    const sourceMedium = data[fieldMapping.key].split('/')
    const source = sourceMedium[0] ? sourceMedium[0].trim() : '*'
    const medium = sourceMedium[1] ? sourceMedium[1].trim() : '*'
    let matched = false
    for (const category of fieldMapping.categories) {
      if (category.values.includes(`${source} / ${medium}`)) {
        categoryTotals[category.title] += Number(data[fieldMapping.count])
        matched = true
        break
      }
    }

    if (!matched) {
      for (const category of fieldMapping.categories) {
        if (category.values.includes(`${source} / *`)) {
          categoryTotals[category.title] += Number(data[fieldMapping.count])
          matched = true
          break
        }
      }
    }
    if (!matched) {
      for (const category of fieldMapping.categories) {
        if (category.values.includes(`* / ${medium}`)) {
          categoryTotals[category.title] += Number(data[fieldMapping.count]);
          matched = true
          break
        }
      }
    }
    if (!matched) {
      for (const category of fieldMapping.categories) {
        if (category.values.includes('* / *')) {
          categoryTotals[category.title] += Number(data[fieldMapping.count])
          break
        }
      }
    }
  });

  const customReport = fieldMapping.categories
    .filter(category => categoryTotals[category.title] > 0)
    .sort((a, b) => categoryTotals[b.title] - categoryTotals[a.title])
    .map(category => {
      return {
        [fieldMapping.key]: category.title,
        [fieldMapping.count]: categoryTotals[category.title]
      }
    })

  return customReport
}

function generateChartTabsData(userCategory) {
  const chartTabsData = {
    lineChart: [
      {
        reportType: 'usersByTimeInterval',
        title: 'Visitors',
        count: userCategory,
        label: 'Visitors',
        defaultValues: {[userCategory]: '0'},
      },
      {
        reportType: 'screenPageViewsByTimeInterval',
        title: 'Total Pageviews',
        count: 'screenPageViews',
        label: 'Pageviews',
        defaultValues: {screenPageViews: '0'},
      },
    ],
    pieChart: [
      {
        reportType: 'usersBySessionSourceMedium',
        title: 'Visitors By Source',
        key: 'sessionSourceMedium',
        count: userCategory,
        label: 'Visitors',
        categories:[
          {
            title: 'Google / Organic',
            values: ['google / organic']
          },
          {
            title: 'Other / Organic',
            values: ['* / organic']
          },
          {
            title: 'Google / Search Ads',
            values: ['google / paidsearch', 'google / ppc', 'google / ad', 'google / cpc', 'adwords / ppc', 'adwords.corp.google.com / referral', 'adsensecustomsearchads.com / referral', 'syndicatedsearch.goog / referral', 'storage.googleapis.com / referral' ]
          },
          {
            title: 'LinkedIn / Ads',
            values: ['linkedin / psocial', 'linkedin / ad', 'LinkedInAds / *', 'onyx.www.linkedin.com / referral']
          },
          {
            title: 'Facebook / Ads',
            values: ['facebook / psocial', 'facebook / ad', 'fb / paid']
          },
          {
            title: 'Instagram / Ads',
            values: ['instagram / psocial', 'instagram / ad', 'ig / paid']
          },
          {
            title: 'Reddit / Ads',
            values: ['reddit / psocial', 'reddit / ad']
          },
          {
            title: 'Sequence / Email',
            values: ['mailsl / email']
          },
          {
            title: 'Broadcast / Email',
            values: ['bmailsl / email', 'bulkmailsl / email']
          },
          {
            title: 'Powered By / Referral',
            values: ['* / poweredby']
          },
          {
            title: 'Other / Referral',
            values: ['* / referral']
          },
          {
            title: 'LinkedIn / Social',
            values: ['linkedin / social', 'linkedin.com / referral', 'lnkd.in / referral', 'linkedin.com / *']
          },
          {
            title: 'Facebook / Social',
            values: ['facebook / social', 'facebook.com / referral', 'l.facebook.com / referral', 'm.facebook.com / referral', 'lm.facebook.com / referral']
          },
          {
            title: 'Instagram / Social',
            values: ['instagram / social', 'instagram.com / referral', 'l.instagram.com / referral']
          },
          {
            title: 'Other / Social',
            values: ['* / social']
          },
          // {
          //   title: 'Others',
          //   values: ['* / *']
          // }
        ]
      },
      {
        reportType: 'usersByCountry',
        title: 'Visitors By Country',
        key: 'country',
        count: userCategory,
        label: 'Visitors',
      },
      {
        reportType: 'usersByDeviceCategory',
        title: 'Visitors By Device',
        key: 'deviceCategory',
        count: userCategory,
        label: 'Visitors',
      },

    ],
    sourceTable: [
      {
        reportType: 'usersBySessionSourceMedium',
        title: 'Top UTM Sources',
        key: 'sessionSourceMedium',
        headers: ['Source', 'Visitors'],
      },
      {
        reportType: 'usersBySessionCampaignName',
        title: 'Top Campaigns',
        key: 'sessionCampaignName',
        headers: ['Campaign', 'Visitors'],
      },
    ]
  }
  return chartTabsData
}

function reportsToLineData(reports, reportType, fieldMapping, label) {
  const report = reports.find(report => report.key === reportType) || {};
  if(!report || Object.keys(report).length === 0) {
    return {}
  }
  let labels = report.data.map(b => b[fieldMapping.key]);
  if(fieldMapping.timeFrame) {
    if(fieldMapping.timeFrame === 'today') {
      report.data = fillMissingReportData(report.data, 24, fieldMapping.key, fieldMapping.defaultValues)
      report.data = filterReportDataTillCurrentHour(report.data)
      labels = Helper.getHoursIn12HourFormat()
    } else if(fieldMapping.timeFrame === 'yesterday') {
      report.data = fillMissingReportData(report.data, 24, fieldMapping.key, fieldMapping.defaultValues)
      labels = Helper.getHoursIn12HourFormat()
    } else if(fieldMapping.timeFrame === 'lastWeek') {
      report.data = fillMissingReportData(report.data, 7, fieldMapping.key, fieldMapping.defaultValues)
      labels = Helper.generatePreviousMonthsOrDays('days', 7)
    } else if(fieldMapping.timeFrame === 'lastMonth') {
      report.data = fillMissingReportData(report.data, 30, fieldMapping.key, fieldMapping.defaultValues)
      labels = Helper.generatePreviousMonthsOrDays('days', 30)
    } else if(fieldMapping.timeFrame === 'lastQuarter') {
      report.data = fillMissingReportData(report.data, 90, fieldMapping.key, fieldMapping.defaultValues)
      labels = Helper.generatePreviousMonthsOrDays('days', 90)
    }else if(fieldMapping.timeFrame === 'lastSixMonths') {
      report.data = fillMissingReportData(report.data, 7, fieldMapping.key, fieldMapping.defaultValues)
      report.data = report.data.slice(-6);
      labels = Helper.generatePreviousMonthsOrDays('months', 6)
    }else if(fieldMapping.timeFrame === 'lastYear') {
      report.data = fillMissingReportData(report.data, 13, fieldMapping.key, fieldMapping.defaultValues)
      report.data = report.data.slice(-12);
      labels = Helper.generatePreviousMonthsOrDays('months', 12)
    }
  }

  return {
    labels: labels,
    datasets: [
      {
        label,
        data:  report.data.map(b => b[fieldMapping.count]),
        backgroundColor: report.data.map((b, i) => generateColor(Number(i + 1) / Number(report.data.length))),
        borderWidth: 2,
        borderColor: 'rgba(90, 140, 245, 1)',
        segment: {
          borderDash: ctx => {
            return ((ctx.p1DataIndex === report.data.length - 1) && (fieldMapping.timeFrame !== 'yesterday')) ? [2,2] : []
          }
        },
      },
    ],
  }
}

function reportsToPieData(reports, reportType,fieldMapping, label) {
  const report = reports.find(report => report.key === reportType) || {};
  if(!report || Object.keys(report).length === 0 || !Array.isArray(report.data)) {
    return {}
  }

  let slicedData = report.data.slice(0, 10)

  if (fieldMapping.categories) {
    slicedData = createCustomCategoryReport(report, fieldMapping)
  }

  const reportsKeyOrder = slicedData.map(item => item[fieldMapping.key])
  const reportKeys = reportsKeyOrder.reduce((p, c) => p.concat(slicedData.filter(b => b[fieldMapping.key] === c)), [])
  const numProgressiveBucketKeys = Number(reportKeys.length)
  return {
    labels: slicedData.map(b => b[fieldMapping.key]),
    datasets: [
      {
        label,
        data: slicedData.map(b => b[fieldMapping.count]).slice(0,10),
        backgroundColor: slicedData.map((b, i) => getPieChartColor(b[fieldMapping.key], slicedData.length - 1 - i, numProgressiveBucketKeys)),
        borderWidth: 1,
      },
    ],
  }
}

function reportsToTopSourcesTableData(reports, reportType, userCategory) {
  const report = reports.find(report => report.key === reportType) || {};
  if(!report || Object.keys(report).length === 0) {
    return {}
  }
  const customReport =  report.data.sort((a, b) => b[userCategory] - a[userCategory])
    .filter(item => item.pageReferrer)
    .map(item => ({
      pageReferrer: Helper.removeQueryParams(item.pageReferrer).replace(/\/$/, ''),
      [userCategory]: item[userCategory] || 0,
    }))

  return {
    headers: ['Source', 'Visitors'],
    rows: customReport.map(item => Object.values(item))
  }
}

function reportsToSourceTableData(reports, reportType, fieldMapping, headers, userCategory) {
  const report = reports.find(report => report.key === reportType) || {};
  if(!report || Object.keys(report).length === 0) {
    return {}
  }

  let customReport =  report.data.sort((a, b) => b[userCategory] - a[userCategory])
    .map(item => ({
      source: item[fieldMapping.key]
        .replace(/\((none)\)|Unknown/g, '(unknown)')
        .replace(/\(direct\) \/ \(unknown\)/g, '(direct)'),
      [userCategory]: item[userCategory] || 0,
    }))

  customReport = customReport.filter((item)=> {
    if(item && item.source) {
      return item
    }
  })

  return {
    headers,
    rows: customReport.map(item => Object.values(item))
  }
}

function reportsToLandingPagesTableData(reports, reportType) {
  const report = reports.find(report => report.key === reportType) || {};
  if(!report || Object.keys(report).length === 0) {
    return {}
  }
  const customReport =  report.data.sort((a, b) => b.screenPageViews - a.screenPageViews)
    .map(item => ({
      landingPage: item.landingPage,
      pageViews: item.screenPageViews || 0,
      userEngagementDuration: Math.floor(item.userEngagementDuration / (item.activeUsers || 1)) || 0,
      bounceRate: Math.floor((item.bounceRate || 0) * 100) + '%',
      users: item.totalUsers || 0,
      new: (Math.floor((item.newUsers / item.totalUsers) * 100) || 0) + '%',
      active: (Math.floor((item.activeUsers / item.totalUsers) * 100) || 0) + '%',
    }))
  return {
    headers: ['Landing Page', 'Page Views', 'Duration (seconds)', 'Bounce Rate', 'Users', 'New', 'Active'],
    rows: customReport.map(item => Object.values(item))
  }
}

export class DashboardOrg extends BaseEntityPage {
  constructor(props) {
    super(props);
    this.orgId = this.props.match.params.orgId
    this.pageTitle = 'Dashboard'
    this.pageKey = 'orgDashboard'
    this.startDate = 'lastMonth'
    this.userCategory = 'totalUsers'
    this.landingPage = ''
    this.mauMap = {
      today: 'nthHour',
      yesterday: 'nthHour',
      lastWeek: 'nthDay',
      lastMonth: 'nthDay',
      lastQuarter: 'nthDay',
      lastSixMonths: 'nthMonth',
      lastYear: 'nthMonth',
    }
    this.timeFrames = [
      {
        key: 'today',
        label: 'Today'
      },
      {
        key: 'yesterday',
        label: 'Yesterday'
      },
      {
        key: 'lastWeek',
        label: 'Last 7 days'
      },
      {
        key: 'lastMonth',
        label: 'Last 30 days'
      },
      {
        key: 'lastQuarter',
        label: 'Last 90 days'
      },
      {
        key: 'lastSixMonths',
        label: 'Last 6 months'
      },
      {
        key: 'lastYear',
        label: 'Last 12 months'
      },
    ]
    this.userCategories = [
      {
        key: 'totalUsers',
        label: 'All Visitors'
      },
      {
        key: 'activeUsers',
        label: 'Active Visitors'
      },
      {
        key: 'newUsers',
        label: 'New Visitors'
      },
    ]
    this.xLabelsDifference = {
      today: 2,
      yesterday: 2,
      lastWeek: 1,
      lastMonth: 4,
      lastQuarter: 6,
      lastSixMonths: 1,
      lastYear: 2,
    }
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs', this.orgId], { pb: 'fieldDef' }) },
      { ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['miscs', 'get-web-analytics'], queryParams: { orgId: this.orgId, startDate: this.startDate, userCategory: this.userCategory, hideInternalTraffic: true, landingPage: this.landingPage } },
    ]
    this.noAdd = true
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDef;
    this.state.analyticsReports = results[1].reports || [];
    this.analyticsReportTypes = ['lineChart', 'pieChart', 'usersByPageReferrer', 'sourceTable', 'pageMetricsByLandingPage']
    this.state.startDate = this.startDate
    this.state.timeFrameSelected = this.timeFrames.find(timeFrame => timeFrame.key === this.startDate)
    this.state.userCategory = this.userCategory
    this.state.landingPage = this.landingPage
    this.state.userCategorySelected = this.userCategories.find(userCategory => userCategory.key === this.userCategory)
    this.state.chartTabsData = generateChartTabsData(this.state.userCategory)
    this.org = results[0]
    this.pageTitle = 'Welcome ' + this.org.title.toLocaleLowerCase() + ','
    this.state.selectedTabData = {
      lineChart: 0,
      pieChart: 0,
      sourceTable: 0,
    }
  }

  banner() {
    return(
      <div className={css(Styles.bannerOuterContainer)}>
        <div className={css(Styles.bannerTextContainer)}>
          <h2 className={css(Styles.bannerTitle)}>{Helper.getString('adminBannerTitle')}</h2>
          <p className={css(Styles.bannerDesc)}>{Helper.getString('adminBannerDesc')}</p>
          {/* <AppButton TODO: commented for now
            buttonStyle={[CommonStyles.buttonStyle, Styles.buttonStyle]}
            buttonTextStyle={[CommonStyles.buttonTextStyle, Styles.buttonTextStyle]}
            // onClick={() => this.getSupport()}
            buttonText={Helper.getString('getStarted')}/> */}
        </div>
        <div className={css(Styles.bannerImgOuterContainer)}>
          <div className={css(Styles.bannerImgContainer)}>
            <img src={require('../../../assets/images/bannerImg.svg')} className={css(Styles.imageContent)} alt={Helper.getString('adminBannerAlt')}/>
          </div>
        </div>
      </div>
    )
  }

  quickListItems() {
    let l1Tiles = []
    if(Controller.get().roleHelper().canEditOrg()) {
      l1Tiles = [
        { title: Helper.getString('cats'), link: vsprintf('/admin/orgs/%s/cats', [this.orgId]) },
        { title: Helper.getString('products'), link: vsprintf('/admin/orgs/%s/products', [this.orgId]) },
        { title: Helper.getString('lists'), link: vsprintf('/admin/orgs/%s/lists', [this.orgId]) },
        { title: Helper.getString('orders'), link: vsprintf('/admin/orgs/%s/orders', [this.orgId]) },

        { title: Helper.getString('pages'), link: vsprintf('/admin/orgs/%s/pages', [this.orgId]) },
        { title: Helper.getString('blocks'), link: vsprintf('/admin/orgs/%s/cborgs', [this.orgId]) },
        // { title: Helper.getString('blogtags'), link: vsprintf('/admin/orgs/%s/btags', [this.orgId]) }, //TODO: added all link here
        // { title: Helper.getString('bloglists'), link: vsprintf('/admin/orgs/%s/blists', [this.orgId]) },
        { title: Helper.getString('blogs'), link: vsprintf('/admin/orgs/%s/blogs', [this.orgId]) },

        // { title: Helper.getString('settings'), link: vsprintf('/admin/orgs/%s/settings', [this.orgId]) },
        { title: Helper.getString('admins'), link: vsprintf('/admin/orgs/%s/users', [this.orgId]) }
      ]
    } else if(Controller.get().roleHelper().hasCurrentOrgRole('BLOGGER')) {
      l1Tiles = [
        { title: Helper.getString('blogs'), link: vsprintf('/admin/orgs/%s/blogs', [this.orgId]) },
      ]
    } else if(Controller.get().roleHelper().hasCurrentOrgRole('CRM')) {
      l1Tiles = [
        { title: Helper.getString('crm-contacts'), link: vsprintf('/admin/orgs/%s/crm-contacts', [this.orgId]) },
        // { title: Helper.getString('crm-contacts'), link: vsprintf('/admin/orgs/%s/crm-contacts', [this.orgId]) },
      ]
    }
    return(
      <Grid container spacing={2}>
        {l1Tiles.map(tile => {
          return (
            <Grid item xs={6} sm={4}> {/* TODO: grid style changed since stats tiles commented */}
              <Link to={tile.link} className={css(Styles.link)}>
                <div className={css(Styles.quickListItemsContainer)}>
                  <p className={css(Styles.quickListTitleContainer)} >{tile.title}</p>
                </div>
              </Link>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  contactBanner() {
    return(
      <div className={css(Styles.bannerOuterContainer, Styles.contactBannerOuterContainer)}>
        <div className={css(Styles.bannerTextContainer, Styles.contactBannerTextContainer)}>
          <p className={css(Styles.contactBannerTitle)}>{Helper.getString('contactBannerTitle')}</p>
          <div className={css(Styles.contactUsBannerTitleContainer)}>
            {/* <Link target='_blank' to={AppConfig.SLIXTA_WEB + 'contact'} className={css(Styles.link)}> */}
            <h2 className={css(Styles.contactUsBannerTitle)}>{Helper.getString('contactUsForSupport')}</h2>
            {/* </Link> */}
            <div className={css(Styles.imageHandClickContainer)}>
              <img src={require('../../../assets/images/hand-click.svg')}
                className={css(Styles.imageContent)} alt={Helper.getString('handClickImgAlt')}/>
            </div>
          </div>
        </div>
        <div className={css(Styles.bannerImgOuterContainer)}>
          <div className={css(Styles.contactBannerImgContainer)}>
            <img src={require('../../../assets/images/contact-banner.svg')} className={css(Styles.imageContent)} alt={Helper.getString('adminBannerAlt')}/>
          </div>
        </div>
      </div>
    )
  }

  onDateRangeAutoCompleteChange = (value) => {
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      loadingMode: ApiHelper.LOADING_MODE.INSTANT_LOAD,
      timeFrameSelected : value,
      startDate: value.key,
    })
    ApiHelper.callAwait({
      ms:  AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['miscs', 'get-web-analytics'],
      queryParams: { orgId: this.orgId, startDate: value.key, userCategory: this.state.userCategory, hideInternalTraffic: true, landingPage: this.state.landingPage }
    }).then(result => {
      this.setState({
        analyticsReports: result.reports || [],
        fetchState: ApiHelper.State.READY,
        loadingMode: ApiHelper.LOADING_MODE.PAGE_LOAD,
      })
    }).catch(err => {
      this.setState({
        fetchState: ApiHelper.State.ERROR,
        loadingMode: ApiHelper.LOADING_MODE.PAGE_LOAD,
        errMsg: Helper.getErrorMsg(err)
      })
    })
  }

  onUserCategoryAutoCompleteChange = (value) => {
    const chartTabsData = generateChartTabsData(value.key)
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      loadingMode: ApiHelper.LOADING_MODE.INSTANT_LOAD,
      userCategorySelected : value,
      userCategory: value.key,
      chartTabsData: chartTabsData
    })
    ApiHelper.callAwait({
      ms:  AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['miscs', 'get-web-analytics'],
      queryParams: { orgId: this.orgId, startDate: this.state.startDate, userCategory: value.key, hideInternalTraffic: true, landingPage: this.state.landingPage},
    }).then(result => {
      this.setState({
        analyticsReports: result.reports || [],
        fetchState: ApiHelper.State.READY,
        loadingMode: ApiHelper.LOADING_MODE.PAGE_LOAD,
      })
    }).catch(err => {
      this.setState({
        fetchState: ApiHelper.State.ERROR,
        loadingMode: ApiHelper.LOADING_MODE.PAGE_LOAD,
        errMsg: Helper.getErrorMsg(err)
      })
    })
  }

  cancelTableDimensionFilter = () => {
    window.scrollTo({ top: 0, behavior: 'smooth'})
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      loadingMode: ApiHelper.LOADING_MODE.INSTANT_LOAD,
      landingPage: ''
    })
    ApiHelper.callAwait({
      ms:  AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['miscs', 'get-web-analytics'],
      queryParams: { orgId: this.orgId, startDate: this.state.startDate, userCategory: this.state.userCategory, hideInternalTraffic: true, landingPage: '' }
    }).then(result => {
      this.setState({
        analyticsReports: result.reports || [],
        loadingMode: ApiHelper.LOADING_MODE.PAGE_LOAD,
        fetchState: ApiHelper.State.READY,
      })
    }).catch(err => {
      this.setState({
        fetchState: ApiHelper.State.ERROR,
        loadingMode: ApiHelper.LOADING_MODE.PAGE_LOAD,
        errMsg: Helper.getErrorMsg(err)
      })
    })
  }

  onTableDimensionFilterSelected = (value) => {
    window.scrollTo({ top: 0, behavior: 'smooth'})
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      loadingMode: ApiHelper.LOADING_MODE.INSTANT_LOAD,
      landingPage: value
    })
    ApiHelper.callAwait({
      ms:  AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['miscs', 'get-web-analytics'],
      queryParams: { orgId: this.orgId, startDate: this.state.startDate, userCategory: this.state.userCategory, hideInternalTraffic: true, landingPage: value }
    }).then(result => {
      this.setState({
        analyticsReports: result.reports || [],
        loadingMode: ApiHelper.LOADING_MODE.PAGE_LOAD,
        fetchState: ApiHelper.State.READY,
      })
    }).catch(err => {
      this.setState({
        fetchState: ApiHelper.State.ERROR,
        loadingMode: ApiHelper.LOADING_MODE.PAGE_LOAD,
        errMsg: Helper.getErrorMsg(err)
      })
    })
  }

  handleTabChange = (tab, chartType) => {
    this.setState({
      selectedTabData: {
        ...this.state.selectedTabData,
        [chartType]: tab
      }
    })
  }

  renderBelowTable() {
    if(this.state.analyticsReports && this.state.analyticsReports.length > 0) {
      return(
        <div className={css(Styles.outerContainer)}>
          <div className={css(Styles.analyticsDashboardContainer)}>
            <div className={css(Styles.analyticsContainer)}>
              <div className={css(Styles.dateRangeContainer)}>
                <FormControl className={css(Styles.formControlMainFilters)} variant='outlined'>
                  <Autocomplete
                    value={this.state.timeFrameSelected}
                    options={this.timeFrames}
                    onChange={(e, val) => this.onDateRangeAutoCompleteChange(val)}
                    getOptionLabel={(option) => option.label || option.text}
                    getOptionSelected={(option, value) => option.key === value.key}
                    disableClearable
                    renderInput={params =>
                      <TextField
                        {...params}
                        variant={'standard'}
                        className={css(Styles.formControlFilters)}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          classes: {
                            root: css([Styles.textInputStyles, Styles.autoCompleteInputRoot]),
                            input: css(Styles.formInputStyle, Styles.textInput),
                          },
                        }} />}
                  />
                </FormControl>
                <FormControl className={css(Styles.formControlMainFilters)} variant='outlined'>
                  <Autocomplete
                    value={this.state.userCategorySelected}
                    options={this.userCategories}
                    onChange={(e, val) => this.onUserCategoryAutoCompleteChange(val)}
                    getOptionLabel={(option) => option.label || option.text}
                    getOptionSelected={(option, value) => option.key === value.key}
                    disableClearable
                    renderInput={params =>
                      <TextField
                        {...params}
                        variant={'standard'}
                        className={css(Styles.formControlFilters)}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          classes: {
                            root: css([Styles.textInputStyles, Styles.autoCompleteInputRoot]),
                            input: css(Styles.formInputStyle, Styles.textInput),
                          },
                        }} />}
                  />
                </FormControl>
                {(this.state.landingPage !== '') && (<div className={css(Styles.dimensionFilterSelectedContainer)}>
                  <span className={css(Styles.dimensionFilterSelectedLabel)}>{this.state.landingPage}</span>
                  <IconButton
                    onClick={this.cancelTableDimensionFilter}
                    color='#222222'
                    className={css(Styles.icon)}
                  >
                    <i className='material-icons-outlined' style={{ fontSize: 20 }}>close</i>
                  </IconButton>
                </div>)}
                {(this.state.loadingMode === ApiHelper.LOADING_MODE.INSTANT_LOAD) && UiHelper.itemLoadingView()}
              </div>
            </div>
            <div className={css(Styles.analyticsContainer, Styles.analyticsDataContainer)}>
              {
                this.analyticsReportTypes.map((analyticReportType, index)=>{

                  if(Object.keys(this.state.chartTabsData).includes(analyticReportType)) {
                    this.state.chartTabsData[analyticReportType].forEach((tab, index)=>{
                      const report = this.state.analyticsReports.find(report => report.key === tab.reportType) || {};
                      const total = (report.total || Number(report.total) === 0) ? report.total : undefined;
                      tab.total = total;
                    })
                  }

                  if(analyticReportType === 'lineChart') {
                    const chartData = reportsToLineData(this.state.analyticsReports,
                      this.state.chartTabsData.lineChart[this.state.selectedTabData.lineChart].reportType,
                      {key: this.mauMap[this.state.startDate], count: this.state.chartTabsData.lineChart[this.state.selectedTabData.lineChart].count, timeFrame: this.state.startDate, defaultValues: this.state.chartTabsData.lineChart[this.state.selectedTabData.lineChart].defaultValues},
                      this.state.chartTabsData.lineChart[this.state.selectedTabData.lineChart].label)

                    const xLabelsDifference = this.xLabelsDifference[this.state.startDate];
                    return ( <div className={css(Styles.lineStatItem)} key={index}>
                      <TabChartCard chartType={analyticReportType} tabsData={this.state.chartTabsData} chartData={chartData} xLabelsDifference={xLabelsDifference} selectedTabData={this.state.selectedTabData}  handleTabChange={this.handleTabChange}/>
                    </div>)
                  } else if(analyticReportType === 'pieChart') {
                    const chartData = reportsToPieData(this.state.analyticsReports,
                      this.state.chartTabsData.pieChart[this.state.selectedTabData.pieChart].reportType,
                      {key: this.state.chartTabsData.pieChart[this.state.selectedTabData.pieChart].key,
                        count: this.state.chartTabsData.pieChart[this.state.selectedTabData.pieChart].count,
                        categories: this.state.chartTabsData.pieChart[this.state.selectedTabData.pieChart].categories,
                      },
                      this.state.chartTabsData.pieChart[this.state.selectedTabData.pieChart].label)

                    const xLabelsDifference = this.xLabelsDifference[this.state.startDate];

                    return ( <div className={css(Styles.lineStatItem)} key={index}>
                      <TabChartCard chartType={analyticReportType} tabsData={this.state.chartTabsData} chartData={chartData} xLabelsDifference={xLabelsDifference} selectedTabData={this.state.selectedTabData}  handleTabChange={this.handleTabChange} showDataLabels={true}/>
                    </div>)
                  } else if(analyticReportType === 'usersByPageReferrer') {
                    return(
                      <div className={css(Styles.tableStatGridItem)} key={index}>
                        <TableCard item={{data: reportsToTopSourcesTableData(this.state.analyticsReports,'usersByPageReferrer', this.state.userCategory), title: 'Top Website Sources'}}/>
                      </div>
                    )
                  } else if(analyticReportType === 'sourceTable') {
                    const tableData = reportsToSourceTableData(this.state.analyticsReports, this.state.chartTabsData.sourceTable[this.state.selectedTabData.sourceTable].reportType,
                      {key: this.state.chartTabsData.sourceTable[this.state.selectedTabData.sourceTable].key},
                      this.state.chartTabsData.sourceTable[this.state.selectedTabData.sourceTable].headers,
                      this.state.userCategory)

                    return (<div className={css(Styles.tableStatGridItem)} key={index}>
                      <TabTableCard tableType={analyticReportType} tabsData={this.state.chartTabsData} tableData={tableData} selectedTabData={this.state.selectedTabData}  handleTabChange={this.handleTabChange} />
                    </div>)
                  } else if((analyticReportType === 'pageMetricsByLandingPage') && (this.state.landingPage === '')) {
                    return(
                      <div className={css(Styles.tableStatItem)} key={index}>
                        <TableCard item={{data: reportsToLandingPagesTableData(this.state.analyticsReports,'pageMetricsByLandingPage'), title: 'Landing Pages', onTableDimensionFilterSelected: this.onTableDimensionFilterSelected.bind(this)}}/>
                      </div>
                    )
                  }
                  return ''
                })
              }
            </div>
          </div>
        </div>
      )
    }
    return(
      <div className={css(Styles.outerContainer)}>
        {this.banner()}
        <div className={css(Styles.innerContainer)}>
          {/* <div className={css(Styles.firstContainer)}> TODO: stats commented for now
            <StatsInfo />
          </div> */}
          <div className={css(Styles.secondContainer)}>
            <div className={css(Styles.quickContainer)}>
              <p className={css(Styles.quickContainerTitle)}>{Helper.getString('quickLinks')}</p>
              {this.quickListItems()}
            </div>
            <div className={css(Styles.contactBannerContainer)}>
              {this.contactBanner()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  innerContainer: {
    flex: 1,
    display: 'flex',
    marginTop: 40,
    marginBottom: 40,
    '@media (max-width: 768px)': {
      flexDirection: 'column'
    }
  },
  bannerOuterContainer: {
    display: 'flex',
    minHeight: 223,
    backgroundColor: '#E2EAFC',
    borderRadius: AppTheme.borderRadius,
    '@media (max-width: 767px)': {
      flexDirection: 'column-reverse'
    }
  },
  bannerTextContainer: {
    flex: 1,
    padding: 30,
    '@media (max-width: 768px)': {
      padding: 20,
    }
  },
  bannerImgOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bannerImgContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 400,
    height: 280,
    marginTop: -60,
    paddingRight: 30,
    zIndex: 1000,
    '@media (max-width: 768px)': {
      width: 300,
      height: 200,
      paddingRight: 0,
    }
  },
  imageContent: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  bannerTitle: {
    margin: 0,
    color: CommonTheme.textColor,
    fontSize: 36,
    fontWeight: 600,
    '@media (max-width: 768px)': {
      fontSize: 30,
    }
  },
  bannerDesc: {
    margin: 0,
    fontSize: 18,
    color: CommonTheme.textColor,
    marginTop: 15,
    '@media (max-width: 768px)': {
      fontSize: 15,
    }
  },
  buttonStyle: {
    padding: 18,
    marginTop: 30,
    minWidth: 180
  },
  buttonTextStyle: {
    fontSize: 16,
    lineHeight: '19px',
    textTransform: 'capitalize'
  },
  firstContainer: {
    flex: .55,
    '@media (max-width: 768px)': {
      flex: 1,
    }
  },
  secondContainer: {
    flex: 1,
    marginLeft: 0,
    '@media (max-width: 768px)': {
      flex: 1,
      marginLeft: 0,
      marginTop: 30
    }
  },
  quickContainer: {
    border: CommonTheme.containerBorderColor,
    borderRadius: AppTheme.borderRadius,
    padding: '20px 30px',
    '@media (max-width: 768px)': {
      padding: '20px 15px',
    }
  },
  quickContainerTitle: {
    textTransform: 'capitalize',
    fontSize: 20,
    color: CommonTheme.textColor,
    fontWeight: 500,
    margin: 0,
    marginBottom: 30
  },
  link: {
    textDecoration: 'none'
  },
  quickListItemsContainer: {
    border: CommonTheme.containerBorderColor,
    borderRadius: AppTheme.borderRadius,
    padding: 17,
    display: 'flex',
    justifyContent: 'center'
  },
  quickListTitleContainer: {
    fontSize: 16,
    color: AppTheme.primaryColor,
    margin: 0,
    fontWeight: 500
  },
  contactBannerContainer: {
    marginTop: 40
  },
  contactBannerImgContainer: {
    width: 198,
    height: 126,
    marginTop: -30,
  },
  contactBannerOuterContainer: {
    minHeight: 115
  },
  contactBannerTextContainer: {
    padding: '24px 10px 24px 30px'
  },
  contactBannerTitle: {
    fontSize: 16,
    color: CommonTheme.textColor,
    margin: 0
  },
  contactUsBannerTitleContainer: {
    display: 'flex',
    marginTop: 8,
  },
  contactUsBannerTitle: {
    fontSize: 34,
    color: AppTheme.primaryColor,
    margin: 0,
    fontWeight: 600
  },
  imageHandClickContainer: {
    width: 25,
    height: 25,
    marginLeft: -3,
    marginTop: -3
  },
  lineStatItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
    },
    width: '49%',
  },
  analyticsDashboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    maxWidth: AppTheme.viewPort,
    overflow: 'hidden',
    marginBottom: 20,
  },
  analyticsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: 10,
  },
  analyticsDataContainer: {
    marginTop: 51,
  },
  dateRangeContainer: {
    position: 'fixed',
    top: 140,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    boxSizing: 'border-box',
    maxWidth: AppTheme.viewPort,
    width: '100%',
    backgroundColor: AppTheme.secondaryBg,
    paddingBottom: 15,
    '@media (max-width: 767px)': {
      width: '90%',
      paddingLeft: 8,
      gap: 0,
    },
  },
  tableStatItem: {
    flex: 1,
    height: '100%',
    '@media (max-width: 767px)': {
      width: '100%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
    },
  },
  tableStatGridItem: {
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
      marginBottom: 0,
    },
    width: '49%',
    marginBottom: '40px'
  },
  pieStatItem: {
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
    },
    width: '49%',
  },
  formControlMainFilters: {
    width: 200,
    '@media (max-width: 440px)': {
      width: '35%',
      alignSelf: 'center',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  labelContainer: {
    display: 'flex',
    marginBottom: 8
  },
  label: {
    fontSize: 14,
    color: 'grey',
    margin: 0,
    // marginBottom: 8,
    display: 'flex',
  },
  formControlFilters: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
    overflow: 'hidden'
  },
  textInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16
  },
  autoCompleteInputRoot: {
    paddingRight: 56,
    '@media (max-width: 440px)': {
      paddingRight: 18,
    },
  },
  formInputStyle: {
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  textInput: {
    padding: '4px 0px',
    '@media (max-width: 440px)': {
      fontSize: 12,
    },
  },
  dimensionFilterSelectedContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
    minWidth: 150,
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
    padding: '10px 0px 10px 20px',
    textAlign: 'left',
    fontSize: 16,
    overflow: 'hidden',
    '@media (max-width: 767px)': {
      fontSize: 12,
      minWidth: 0,
      gap: 2,
      padding: '8px 5px 8px 10px',
    },
  },
  dimensionFilterSelectedLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 250,
    '@media (max-width: 767px)': {
      fontSize: 12,
      maxWidth: 50,
    },
  },
  icon: {
    padding: 5
  },
})