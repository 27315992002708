import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { AppStyles } from '../../../styles/AppTheme';

function _getUserStates(userAttribs) {
  const userAttribsCopy = JSON.parse(JSON.stringify(userAttribs))
  // delete all known attributes
  delete userAttribsCopy.UserTags

  // rest of the attributes go as user states
  const userStates = []
  Object.keys(userAttribsCopy).forEach(a => {
    userStates.push(a)
  })
  return userStates
}

export class Segments extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('segments')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('segments') },
    ]
    this.attribsGroupId = 'settingscrmcontactattrs' + this.orgId
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['csegments'], queryParams: { orgId: this.orgId, pb: 'fieldDef,fieldDefCSegmentConfig' } },
      { ms: this.ms, method: 'GET', paths: ['items', `${this.attribsGroupId}-all`], queryParams: { groupId: this.attribsGroupId } },
    ]
    this.apiData = {
      orgId: this.orgId
    }
    this.pageKey = 'segments'
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Name: (current) => current.title,
      Status: (current) => current.state,
      Info: (current) => {
        if (current.ppSegmentGroups && current.ppSegmentGroups.Groups && current.ppSegmentGroups.Groups.length && current.ppSegmentGroups.Groups[0].Dimensions && current.ppSegmentGroups.Groups[0].Dimensions.length) {
          let _isDataFound = false
          const data = current.ppSegmentGroups.Groups[0].Dimensions.map(dimension => {
            if (dimension.UserAttributes && dimension.UserAttributes.UserTags && ['CONTAINS', 'EXCLUSIVE'].includes(dimension.UserAttributes.UserTags.AttributeType)) {
              const userAttribs = dimension.UserAttributes || {}
              const userTagsObj = userAttribs.UserTags || {}
              const userTagsOp = userTagsObj.AttributeType
              const userTags = userTagsObj.Values || []
              if (userTagsOp === 'CONTAINS') {
                _isDataFound = true
                const userStates = _getUserStates(userAttribs)
                return <div className={css(Styles.info)}>{userTags.length > 0 && <><strong>User Tags (INCLUDE): </strong>{userTags.join(', ')}<br/></>}{userStates.length > 0 && <><strong>User States: </strong>{userStates.join(', ')}<br/></>}</div>
              } else if (userTagsOp === 'EXCLUSIVE') {
                _isDataFound = true
                return <div className={css(Styles.info)}>{userTags.length > 0 && <><strong>User Tags (EXCLUDE): </strong>{userTags.join(', ')}<br/></>}</div>
              } else {
                return <></>
              }
            }
            return <></>
          })
          return _isDataFound ? <>{data}</> : <div className={css(Styles.info)}><strong>ALL</strong></div>
        }
        return ''
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
  }

  onFetchSuccess(results) {
    this.fieldDef = this.formDefinition = results[0].fieldDef
    this.fieldDefCSegmentConfig = results[0].fieldDefCSegmentConfig
    this.crmContactAttrs = results[1]
    this.crmContactAttrs.tagsExclude = this.crmContactAttrs.tags
    this.fieldDefCSegmentConfig.steps[0].fields.map(f => {
      if (f.key in this.crmContactAttrs) {
        const attrStr = this.crmContactAttrs[f.key] || ''
        f.options.push(...attrStr.split(',').map(a => a.trim()).map(a => ({key: a, label: a})))
      }
    })

    this.setState({
      items: results[0].items
    })
  }

  onAdd() {
    this.formDefinition = this.fieldDef
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['csegments'], queryParams: {} }),
    });
  }

  openModalForEdit(current) {
    this.editMode = 'fieldDef'
    this.editId = current.id
    super.openModalForEdit(current)
  }

  openModalForConfig(current) {
    this.editMode = 'fieldDefCSegmentConfig'
    this.editId = current.id
    this.formDefinition = this.fieldDefCSegmentConfig

    const modalObj = {
      id: current.id,
      tags: [],
      userstates: [],
    }
    if (current.ppSegmentGroups && current.ppSegmentGroups.Groups && current.ppSegmentGroups.Groups.length && current.ppSegmentGroups.Groups[0].Dimensions && current.ppSegmentGroups.Groups[0].Dimensions.length) {
      current.ppSegmentGroups.Groups[0].Dimensions.forEach(dimension => {
        if (dimension.UserAttributes && dimension.UserAttributes.UserTags && ['CONTAINS', 'EXCLUSIVE'].includes(dimension.UserAttributes.UserTags.AttributeType)) {
          const userAttribs = dimension.UserAttributes || {}
          const userTagsObj = userAttribs.UserTags || {}
          const userTagsOp = userTagsObj.AttributeType
          const userTags = userTagsObj.Values || []
          if (userTagsOp === 'CONTAINS') {
            modalObj.tags = userTags
            modalObj.userstates = _getUserStates(userAttribs)
          } else if (userTagsOp === 'EXCLUSIVE') {
            modalObj.tagsExclude = userTags
          }
        }
      })
    }

    super.openModalForEdit(modalObj, this.formDefinition)
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['csegments', current.id], queryParams: {} }),
    });
  }

  // onDelete(current) {
  //   this.setState({
  //     apiMethod: 'DELETE',
  //     apiEndPoint: ApiHelper.makeUrlPath(['csegments', current.id], {}),
  //   });
  // }

  customSubmit(options) {
    const { formType, formData, caller } = options
    if (formType !== 'edit' || this.editMode === 'fieldDef') {
      return caller.submit.call(caller, formData, true);
    }

    const UserAttributesContains = formData.userstates.reduce((p, c) => {
      p[c] = {AttributeType : 'CONTAINS', Values : ['True']}
      return p
    }, {})

    if (formData.tags && formData.tags.length > 0) {
      UserAttributesContains.UserTags = {
        AttributeType : 'CONTAINS',
        Values : formData.tags,
      }
    }

    const UserAttributesExcludes = {}
    if (formData.tagsExclude && formData.tagsExclude.length > 0) {
      UserAttributesExcludes.UserTags = {
        AttributeType : 'EXCLUSIVE',
        Values : formData.tagsExclude,
      }
    }

    ApiHelper.callAwait({
      method: 'PUT',
      jsonBody: {
        ppSegmentGroups: {
          Include : 'ALL',
          Groups : [ {
            Type : 'ALL',
            Dimensions : [
              {
                Demographic : { },
                Location : { },
                Behavior : { },
                User : { },
                Attributes : { },
                Metrics : { },
                UserAttributes: UserAttributesContains,
                UserMetrics : { }
              },
              {
                Demographic : { },
                Location : { },
                Behavior : { },
                User : { },
                Attributes : { },
                Metrics : { },
                UserAttributes: UserAttributesExcludes,
                UserMetrics : { }
              },
            ],
            SourceType : 'ANY',
            SourceSegments : []
          } ]
        },
        orgId: formData.orgId,
      },
      endPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['csegments', this.editId], queryParams: {} }),
    }).then(() => {
      this.setState({ modalOpen: false })
      this.fetchItems()
    })
      .catch(err => {
        const errorMessage = Helper.getErrorMsg(err)
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: errorMessage,
          success: false,
          message: errorMessage,
          inProcess: false
        })
      })
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(
      <Tooltip title='Configure' placement='top-start'>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => this.openModalForConfig(current)}>view_agenda</i>
        </span>
      </Tooltip>
    )
    return actions
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }
}

const Styles = StyleSheet.create({
  info: {
    fontSize: 12
  },
})