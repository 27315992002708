import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
  convertFromHTML,
  Modifier
} from 'draft-js';
import { UiHelper } from '../../helpers/UiHelper';
import { ApiHelper } from '../../helpers/ApiHelper';
import './Styles.css'

class VariablePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this.dropdownRef = React.createRef()
    this.openDropdown = this.openDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  openDropdown() {
    this.setState((prevState) => ({
      open: !prevState.open
    }));
  }

  handleClickOutside(event) {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({
        open: false
      });
    }
  }

  addVariable = (value) => {
    const { editorState, onChange } = this.props

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      value,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'))
  }

  render() {
    if(this.props.title && this.props.options && this.props.options.length > 0) {
      return (
        <div ref={this.dropdownRef} onClick={this.openDropdown} className='rdw-block-wrapper' aria-label='rdw-block-control'>
          <div className={`rdw-dropdown-wrapper rdw-block-dropdown ${css(Styles.variableDropdown)}`} aria-label='rdw-dropdown'>
            <div className='rdw-dropdown-selectedtext' title={this.props.title}>
              <span>{this.props.title}</span>
              <div className={`rdw-dropdown-caretto${this.state.open ? 'close' : 'open'}`}></div>
            </div>
            <ul className={`rdw-dropdown-optionwrapper ${css(this.state.open ? Styles.variableList : Styles.variableListHidden)}`}>
              {this.props.options.map(item => (
                <li
                  onClick={this.addVariable.bind(this, item.value)}
                  key={item.key}
                  className={`rdw-dropdownoption-default ${css(Styles.variableListItem)}`}
                >{item.text}</li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
    return ''
  }
}

export class RichtextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: this.processValue(props.value),
      fetchState: ApiHelper.State.LOADING
    }
    this.defaultContent = null
  }

  componentDidMount() {
    this.setState({
      fetchState: ApiHelper.State.READY
    })
  }

  processValue = (value) => {
    if (value) {
      try {
        this.defaultContent = JSON.parse(value).__azformatted;
      } catch (e) {
        console.log('Json parse error', e)
        return EditorState.createEmpty()
      }
    }
    if (this.defaultContent) {
      return EditorState.createWithContent(convertFromRaw(this.defaultContent))
    } else {
      if(this.props.current.subType === 'h1') {
        return EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML('<h1></h1>')
          )
        )
      } else if(this.props.current.subType === 'h2') {
        return EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML('<h2></h2>')
          )
        )
      }
      return EditorState.createEmpty()
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    const data = convertToRaw(editorState.getCurrentContent())
    if(data && data.blocks && data.blocks.length === 1 && data.blocks[0].text === '') {
      return this.props.handleRichtextInputChange('', this.props.current.key)
    }

    this.props.handleRichtextInputChange(JSON.stringify({ __azformatted: data }), this.props.current.key)
  };

  handleReturn = (event, editorState)=>{
    if(this.props.current.subType === 'h1' || this.props.current.subType === 'h2') return 'handled'
  }

  /*uploadImage = (file) => {
    const error = null
    return new Promise((resolve, reject) => {
      if (error) reject(error);
      else resolve({ data: { link: 'TODO:link to image' } });
    });
  };

  processValueV2 = () => {
    const c = localStorage.getItem('contentData')
    if (c) {
      this.defaultContent = JSON.parse(c)
    }
    if (this.defaultContent) {
      return EditorState.createWithContent(convertFromRaw(this.defaultContent))
    } else {
      return EditorState.createEmpty()
    }
  }

  onEditorStateChangeV2 = (editorState) => {
    this.setState({
      editorState,
    });
    const data = convertToRaw(editorState.getCurrentContent())
    localStorage.setItem('contentData', JSON.stringify(data))
  };*/

  setEditorRef(ref) {
    this.editorReferece = ref;
  }
  editorFoucs() {
    if (this.editorReferece) {
      this.editorReferece.focus();
    }
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.loadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  handlePastedText = (text, html, editorState) => {
    if (this.props.stripPastedImages && html) {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      doc.querySelectorAll('img, figure').forEach(element => element.remove());
      const modifiedHtml = doc.body.innerHTML;

      const contentState = ContentState.createFromBlockArray(
        convertFromHTML(modifiedHtml)
      );

      const newBlockMap = contentState.getBlockMap();
      const currentContent = editorState.getCurrentContent();
      const newContentState = Modifier.replaceWithFragment(
        currentContent,
        editorState.getSelection(),
        newBlockMap
      );

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'insert-characters'
      );

      this.onEditorStateChange(newEditorState);
      return true;
    }
    return false;
  };

  _getBlockTypeOptions() {
    if(this.props.current.subType === 'h1') {
      return ['H1']
    } else if(this.props.current.subType === 'p') {
      return ['Normal']
    } else if(this.props.current.subType === 'h2') {
      return ['H2']
    } else  {
      return ['Normal', 'H2', 'H3', 'H4', 'H5', 'H6']
    }
  }

  _getOptions() {
    if(this.props.current.subType === 'h1') {
      return ['inline', 'blockType', 'fontSize']
    } else if(this.props.current.subType === 'h2') {
      return ['inline', 'blockType', 'fontSize']
    } else  {
      return ['inline', 'list', 'link', 'blockType', 'fontSize']
    }
  }

  _getOptionalParams() {
    const options = {}
    if(this.props.current.subType === 'h1' || this.props.current.subType === 'h2') {
      options.handleReturn = this.handleReturn
    }
    return options
  }

  readyView() {
    const toolbar = this.props.fullEditor ? undefined : {
      options: this._getOptions(),
      inline: {
        options: ['bold', 'italic'],
      },
      list: {
        options: ['unordered'],
      },
      blockType: {
        inDropdown: true,
        // options: ['Normal', 'H2', 'H3', 'H4', 'H5', 'H6'],
        options: this._getBlockTypeOptions()
      },
      fontSize: {
        options: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20,
          22, 24, 26, 28, 30, 32, 34, 36, 38, 40,
          42, 44, 46, 48, 50, 52, 56, 60, 64, 68,
          72, 76, 80, 84, 88, 92, 96, 100
        ],
      },
      remove: { icon: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAtOTYwIDk2MCA5NjAiIHdpZHRoPSIyNHB4IiBmaWxsPSIjMDAwIj48cGF0aCBkPSJtNTI4LTU0Ni05My05My0xMjEtMTIxaDQ4NnYxMjBINTY4bC00MCA5NFpNNzkyLTU2IDQ2MC0zODhsLTgwIDE4OEgyNDlsMTE5LTI4MEw1Ni03OTJsNTYtNTYgNzM2IDczNi01NiA1NloiLz48L3N2Zz4=', className: undefined, title: 'Clear Formatting' },
    }
    if (this.props.colorPicker) { toolbar.options.push('colorPicker') }
    if (this.props.clearFormatting) { toolbar.options.push('remove') }
    const optionalParams = this._getOptionalParams()

    return (

      <div className={css(Styles.container)} onClick={this.editorFoucs.bind(this)}
        style={{
          '--noLines': (this.props.current && this.props.current.rows) || 2
        }}>
        <Editor
          editorState={this.state.editorState}
          wrapperClassName='wrapper'
          editorClassName='editor'
          toolbarClassName='toolbar'
          onEditorStateChange={this.onEditorStateChange}
          toolbar={toolbar}
          stripPastedStyles={'stripPastedStyles' in this.props ? this.props.stripPastedStyles : true}
          handlePastedText={this.handlePastedText}
          editorRef={this.setEditorRef.bind(this)}
          readOnly={this.props.disabled}
          toolbarCustomButtons={ this.props.variablePicker ? [
            <VariablePicker
              title={this.props.variablePicker.title || 'Custom Fields'}
              options={this.props.variablePicker.options || []}
              onChange={this.onEditorStateChange.bind(this)}
              editorState={this.state.editorState}/>
          ] : []}
          {...optionalParams}
        />
      </div>
    );
  }
}

const Styles = StyleSheet.create({
  container: {
    position:'relative'
  },
  variableDropdown: {
    width: 140,
  },
  variableList: {
    width: 140,
  },
  variableListHidden: {
    visibility: 'hidden',
  },
  variableListItem: {
    ':hover': { background: '#F1F1F1'
    }
  }
})
