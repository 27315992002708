import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ApiHelper } from './ApiHelper';
import { Helper } from '../helpers/Helper';
import { AppTheme } from '../../styles/AppTheme';
import { AppStyles } from '../../styles/AppTheme';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import { AppConfig } from '../../AppConfig';
import { AppButton } from '../components/AppButton';
import { Controller } from '../Controller';

const ERROR_MSG = Helper.getString('defaultApiErrMsg')

export class UiHelper {

  static itemIdContainer(current) {
    return (
      <div>
        <span className={css(Styles.spanValue)}>{current.slug}</span>
        <br />
        <span className={css(Styles.spanValue)}>{current.id}</span>
      </div>
    )
  }

  static itemTitleImage(current) {
    return (
      <div className={css(Styles.spanContainer)}>
        <div className={css(Styles.spanImgContainer)}>
          {current.image ?
            <img src={current.image} className={css(Styles.imageContent)} />
            :
            <i className='material-icons-outlined' style={{ fontSize: 40, color: 'lightgray' }}>image</i>
          }
        </div>
        <div className={css(Styles.spanTextContainer)}>
          <span className={css(Styles.itemValue)}>{current.title}</span>
        </div>
      </div>
    )
  }

  static itemImage(current) {
    const image = current.imageLink || current.image
    return (
      <div className={css(Styles.spanContainer)}>
        <div className={css(Styles.spanImgContainer)}>
          {image ?
            <img src={image} className={css(Styles.imageContent)} />
            :
            <i className='material-icons-outlined' style={{ fontSize: 40, color: 'lightgray' }}>image</i>
          }
        </div>
      </div>
    )
  }

  static itemStatusContainer(current) {
    return (
      <div className={css(Styles.statusOuterContainer)}>
        <p className={current.state === 'LIVE' ? css(Styles.statusTitle) : css(Styles.statusTitle, Styles.statusTitleStyle)}>{current.state || ''.toLowerCase()}</p>
        <div>
          {current.likeCount !== undefined &&
            <div className={css(Styles.countContainer)}>
              <div className={css(Styles.imageContainer)}>
                <i className='material-icons-outlined' style={{ fontSize: 20, color: AppTheme.primaryColor }}>thumb_up</i>
              </div>
              <p className={css(Styles.statusValue)}>{current.likeCount || 0}</p>
            </div>
          }
          {current.buyCount !== undefined &&
            <div className={css(Styles.countContainer, Styles.countSecondContainer)}>
              <div className={css(Styles.imageContainer)}>
                <i className='material-icons-outlined' style={{ fontSize: 20, color: AppTheme.primaryColor }}>shopping_basket</i>

              </div>
              <p className={css(Styles.statusValue)}>{current.buyCount || 0}</p>
            </div>
          }
        </div>
      </div>
    )
  }

  static itemDetails(current) {
    return (
      <div className={css(Styles.spanContainer)}>
        <div className={css(Styles.spanImgContainer)}>
          {(current.variantItems && current.variantItems[0].image || current.image) ?
            <img src={current.imageLink || current.image || current.variantItems[0].image}
              className={css(Styles.imageContent)} />
            :
            <i className='material-icons-outlined' style={{ fontSize: 40, color: 'lightgray' }}>image</i>
          }
        </div>
        <div>
          <span className={css(Styles.itemValue)}>{current.title}</span>
          <br />
          <div className={css(Styles.slugSmContainer)}>
            {current.includeInSitemap && current.includeInSitemap === 'YES' &&
             <span className={css(Styles.itemValue, Styles.line2)}>
               <Chip size='small' label={Helper.getString('sitemapIncluded')} style={{ backgroundColor: 'lightgray', color: 'black', fontSize: 8, height: 20 }} />
             </span>
            }
            <span className={css(Styles.itemValue, Styles.line2)}>
              {current.slug} {current.badge && <Chip size='small' label={current.badge} style={{ backgroundColor: 'lightgray', color: 'black', fontSize: 8, height: 20 }} />}
            </span>
          </div>
        </div>
      </div>
    )
  }

  static itemTitleId(current) {
    return (
      <div>
        <span className={css(Styles.spanValue)}>{current.title}</span>
        <br />
        <span className={css(Styles.itemValue, Styles.line2)}>{current.slug || current.id}</span>
      </div>
    )
  }

  static chipLive(label) {
    if (label === 'LIVE') return UiHelper.chipPos(label)
    else return ''
  }

  static chipFeatured(label) {
    if (label === 'FEATURED') return UiHelper.chipPos(label)
    else return ''
  }

  static chipHome(label) {
    if (label === 'HOME') return UiHelper.chipPos(label)
    else return ''
  }

  static chip(label) {
    if (!label) return ''
    return (
      <Chip size='small' label={label} />
    )
  }

  static chipPos(label) {
    if (!label) return ''
    return (
      <Chip size='small' label={label} style={{ backgroundColor: '#77DD77', color: 'black' }} />
    )
  }

  static loadingView() {
    return (
      <div className={css(Styles.container)}>
        <CircularProgress size={48} className={css(Styles.progress)} />
      </div>
    )
  }

  static itemLoadingView() {
    return (
      <CircularProgress size={24} className={css(Styles.progress)} />
    )
  }

  static errorView(caller) {
    if (AppConfig.CUSTOM_STYLE) {
      return UiHelper.customErrorView(caller)
    } else {
      return (
        <div className='pageError'>
          {caller.state.errMsg || ERROR_MSG}
        </div>
      )
    }
  }

  static goToHome() {
    const path = Controller.get().roleHelper().getHome()
    window.location.href = path
  }

  static customErrorView(caller) {
    return (
      <div className='pageError'>
        <div className={css(Styles.customEmptyContainer)}>
          <div className={css(Styles.customImageContainer)}>
            <img src={require('../../assets/images/error.svg')} className={css(Styles.image)} />
          </div>
          <p className={css(Styles.customText)}>{caller.state.errMsg || Helper.getString('defaultApiErrMsg')}</p>
          <AppButton
            buttonText={Helper.getString('browseHome')}
            onClick={() => UiHelper.goToHome()}
          />
        </div>
      </div>
    )
  }

  static staticErrorView(caller) {
    return (
      <div className={css(Styles.errorContainer)}>
        <div className={css(Styles.errImageContainer)}>
          <img src={require('../../assets/images/404.svg')} className={css(Styles.image)} />
        </div>
        <p className={css(Styles.nfMiddle)}>{Helper.getString('serviceErr')}</p>
      </div>
    )
  }

  static componentLoadingView() {
    if (AppConfig.CUSTOM_STYLE) {
      return UiHelper.customLoadingView()
    } else {
      return (
        <div className={css(Styles.container)}>
          <CircularProgress size={48} className={css(Styles.progress)} />
        </div>
      )
    }
  }

  static customLoadingView() {
    return (
      <div className={css(Styles.container2)}>
        <div className={css(Styles.loaderContainer)}>
          <div className='loader' />
          <div className={css(Styles.loaderImageContainer)}>
            <img src={require('../../assets/images/loading.svg')} className={css(Styles.image)} />
          </div>
        </div>

        <p className={css(Styles.customText)}>{Helper.getString('loadingText')}</p>
      </div>
    )
  }

  static contentLoadingView() {
    return (
      <div className={css(Styles.container2, Styles.transparentLoadingViewContainer)}>
        <div className={css(Styles.loaderContainer)}>
          <div className='loader' />
          <div className={css(Styles.loaderImageContainer)}>
            <img src={require('../../assets/images/loading.svg')} className={css(Styles.image)} />
          </div>
        </div>

        <p className={css(Styles.customText)}>{Helper.getString('loadingText')}</p>
      </div>
    )
  }

  static contentLoadingViewOverlay() {
    return (<div className={css(Styles.loadingViewOverlay)}></div>)
  }


  static emptyView(msg) {
    if (AppConfig.CUSTOM_STYLE) {
      return UiHelper.customEmptyView(msg)
    } else {
      return (
        <div className={css(Styles.emptyContainer)}>
          <p>{msg || Helper.getString('emptyMsg')}</p>
        </div>
      )
    }
  }

  static customEmptyView(msg) {
    return (
      <div className={css(Styles.customEmptyContainer)}>
        <div className={css(Styles.customImageContainer)}>
          <img src={require('../../assets/images/empty.svg')} className={css(Styles.image)} />
        </div>
        <p className={css(Styles.customText)}>{msg || Helper.getString('emptyMsg')}</p>
      </div>
    )
  }

  static componentErrorView(caller) {
    return (
      <div className={css(Styles.container)}>
        <p className={css(Styles.error)}>{caller.state.errMsg || ERROR_MSG}</p>
      </div>
    )
  }

  static actionButton(item) {
    return (
      <Tooltip title={(item.tooltip && item.tooltip !== '') ? item.tooltip : ''} placement='top-start' key={item.current && item.current.id}>
        <div className={css(Styles.actionMenuItem)} onClick={item.action} key={item.key}>
          {item.title &&
            <p className={css(Styles.actionMenuText)}>{item.title}</p>
          }
          {item.icon &&
            <i className='material-icons-outlined' style={{fontSize: 22, color: AppTheme.primaryColor}}>{item.icon}</i>
          }
        </div>
      </Tooltip>
    )
  }

  static buttonEdit(caller, current, title) {
    return (
      <Tooltip title={title || 'Edit'} placement='top-start' key={current.id + 'editTT'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => caller.openModalForEdit(current)}>edit</i>
        </span>
      </Tooltip>
    )
  }

  static buttonInfo(caller, current, title) {
    return (
      <Tooltip title={title || 'Info'} placement='top-start' key={current.id + 'infoTT'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => caller.openModalForInfo(current)}>info</i>
        </span>
      </Tooltip>
    )
  }

  static buttonDelete(caller, current) {
    return (
      <Tooltip title='Delete' placement='top-start' key={current.id + 'deleteTT'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => caller.openModalForDelete(current)}>delete</i>
        </span>
      </Tooltip>
    )
  }


  static buttonOrderChange(current, orderChangePayload, order) {
    const {index, items, toolTipUp, toolTipDown, orderUp, orderDown, fetchState, currentOrderChangeIndex } = orderChangePayload

    if(fetchState === ApiHelper.State.LOADING && currentOrderChangeIndex === index) {
      return (
        <div className={css(Styles.orderChangeBtnContainer)}>
          <CircularProgress size={20} className={css(Styles.progress)} />
        </div>
      )
    }

    return (
      <div className={css(Styles.orderChangeBtnContainer)}>
        { <Tooltip title={toolTipUp || Helper.getString('moveUp')} placement='top-start' key={current.id + Helper.getString('moveUp')}>
          <span className={(index > 0) ? css(AppStyles.entityTableLinkIcon, Styles.orderChangeBtn) : css(AppStyles.entityTableLinkIcon, Styles.orderChangeBtnDisabled)}>
            <i className='material-icons-outlined' onClick={() =>
              orderUp(current, items, index)}>keyboard_arrow_up</i>
          </span>
        </Tooltip>}
        <span className={css(Styles.orderText)}>{order}</span>
        {  <Tooltip title={toolTipDown || Helper.getString('moveDown')} placement='top-start' key={current.id + Helper.getString('moveDown')}>
          <span className={(index < items.length - 1) ? css(AppStyles.entityTableLinkIcon, Styles.orderChangeBtn) : css(AppStyles.entityTableLinkIcon, Styles.orderChangeBtnDisabled)}>
            <i className='material-icons-outlined' onClick={() =>
              orderDown(current, items, index)}>keyboard_arrow_down</i>
          </span>
        </Tooltip>}
      </div>
    )
  }

  static buttonSEO(caller, current) {
    return (
      <Tooltip title='SEO' placement='top-start' key={current.id + 'SEOTT'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => caller.openModalForSEO(current)}>content_paste_search</i>
        </span>
      </Tooltip>
    )
  }

  static buttonOpenItemsInCat(caller, current) {
    return (
      <Link to={caller.baseRoute() + '/cats/' + current.id + '/items'} key={current.id + '-items'} >
        <Tooltip title={Helper.getString('items')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>layers</i>
          </span>
        </Tooltip>
      </Link>
    )
  }

  static buttonOpenItemsInCCat(caller, current) {
    return (
      <Link to={caller.baseRoute() + '/cats/' + current.id + '/items'} key={current.id + '-items'} >
        <Tooltip title={Helper.getString('items')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>layers</i>
          </span>
        </Tooltip>
      </Link>
    )
  }

  static buttonOpenBlogsInTag(caller, current) {
    return (
      <Link to={caller.baseRoute() + '/btags/' + current.id + '/blogs'} key={current.id + '-blogs'}>
        <Tooltip title={Helper.getString('blogs')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>layers</i>
          </span>
        </Tooltip>
      </Link>
    )
  }

  static buttonOpenBlog(caller, current) {
    return (
      <Link to={this.baseRoute(current.orgId) + '/blogs/' + current.id} key={current.id}>
        <Tooltip title='Edit details' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}><i className='material-icons-outlined'>link</i></span>
        </Tooltip>
      </Link>
    )
  }

  static buttonOpenBlogContent(caller, current) {
    return (
      <Link to={this.baseRoute(current.orgId) + '/blogs/content/' + current.id} key={current.id}>
        <Tooltip title='Edit content' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}><i className='material-icons-outlined'>text_format</i></span>
        </Tooltip>
      </Link>
    )
  }

  static buttonOpenProduct(caller, current) {
    return (
      <Link to={this.baseRoute(current.orgId) + '/products/' + current.id} key={current.id} >
        <Tooltip title='Open' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}><i className='material-icons-outlined'>link</i></span>
        </Tooltip>
      </Link>
    )
  }

  static buttonOpenCourse(caller, current) {
    return (
      <Link to={this.baseRoute(current.orgId) + '/courses/' + current.courseId} key={current.courseId} >
        <Tooltip title='Open' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}><i className='material-icons-outlined'>link</i></span>
        </Tooltip>
      </Link>
    )
  }

  static buttonEditMenuItem(caller, current) {
    return (
      <MenuItem className={css(AppStyles.menuItem)}>
        <div key={current.id + 'editTT'} onClick={() => caller.openModalForEdit(current)} className={css(AppStyles.menuContainer)}>
          <span className={css(AppStyles.entityTableLinkIcon, AppStyles.entityTableLinkIconMenu)}>
            <i className='material-icons-outlined'>edit</i>
          </span>
          <p className={css(AppStyles.titleContainer)}>Edit</p>
        </div>
      </MenuItem>
    )
  }

  static buttonDeleteMenuItem(caller, current) {
    return (
      <MenuItem className={css(AppStyles.menuItem)}>
        <div key={current.id + 'deleteTT'} onClick={() => caller.openModalForDelete(current)} className={css(AppStyles.menuContainer)}>
          <span className={css(AppStyles.entityTableLinkIcon, AppStyles.entityTableLinkIconMenu)}>
            <i className='material-icons-outlined'>delete</i>
          </span>
          <p className={css(AppStyles.titleContainer)}>Delete</p>
        </div>
      </MenuItem>
    )
  }

  static populateOptions(formDefinition, apiResult, key) {
    const options = []
    for (const d of apiResult.items) {
      options.push({
        key: d.id || d.userId,
        text: d.title || Helper.formatName(d)
      })
    }
    for (const field of formDefinition.steps[0].fields) {
      if (field.key === key) {
        field.options = options
      }
    }
  }

  static baseRoute(orgId) {
    return orgId !== 'PRODUCT' ? '/admin/orgs/' + orgId : '/admin'
  }

  static fieldDefResolve(options) {
    console.log('fieldDefResolve:', options)
    const { def, values, key } = options
    let resolved = values[key]
    if (!def) return resolved
    def.steps[0].fields.forEach(field => {
      if (field.key !== key) return
      if (!field.options) return
      const optionsByKey = Helper.itemsToMap(field.options, 'key')
      const value = values[key]
      if (value) {
        if (field.type === 'radio') {
          const option = optionsByKey[value]
          if (option) {
            resolved = option.label || option.text
            return
          }
        } else if (field.type === 'check_box') {
          const resolves = []
          value.forEach(v => {
            const option = optionsByKey[v]
            if (option) resolves.push(option.label || option.text)
          })
          if (resolves.length > 0) {
            resolved = resolves.join(', ')
            return
          }
        }
      }
    })
    return resolved
  }

  static fieldDefDeleteField(fieldDef, key) {
    if (fieldDef && Array.isArray(fieldDef.steps) && fieldDef.steps.length > 0 && Array.isArray(fieldDef.steps[0].fields)) {
      fieldDef.steps[0].fields = fieldDef.steps[0].fields.filter(f => f.key !== key)
    }
  }

  static renderImg = (options) => {
    let imgUrl = ''
    let icon = ''
    switch (options.key) {
    case 'file':
      try {
        imgUrl = require('../../assets/images/document-img.svg')
      } catch (err) {
        imgUrl = null
        icon = 'file_open'
      }
      break
    case 'drawerMenu':
      if (options.drawerIsOpen) {
        try {
          imgUrl = require('../../assets/images/drawer-button-close.svg')
        } catch (err) {
          imgUrl = null
          icon = 'menu_open'
        }
      } else {
        try {
          imgUrl = require('../../assets/images/drawer-button-open.svg')
        } catch (err) {
          imgUrl = null
          icon = 'menu'
        }
      }
      break
    case 'drawerClose':
      try {
        imgUrl = require('../../assets/images/drawer-button-close.svg')
      } catch (err) {
        imgUrl = null
        icon = 'menu_open'
      }
      break
    case 'drawerOpen':
      try {
        imgUrl = require('../../assets/images/drawer-button-open.svg')
      } catch (err) {
        imgUrl = null
        icon = 'menu'
      }
      break
    case 'docUpload':
      try {
        imgUrl = require('../../assets/images/img-uploader.svg')
      } catch (err) {
        imgUrl = null
        icon = 'file_upload'
      }
      break
    case 'imgUpload':
      try {
        imgUrl = require('../../assets/images/export.svg')
      } catch (err) {
        imgUrl = null
        icon = 'file_upload'
      }
      break
    default:
      return ''
    }
    if (imgUrl) {
      return <img src={imgUrl} className={css(Styles.imageContent)} />
    } else {
      return <i className='material-icons-outlined' style={{ fontSize: options.size || 24, color: AppTheme.primaryColor }}>{icon}</i>
    }
  }

  static renderTransparentTile = () => {
    return (
      <div className={css(Styles.transparentOuterContainer)}>
        <div className={css(Styles.transparentInnerContainer)}>
          <div className={css(Styles.checkBox1)}></div>
          <div className={css(Styles.checkBox2)}></div>
        </div>
        <div className={css(Styles.transparentInnerContainer)}>
          <div className={css(Styles.checkBox2)}></div>
          <div className={css(Styles.checkBox1)}></div>
        </div>
      </div>
    )
  }

  static renderTransparentTileBycolorCode = (colorCode) => {
    return colorCode === AppConfig.TRANSPARENT_COLOR_CODE ? UiHelper.renderTransparentTile() : ''
  }

  static renderDaysInWeek = (selectedDays) => {
    const daysOfWeekLong = Helper.getDaysOfWeekLong();
    return(
      <div className={css(Styles.weekDaysContainer)}>
        {daysOfWeekLong.map((day) => (
          <div
            key={day}
            className={css(Styles.dayCell,selectedDays.includes(day) ? Styles.activeDayCell : Styles.normalDayCell )}
          >
            {Helper.getShortDayFromLong(day)}
          </div>
        ))}
      </div>
    )
  }

  static getSequencesFieldDef(crmSequences, crmInboxes) {
    const sequencesFieldDef = {
      steps: [
        {
          fields: [
            {
              key: 'campaignId',
              type: 'radio',
              label: 'Drip Sequence',
              hint: 'Select drip sequence',
              options: [],
              v_required: { value: true, err: 'Select drip sequence' },
              placement: 'left'
            },
            {
              key: 'inboxId',
              type: 'radio',
              label: 'Sender Inbox',
              hint: 'Select sender inbox',
              options: [],
              v_required: { value: true, err: 'Select sender inbox' },
              placement: 'right'
            },
            {
              key: 'startDate',
              type: 'dateTime',
              label: 'Start date',
              hint: 'Select start date',
              v_required: { value: true, err: 'Select start date' },
              placement: 'left',
            },
          ]
        }
      ]
    }
    UiHelper.populateOptions(sequencesFieldDef, {items: crmSequences}, 'campaignId')
    const inboxField = sequencesFieldDef.steps[0].fields.find(f => f.key === 'inboxId')
    if (Array.isArray(crmInboxes) && crmInboxes.length > 0) {
      crmInboxes.forEach(inbox => {
        inboxField.options.push({key: inbox.id, label: inbox.email})
      })
      inboxField.defaultValue = crmInboxes[0].id
    }
    Helper.populateFormDefinitionValues(sequencesFieldDef, {
      campaignId: '',
      inboxId: inboxField.defaultValue || '',
      startDate: Helper.currentTs(),
    })
    return sequencesFieldDef
  }

  static openModalForSequences(items, crmSequences, crmInboxes) {
    this.apiData = {
      orgId: this.orgId,
      userIds: Array.isArray(items) ? items.map(u => u.userId) : [items.userId],
      staffUserId: this.staffUserId,
      seqEnrolmentNextStepIndex: 0,
    }
    this.formDefinition = UiHelper.getSequencesFieldDef(crmSequences, crmInboxes)
    this.formDefinition.formType = 'edit'
    this.formDefinition.ctaButtonText = 'Next'
    this.formDefinition.title = `Enroll ${this.apiData.userIds.length} ${this.apiData.userIds.length === 1 ? 'contact' : 'contacts'}`
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({
        ms: AppConfig.CDP_MS.ENDPOINT,
        paths: ['miscs', 'create-seq-enrolments'],
        queryParams: { orgId: this.orgId }
      }),
      modalOpen: true
    });
  }

  static openModalForSeqEnrolmentStep(stepIndex, steps, emailSignatureRichText, apiData) {
    this.apiData = apiData
    const step = steps[stepIndex]
    this.apiData.step = step
    const emailTemplate = JSON.parse(JSON.stringify(this.crmEmailTemplates.find(t => t.id === step.stepEmailTemplateId)))
    if (step.stepIncludeEmailSignature) {
      emailTemplate.bodyRichText = Helper.mergeRichText(emailTemplate.bodyRichText, emailSignatureRichText)
    }
    const fieldDef = JSON.parse(JSON.stringify(this.crmEmailTemplateFieldDef))
    fieldDef.steps[0].fields = fieldDef.steps[0].fields.filter(f => ['subject', 'bodyRichText', 'attachment'].includes(f.key))
    Helper.populateFormDefinitionValues(fieldDef, emailTemplate)
    this.formDefinition = fieldDef
    this.formDefinition.formType = 'add'
    this.formDefinition.ctaButtonText = stepIndex === steps.length - 1 ? 'Enroll' : 'Next'
    this.formDefinition.title = `Review step ${stepIndex + 1}/${steps.length}`
    this.setState({
      modalOpen: true
    });
  }

  static async customSubmitForSequences(options) {
    const { formType, formData, caller } = options

    const seqEnrolmentNextStepIndex = formData.seqEnrolmentNextStepIndex
    const subject = formData.subject
    const bodyRichText = formData.bodyRichText
    const attachment = formData.attachment
    const step = formData.step

    delete formData.seqEnrolmentNextStepIndex
    delete formData.subject
    delete formData.bodyRichText
    delete formData.attachment
    delete formData.step

    if (Number.isInteger(seqEnrolmentNextStepIndex)) {
      const apiData = {...formData}
      apiData.steps = apiData.steps || []
      if (step) {
        if (bodyRichText) step.emailBodyRichText = bodyRichText
        if (subject) step.emailSubject = subject
        if (attachment) step.emailAttachment = attachment
        apiData.steps.push(step)
      }
      const sequence = this.crmSequences.find(s => s.id === apiData.campaignId)
      const steps = Object.values(sequence.steps).sort((a, b) => a.entityCreatedTs - b.entityCreatedTs)
      if (seqEnrolmentNextStepIndex === steps.length) {
        return caller.submit.call(caller, apiData, true);
      } else {
        const fromInbox = this.crmInboxes.find(i => i.id === apiData.inboxId)
        const emailSignatureRichText = Helper.renderPlainText(fromInbox.signatureRichText) ? fromInbox.signatureRichText : (Helper.renderPlainText(this.crmUserSettings.emailSignatureRichText) ? this.crmUserSettings.emailSignatureRichText : '')
        apiData.seqEnrolmentNextStepIndex = seqEnrolmentNextStepIndex + 1
        this.setState({ fetchState: ApiHelper.State.READY, modalOpen: false }, () => {
          UiHelper.openModalForSeqEnrolmentStep.call(this, seqEnrolmentNextStepIndex, steps, emailSignatureRichText, apiData)
        })
      }
    }
  }
  static renderEmailClickTrail(emailClickTrail) {
    let out = ''
    emailClickTrail.forEach(clickData => {
      if (typeof clickData === 'string') {
        out = out + clickData + '\n\n'
      } else if (typeof clickData === 'object') {
        out = out + `${clickData.anchor} [${clickData.href}]\n\n`
      }
    })
    return out.trim()
  }
  static openModalForCreateCopy(current, type, ) {
    this.apiData = {
      orgId: this.orgId,
      id: current.id,
      type,
      _clientVersion: AppConfig.VERSION,
    }
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['miscs', 'create-copy'], queryParams: {} }),
    });
    this.formDefinition = {
      steps: [
        {
          fields: [
            {
              key: 'title',
              value: 'Copy of ' + current.title,
              type: 'edit_text',
              label: 'Title',
              v_required: { value: true, err: 'Provide title' },
              v_min: { value: 2, err: 'Title is too short (2 - 100)' },
              v_max: { value: 100, err: 'Title is too long (2 - 100)' },
              placement: 'left'
            },
          ]
        }
      ],
      title: 'Make a copy',
      ctaButtonText: 'Create',
      formType: 'add',
      _subType: 'create-copy',
    }
    this.setState({
      modalOpen: true
    });
  }

  static openModalForEnrich(items) {
    this.apiData = {
      orgId: this.orgId,
      userIds: Array.isArray(items) ? items.map(u => u.userId) : items.userId,
    }
    this.formDefinition = {
      steps: [
        {
          fields: [
            {
              key: 'enrichFields',
              type: 'radio',
              label: 'Fields',
              options: [{key: 'FRIENDLY NAME AND COMPANY NAME', label: 'FRIENDLY NAME AND COMPANY NAME'}],
              defaultValue: 'FRIENDLY NAME AND COMPANY NAME',
              v_required: { value: true, err: 'Select fields to enrich' },
              placement: 'left'
            },
          ]
        }
      ]
    }
    this.formDefinition.formType = 'add'
    this.formDefinition.ctaButtonText = 'Enrich'
    this.formDefinition.title = Array.isArray(this.apiData.userIds) ? `Enrich ${this.apiData.userIds.length} ${this.apiData.userIds.length === 1 ? 'contact' : 'contacts'}` : 'Enrich contact'
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({
        ms: AppConfig.CDP_MS.ENDPOINT,
        paths: ['miscs', 'enrich-crm-contact'],
        queryParams: { orgId: this.orgId }
      }),
      modalOpen: true
    });
  }

  static extractSettingsKey(current) {
    const keys = Object.keys(current).filter(k => k !== '__index')
    return keys.length ? keys[0] : ''
  }
  static extractSettingsValue(current) {
    const key = this.extractSettingsKey(current)
    return key ? current[key] : ''
  }
  static extractSettingsFields(current, fieldsAttrs) {
    const key = this.extractSettingsKey(current)
    return fieldsAttrs.filter(i => i.key === key)
  }
  static extractSettingsLabel(current, fieldsAttrs) {
    const fields = this.extractSettingsFields(current, fieldsAttrs)
    return fields.length ? fields[0].label : ''
  }
  static removeFields(fieldDef, keys) {
    const fieldDefCp = JSON.parse(JSON.stringify(fieldDef))
    fieldDefCp.steps[0].fields = fieldDefCp.steps[0].fields.filter(f => !keys.includes(f.key))
    return fieldDefCp
  }
  static reflowFields(fieldDef) {
    const fieldDefCp = JSON.parse(JSON.stringify(fieldDef))
    let dir = 'left'
    for (const f of fieldDefCp.steps[0].fields) {
      f.placement = dir
      dir = dir === 'left' ? 'right' : 'left'
    }
    return fieldDefCp
  }
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    height: '100vh',
  },
  container2: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    flexDirection: 'column',
  },
  loadingViewOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.1)',
    zIndex: 9998,

  },
  transparentLoadingViewContainer: {
    position: 'fixed',
    top: '50%',
    left: '60%',
    transform: 'translate(-75%, -50%)',
    zIndex: 1000,
  },
  emptyContainer: {
    minHeight: '75vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 24,
    color: '#7D7D7D'
  },
  progress: {
    color: AppTheme.primaryColor
  },
  error: {
    textAlign: 'center',
    maxWidth: 500
  },
  errorContainer: {
    flex: 1,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: AppTheme.primaryBg,
    flexDirection: 'column'
  },
  errImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 120,
    height: 120,
    backgroundColor: 'white',
    padding: 20,
    borderRadius: '50%'
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
  nfMiddle: {
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  valueText: {
    fontSize: 16,
    color: '#000000'
  },
  spanContainer: {
    display: 'flex'
  },
  spanTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  spanImgContainer: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12
  },
  imageContent: {
    height: '100%',
    width: '100%',
    borderRadius: 6,
    objectFit: 'cover'
  },
  slugSmContainer:{
    display:'flex',
    alignItems:'center',
    gap:5
  },
  itemValue: {
    fontSize: 16
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  },
  statusOuterContainer: {
    display: 'flex'
  },
  statusTitle: {
    color: '#00CA6F',
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    marginRight: 18,
    textTransform: 'capitalize',
    minWidth: 80
  },
  statusTitleStyle: {
    color: '#222222',
  },
  countContainer: {
    display: 'flex'
  },
  countSecondContainer: {
    marginTop: 8
  },
  imageContainer: {
    width: 20,
    height: 20
  },
  statusValue: {
    fontSize: 16,
    color: '#7D7D7D',
    margin: 0,
    marginLeft: 8
  },
  customEmptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  customImageContainer: {
    marginTop: 200,
    width: 250 / 2,
    height: 162 / 2
  },
  customText: {
    color: '#7D7D7D',
    fontSize: 26,
    margin: 0,
    marginTop: 24,
    marginBottom: 24
  },
  loaderContainer: {
    position: 'relative'
  },
  loaderImageContainer: {
    position: 'absolute',
    width: 39,
    height: 41,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  },
  actionMenuItem: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    cursor: 'pointer'
  },
  actionMenuText: {
    fontSize: 14,
    color: '#222222',
    margin: 0,
    marginRight: 10,
    flex: 1
  },
  transparentOuterContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column'
  },
  checkBox1: {
    flex: 1,
    backgroundColor: '#fffffff'
  },
  checkBox2: {
    flex: 1,
    backgroundColor: '#f5f5f5'
  },
  transparentInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  weekDaysContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4,
    fontSize: 12,
    marginTop: 7,
    marginBottom: 7
  },
  dayCell: {
    minWidth: 25,
    minHeight: 5,
    padding: 2,
    textAlign: 'center'
  },
  normalDayCell: {
    border: '0.5px solid grey',
    borderRadius: 4,
    color: 'lightgrey',
  },
  activeDayCell: {
    border: '0.5px solid grey',
    borderRadius: 4,
    color: 'darkgrey',
    fontWeight: 'bold'
  },
  orderChangeBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 0,
    width: '100%',
    height: 46,
  },
  orderChangeBtn: {
    padding: 0,
    fontSize: 7,
  },
  orderChangeBtnDisabled: {
    padding: 0,
    fontSize: 7,
    color: 'gray',
  },
  orderText: {
    fontSize: 14,
    color: 'gray'
  },
})
