import { CampaignsBase } from './CampaignsBase';
import { Helper } from '../../../common/helpers/Helper';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppConfig } from '../../../AppConfig';

export class Broadcasts extends CampaignsBase {
  constructor(props, options) {
    super(props, {
      ...options,
      pageTitle: Helper.getString('broadcasts'),
      pageKey: 'broadcasts',
      campaignType: 'BROADCAST',
    });
  }
  async customSubmit(options) {
    const { formType, formData, caller } = options
    if (formType === 'add') {
      let paths = []
      if (this.formDefinition._subType === 'create-copy') {
        paths = ['miscs', 'create-copy']
      } else {
        paths = ['miscs', 'create-campaign']
      }
      ApiHelper.callAwait({
        ms: AppConfig.CDP_MS.ENDPOINT,
        method: 'POST',
        paths,
        jsonBody: formData,
      }).then(response => {
        window.open(this.baseRoute() + `/campaigns/${response.id}`, '_self')
      }).catch(err => {
        const errorMessage = Helper.getErrorMsg(err)
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: errorMessage,
          success: false,
          message: errorMessage,
          inProcess: false
        })
      })
      return
    }
    return caller.submit.call(caller, formData, true);
  }
}
