import React, { Component } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { AppTheme, AppStyles } from '../../styles/AppTheme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { NextPage } from '../components/NextPage'
import { Tooltip } from '@material-ui/core';
import { Helper } from '../helpers/Helper';
import { MenuItemsDropdown } from './MenuItemsDropdown';
import { CustomTableComponentStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { AppHelper } from '../../helpers/AppHelper';
import { UiHelper } from '../helpers/UiHelper';
import { ApiHelper } from '../helpers/ApiHelper';

export class TableComponent extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      checkedAll:false,
      orderUIMode: ApiHelper.ORDER_UI_MODE.STATIC
    }
  }

  renderHeadActions(cellWidths) {
    return (
      <TableCell className={css([Styles.cellHead, cellWidths.actions])}>
      </TableCell>
    )
  }

  renderHead() {
    if (this.props.noTableHead) return ''
    const { tableHeadersMap } = this.props
    const tableHeaders = tableHeadersMap ? Object.keys(tableHeadersMap) : this.props.tableHeaders
    const cellWidths = this.props.cellWidths || {}
    return (
      <TableHead>
        <TableRow>
          {tableHeaders.map((header, index) => (
            <TableCell
              className={(index === 0) ? css([Styles.cellHead, Styles.firstcellBody, cellWidths[header]]) : css([Styles.cellHead, cellWidths[header]])}
              key={'headCol' + index}>{header}
            </TableCell>
          ))}
          {this.renderHeadActions(cellWidths)}
        </TableRow>
      </TableHead>
    )
  }

  renderBodyActions(current, itemIndex, cellWidths) {
    const swapRowIndex = (this.props.orderChangeEvent && this.props.currentOrderChangeIndex >= 0)
      ? (this.props.orderChangeEvent === 'up' ? this.props.currentOrderChangeIndex - 1 : this.props.currentOrderChangeIndex + 1) : null
    return (
      <TableCell
        className={css(
          [Styles.cellBody, Styles.cellBodyIcons, cellWidths.actions],
          ((this.props.fetchState === ApiHelper.State.READY) && this.props.isOrderChanged && swapRowIndex !== null && (itemIndex === swapRowIndex)) && Styles.cellBodySwap,
        )}
      >
        <div className={css(Styles.divIcon)}>
          {this.setUpActions(current, itemIndex)}
          {this.setUpMenuItems(current, itemIndex)}
        </div>
      </TableCell>
    )
  }

  renderBody() {
    const { tableHeadersMap, onTableValue, items, orderUp, orderDown, fetchState, currentOrderChangeIndex  } = this.props
    const tableHeaders = tableHeadersMap ? Object.keys(tableHeadersMap) : this.props.tableHeaders
    const lastElmntIndex = this.props.tableHeaders ? this.props.tableHeaders.length - 1 : undefined;
    const cellWidths = this.props.cellWidths || {}
    const checkedItems = this.props.items.filter(item => item._selected === true)
    const orderUIMode = this.props.orderUIMode || this.state.orderUIMode

    const swapRowIndex = (this.props.orderChangeEvent && this.props.currentOrderChangeIndex >= 0)
      ? (this.props.orderChangeEvent === 'up' ? this.props.currentOrderChangeIndex - 1 : this.props.currentOrderChangeIndex + 1) : null

    return (
      <TableBody>
        {this.renderHeaderAction(tableHeaders)}
        {
          items.map((current, itemIndex) => {
            current.__index = itemIndex
            return (
              <TableRow key={current.id}>
                {this.props.displayCheckBoxTable && this.props.checkBoxActions(checkedItems).length > 0 && this.renderCheckbox(current, itemIndex)}
                {tableHeaders.map((f, headerIndex) => (
                  <TableCell key={current.id + headerIndex}
                    className={css(
                      (headerIndex === 0) ? [Styles.cellBody, Styles.firstcellBody, cellWidths[f]] : Styles.cellBody,
                      ((this.props.fetchState === ApiHelper.State.READY) && this.props.isOrderChanged && swapRowIndex !== null && (itemIndex === swapRowIndex)) && Styles.cellBodySwap,
                    )}
                  >
                    {tableHeadersMap ?
                      ((current.order && (orderUIMode === ApiHelper.ORDER_UI_MODE.INTERACTIVE) &&  (tableHeaders[headerIndex] === 'Order')) ?
                        UiHelper.buttonOrderChange(current, { items, index: itemIndex, orderUp, orderDown, fetchState, currentOrderChangeIndex }, tableHeadersMap[f](current))
                        : tableHeadersMap[f](current))
                      : onTableValue(current, headerIndex)}
                  </TableCell>
                ))}
                {this.renderBodyActions(current, itemIndex, cellWidths)}
              </TableRow>
            )
          })
        }
      </TableBody >
    )
  }
  renderHeaderAction(tableHeaders) {
    const checkedItems = this.props.items.filter(item => item._selected === true)
    const allActions = this.props.checkBoxActions ? this.props.checkBoxActions(checkedItems) : []
    const checkBoxMenuActions = this.props.checkBoxMenuActions ? this.props.checkBoxMenuActions(checkedItems) : []
    if (!this.props.displayCheckBoxTable || allActions.length < 1) return (null)
    return (
      <TableRow key={'selectAllCell'}>
        <TableCell className={css([Styles.cellBody, Styles.checkboxCell, Styles.firstCheckHeaderCell])} colSpan={tableHeaders.length + 1}>
          <Checkbox
            onChange={(e) => {
              this.setState({ checkedAll: e.target.checked })
              this.props.items.map(x => x._selected = e.target.checked)
              this.props.setItems(this.props.items)
            }}
            name={'checkAllItems'} color='primary'
            checked={this.state.checkedAll} />
          You have selected {checkedItems ? checkedItems.length : 0} {checkedItems.length === 1 ? this.props.checkboxTableItemNameSingular || 'item' : this.props.checkboxTableItemNamePlural || 'items'}
        </TableCell>
        <TableCell key={'actionCell'} className={css([Styles.cellBody, Styles.checkboxCell, Styles.endCheckHeaderCell])}>
          <div className={css(Styles.headerActionsContainer)}>
            { checkedItems && checkedItems.length > 0 && allActions }
            { checkedItems && checkedItems.length > 0 && checkBoxMenuActions.length > 0 && this.renderHeaderMenu(checkBoxMenuActions)}
          </div>
        </TableCell>
      </TableRow>
    )
  }

  renderHeaderMenu(checkBoxMenuActions) {
    return <MenuItemsDropdown checkBoxMenuActions={checkBoxMenuActions}/>
  }

  renderCheckbox(current) {
    return (
      <TableCell key={current.id + 'checkbox'} className={css([Styles.cellBody, Styles.checkboxCell])}>
        <Checkbox
          color='primary'
          onChange={(event) => this.onCheckboxChange(event, current)}
          onClick={(event) => this.handleCheckboxClick(event, current)}
          checked={current._selected || false}
          className={css((this.state.activeCheckboxIndex === current.__index) && Styles.checkboxActive)}
          // disabled={this.state.checkedAll || false}
        />
      </TableCell>
    )
  }

  onCheckboxChange = (event, current) => {
    current._selected = event.target.checked
    this.props.setItems(this.props.items)
    if (this.props.items.length > this.props.items.filter(s => s._selected === true).length && this.state.checkedAll !== false) {
      this.setState({ checkedAll: false })
    } else if (this.props.items.length === this.props.items.filter(s => s._selected === true).length && this.state.checkedAll !== true) {
      this.setState({ checkedAll: true })
    }
  }

  handleCheckboxClick = (event, current) => {
    const currentItemIndex = current.__index
    if(event.shiftKey) {
      if(Number.isInteger(this.state.activeCheckboxIndex) && this.state.activeCheckboxIndex !== currentItemIndex) {
        const startItemIndex = Math.min(this.state.activeCheckboxIndex, currentItemIndex)
        const endItemIndex = Math.max(this.state.activeCheckboxIndex, currentItemIndex)

        this.props.items.forEach((item, index) => {
          if (index >= startItemIndex && index <= endItemIndex) {
            item._selected = event.target.checked
          }
        })
      }
    }
    this.setState({activeCheckboxIndex: currentItemIndex})
  }

  setUpMenuItems(current, index) {
    if (this.props.onMenuItems) {
      return <MenuItemsDropdown onMenuItems={this.props.onMenuItems} current={current} />
    }
  }

  setUpActions(current, index) {
    // this.headerActions - if any action is multi: actions.push('checkbox')
    let isMulti = false
    if (this.props.headerActions) {
      for (const action of this.props.headerActions) {
        if (action.multi) {
          isMulti = true;
          break;
        }
      }
    }

    let actions = []
    if (this.props.onActions) {
      actions = Object.assign([], this.props.onActions(current))
    }
    if (isMulti) {
      actions.push(this.addButtonCheckbox(current, index))
    }
    return actions;
  }

  addButtonCheckbox(current, index) {
    return (
      <Tooltip title='Check' placement='top-start' key={current.id + 'checkTT'}>
        <span className={css(AppStyles.entityTableLinkIcon, (current.__checked ? Styles.buttonSpanUnchecked : Styles.buttonSpanChecked))}>
          <i className='material-icons-outlined' color='blue' onClick={() => this.handleCheck(current, index)}>check_circle</i>
        </span>
      </Tooltip >
    )
  }

  handleCheck(current, index) {
    let itemsList = [];
    itemsList = Object.assign([], this.props.items)
    if (itemsList[index].__checked && itemsList[index].__checked) {
      current.__checked = false
    } else {
      current.__checked = true
    }
    this.props.setItems(itemsList)
  }

  render() {
    return (
      <div className={css(AppStyles.entityContent, Styles.content)}>
        {this.props.items.length > 0 ?
          <div className={css(Styles.divTable)}>
            <Table className={css(Styles.table)}>
              {this.renderHead()}
              {this.renderBody()}
            </Table>
          </div>
          :
          UiHelper.emptyView(this.props.emptyText)
        }
        {this.props.nextPage &&
          (<NextPage
            itemCount={this.props.items.length}
            nextPageState={this.props.nextPageState}
            onNext={this.props.onNext}></NextPage>)}
      </div>
    );
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomTableComponentStyles : StyleSheet.create({
  content: {
    paddingBottom: 0
  },
  divTable: {
    display: 'block',
    overflowX: 'auto',
    width: '100%',
    '@media (max-width: 320px)': {
      maxWidth: 310,
    },
    '@media (max-width: 767px) and (min-width: 321px)': {
      maxWidth: 350,
    },
    '@media (min-width: 768px) and (max-width: 1021px)': {
      maxWidth: 750,
    },
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
  },
  entityAddIcon: {
    fontSize: 36,
    color: 'black',
    cursor: 'pointer',
  },
  cellHead: {
    backgroundColor: 'white',
    color: '#9B9B9B',
    height: 38,
    fontSize: 16,
    fontWeight: 'bold',
    padding: '8px 8px 8px 8px',
  },
  cellBodyIcons: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  cellBody: {
    backgroundColor: 'white',
    color: '#4A4A4A',
    minHeight: 72,
    border: '1px solid #FFFFFF',
    fontSize: 16,
    padding: '8px 8px 8px 8px',
    wordBreak: 'break-all'
  },
  firstcellBody: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    paddingLeft: 20
  },
  divIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  firstCheckHeaderCell:{
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16
  },
  endCheckHeaderCell:{
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16
  }
})
