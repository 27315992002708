import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppStyles, AppTheme } from '../../../styles/AppTheme'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CommonTheme } from '../../../styles/Styles';
import { Helper } from '../../helpers/Helper';
import { AppButton } from '../AppButton';
import { ApiHelper } from '../../helpers/ApiHelper';
import { Button, Dialog, Icon, TableContainer } from '@material-ui/core';

class TableCard extends Component {
  constructor(props) {
    super(props);
    this.defaultErrMsg = 'An error occured.'
    this.state = {
      inProcess: false,
      modalOpen: false,
    };
  }

  openModalForViewAll() {
    this.setState({
      modalOpen: true
    });
  }

  onCloseModal() {
    this.setState({
      modalOpen: false
    });
  }

  fullWidthCondition(fullWidthDialog) {
    if (fullWidthDialog) {
      return true
    }
    if (window.innerWidth && window.innerWidth < 480) {
      return true
    }
    return false
  }

  renderModal() {
    const {headers, rows} = this.props.item.data
    const fullWidthDialog = (this.props.fullWidthDialog && window.innerWidth > 480)
    return(<Dialog
      open={this.state.modalOpen}
      fullWidth={true}
      fullScreen={this.fullWidthCondition(fullWidthDialog)}
      classes={{
        root: css(Styles.customRoot),
        paper: css(Styles.customPaper),
      }}
      BackdropProps={fullWidthDialog && { style: { backgroundColor: 'unset' } }}
      maxWidth='md'
    >
      <div className={css([Styles.modalHeader, AppStyles.modalHeader])}>
        <div className={css(Styles.modalTitle)}>{this.props.item.title}</div>
        <button className={css(Styles.modalCloseButton)} onClick={this.onCloseModal.bind(this)} disabled={this.state.inProcess}>
          <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
            <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
          </svg>
        </button>
      </div>
      <div className={css(Styles.modalContent)}>
        <TableContainer className={css(Styles.modalTableContainer)}>
          {(headers && headers.length > 0 && rows && rows.length > 0)  ? (<Table stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index}  className={index === 0 ? css([Styles.cellBody, Styles.firstColumn]) : css(Styles.cellHead)}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => {
                    const isDimensionFilterEnabled = this.props.item.onTableDimensionFilterSelected && (cellIndex === 0)
                    return(
                      <TableCell key={cellIndex}
                        className={cellIndex === 0 ? (isDimensionFilterEnabled ? css([Styles.cellBody, Styles.modalFirstColumn, Styles.dimensionFilter]) : css([Styles.cellBody, Styles.modalFirstColumn])) : css(Styles.cellBody)}
                        onClick={ isDimensionFilterEnabled && (()=>this.props.item.onTableDimensionFilterSelected(cell))}
                      >
                        {cell}
                      </TableCell>
                    )}
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>) : (<div className={css(Styles.emptyViewContainer)}><p className={css(Styles.emptyViewText)}>{Helper.getString('noDataAvailable')}</p></div>)}
        </TableContainer>
      </div>
    </Dialog>)
  }

  render() {
    const {headers, rows} = this.props.item.data

    return (
      <div className={css(this.props.isTabTable ? [Styles.outerContainer, Styles.tabTableOuterContainer] : Styles.outerContainer)}>
        {this.props.item.title && <div className={css(Styles.paperTitle)} elevation={0}>
          <p className={css(Styles.paragraphMargin)}>{this.props.item.title}</p>
        </div>}
        <div>
          {this.state.inProcess &&
            <div className={css(Styles.loader)}>
              <CircularProgress size={48} className={css(Styles.progress)} />
            </div>
          }
        </div>
        <TableContainer  className={css(Styles.tableContainer)}>
          {(headers && headers.length > 0 && rows && rows.length > 0)  ? (<Table stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index}  className={index === 0 ? css([Styles.cellBody, Styles.firstColumn]) : css(Styles.cellHead)}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(0,10).map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => {
                    const isDimensionFilterEnabled = this.props.item.onTableDimensionFilterSelected && (cellIndex === 0)
                    return(
                      <TableCell key={cellIndex}
                        className={cellIndex === 0 ? (isDimensionFilterEnabled ? css([Styles.cellBody, Styles.firstColumn, Styles.dimensionFilter]) : css([Styles.cellBody, Styles.firstColumn])) : css(Styles.cellBody)}
                        onClick={ isDimensionFilterEnabled && (()=>this.props.item.onTableDimensionFilterSelected(cell))}
                      >
                        {cell}
                      </TableCell>
                    )}
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>) : (<div className={css(Styles.emptyViewContainer)}><p className={css(Styles.emptyViewText)}>{Helper.getString('noDataAvailable')}</p></div>)}
          {(rows && rows.length >= 10) && <div className={css(Styles.tableFooterContainer)}>
            <AppButton
              onClick={this.openModalForViewAll.bind(this)}
              buttonText={'SHOW MORE'}
              buttonStyle={Styles.button}
              buttonTextStyle={Styles.buttonText}
            />
          </div>}
        </TableContainer >
        {this.state.modalOpen ? this.renderModal() : ''}
      </div>
    );
  }
}

export default TableCard;

const Styles = StyleSheet.create({
  outerContainer: {
    border: '1px solid ' + AppTheme.borderColor,
    padding: '10px 20px 20px 20px',
    borderRadius: AppTheme.borderRadius,
    flex: 1,
    '@media (max-width: 767px)': {
      padding: '20px 10px',
      borderRadius: 8,
    },
  },
  tabTableOuterContainer: {
    border: 0,
  },
  tableContainer: {
    position: 'relative',
  },
  modalTableContainer: {
    position: 'relative',
    maxHeight: 680,
    overflowY: 'auto',
  },
  paperFooter: {
    marginBottom: 0,
    display: 'flex',
    verticalAlign: 'top',
    justifyContent: 'flex-end'
  },
  paperTitle: {
    height: '80px',
    fontWeight: 'bold',
    color: AppTheme.white,
    display: 'flex',
    alignItems: 'center'
  },
  subText: {
    display: 'inline-block',
    fontSize: 24,
    fontWeight: 'bold',
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'default',
    '@media(max-width: 768px)': {
      fontSize: 12
    }
  },
  arrowUp: {
    color: 'green'
  },
  arrowDown: {
    color: 'red'
  },
  videoContainerTile: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paragraphMargin: {
    margin: 0,
    flex: 1,
    fontSize: 20,
    fontWeight: 500,
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  },
  progress: {
    color: AppTheme.white
  },
  loader: {
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },


  cellHead: {
    backgroundColor: 'white',
    color: '#9B9B9B',
    height: 38,
    fontSize: 16,
    fontWeight: 'bold',
    padding: '8px 8px 8px 8px',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      maxWidth: 32,
      fontSize: 10,
    },
  },
  cellBody: {
    backgroundColor: 'white',
    color: CommonTheme.textColor,
    // minHeight: 72,
    borderBottom: '1px solid ' + AppTheme.borderColor,
    fontSize: 16,
    textAlign: 'center',
    padding: '16px 5px',
    verticalAlign: 'middle',
    wordBreak: 'break-all',
    '@media (max-width: 767px)': {
      fontSize: 10,
    },
  },
  tableFooterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 15,
  },
  firstColumn: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 200,
    '@media(max-width: 767px)': {
      maxWidth: 'unset',
      whiteSpace: 'unset',
    }
  },
  modalFirstColumn: {
    textAlign: 'left',
    '@media(max-width: 767px)': {
      maxWidth: 'unset',
      whiteSpace: 'unset',
    }
  },
  dimensionFilter: {
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
      textDecorationColor: '#9B9B9B',
      textUnderlineOffset: 2,
    }
  },
  emptyViewContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyViewText: {
    color: 'grey'
  },
  button: {
    color: AppTheme.primaryColor,
    border: '1px solid #E5E5E5',
    backgroundColor: AppTheme.secondaryBg
  },
  buttonText: {
    color: AppTheme.primaryColor,
    fontWeight: 500
  },
  modalHeader: {
    // backgroundColor: 'whitesmoke',
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 14,
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    gap: 20,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalTitle: {
    color: CommonTheme.textColor,
    fontSize: 26,
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    // marginRight: 28,
    flex: 1,
    '@media (max-width: 767px)': {
      marginRight: 43,
    }
  },
  modalContent: {
    paddingLeft: 30,
    paddingRight: 30,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalCloseButton: {
    // position: 'absolute',
    // top: 23,
    // right: 30,
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    border: 'none'
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: 8
  },
  root: {
    zIndex: 13000,
  },
  paper: {
    borderRadius: AppTheme.borderRadius,
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  customRoot: {
    zIndex: 13000
  },
  customPaper: {
    borderRadius: AppTheme.borderRadius,
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
});
