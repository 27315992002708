
import { StyleSheet } from 'aphrodite';
import { AppTheme, AppStyles } from './AppTheme';
import { AppHelper } from '../helpers/AppHelper';
import { CommonTheme, CommonStyles } from './Styles';
import { AppConfig } from '../AppConfig';
const IMG_RENDER_SIZE = 135
const ICON_SIZE = 35
const FIELD_HEIGHT = 54

export const CustomBaseEnityStyles = StyleSheet.create({
  entityContainer: {
    marginTop: AppTheme.headerbarHeight,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    backgroundColor: AppTheme.secondaryBg,
    marginLeft: AppTheme.adminPagePadding,
    marginRight: AppTheme.adminPagePadding,
    maxWidth: AppTheme.viewPort,
    '@media (max-width: 767px)': {
      marginLeft: 10,
      marginRight: 10,
    }
  },
  disabledEntityContainer: {
    pointerEvents: 'none',
    userSelect: 'none',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  breadCrumbsContainer: {
    alignItems: 'flex-start',
    paddingBottom: 0
  }
})

export const CustomHeaderbarStyles = StyleSheet.create({
  container: {
    position: 'fixed',
    top: 0,
    paddingTop: AppTheme.toolbarHeight ? 0 : 20,
    paddingBottom: AppTheme.toolbarHeight ? 0 : 20,
    minHeight: AppTheme.toolbarHeight, //TODO: creates issue in mobile view
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    // width: window.innerWidth - AppTheme.drawerWidth - 90,
    // width: '-webkit-fill-available',
    // marginBottom: 5,
    zIndex: 1000,
    maxWidth: AppTheme.viewPort,
    marginLeft: AppTheme.adminPagePadding,
    marginRight: AppTheme.adminPagePadding,
    '@media (max-width: 1024px)': {
      width: '-webkit-fill-available',
    },
    // '@media (max-width: 1030px)': {
    //   width: window.innerWidth - AppTheme.drawerWidth - 90, //TODO the width value when drawer is close
    // },
    '@media (max-width: 767px)': {
      marginLeft: 10,
      marginRight: 10,
    }
  },
  containerNarrow: {
    maxWidth: AppTheme.viewPort,
    display: 'flex',
    flex: 1,
    alignItems:'center'
  },
  containerTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    marginRight: 20
  },
  containerTitleContent: {
    display: 'grid'
  },
  headerImage:{
    height:80,
    aspectRatio:'1200/630',
    marginRight:10,
    objectFit:'contain',
    borderRadius:12,
    border: '1.5px solid #e5e5e5',
    overflow: 'hidden'
  },
  title: {
    color: CommonTheme.textColor,
    // display: 'flex',
    // justifyContent: 'flex-start',
    // alignItems: 'center',
    fontSize: 30,
    fontWeight: 600,
    margin: 0,
    textTransform: 'capitalize',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '@media (max-width: 767px)': {
      fontSize: 25
    }
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
    flexDirection: 'column-reverse',

    // '@media (max-width: 767px)': {
    //   marginBottom: 5,
    //   marginTop: 5
    // }
  },
  containerButtonInner: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorMessage: {
    marginTop: 5,
    marginLeft: 15,
    alignSelf: 'flex-start',
    color: '#f44336'
  },
  actionComponent: {
    marginLeft: 10
  },
  button: {
    minWidth: 180,
    marginLeft: 10
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: 500,
    color: AppTheme.buttonTextColor,
    textTransform: 'capitalize',
    marginLeft: 5,
    lineHeight: '19px'
  },
  iconContainer: {
    border: '1px solid' + AppTheme.buttonTextColor,
    borderRadius: 8,
    display: 'flex',
    padding: 2,
    marginRight: 7,
    color: 'white'
  },
  iconLabelContainer: {
    width: 48,
    color: AppTheme.primaryColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconLabelText: {
    margin: 0,
    fontSize: 12,
    textTransform: 'capitalize',
  },
  link: {
    textDecoration: 'none',
    marginLeft: 20,
    '@media (max-width: 767px)': {
      marginLeft: 5,
    }
  },
  imageContainer: {
    width: 48,
    height: 48,
    backgroundColor: AppTheme.backgroundColor,
    borderRadius: '50%',
    display: 'flex',
    cursor: 'pointer',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 14
  },
  icon: {
    margin: 14
  },
  tooltip: {
    marginTop: -8
  }
})

export const CustomBreadCrumbsStyles = StyleSheet.create({
  breadCrumbsContainer: {
    marginTop: 5,
    fontSize: 18,
    color: CommonTheme.subTextColor,
    // marginBottom: -25
  },
  textStyle: {
    color: CommonTheme.subTextColor,
    fontSize: 18,
    textTransform: 'capitalize',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  lastTextStyle: {
    color: CommonTheme.subTextColor,
    fontSize: 18,
    textTransform: 'capitalize',
  },
  link: {
    textDecoration: 'none'
  },
  textStyleButton: {
    textDecoration: 'none',
    backgroundColor: AppTheme.buttonBg,
    color: AppTheme.buttonTextColor,
    padding: '4px 10px',
    borderRadius: 18,
    fontSize: 14,
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center'
  }
})


export const CustomSearchBarStyles = StyleSheet.create({
  container: {
    minHeight: AppTheme.toolbarHeight * 2 / 3,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 16,
    maxWidth: AppTheme.viewPort,
  },
  searchInput: {
    minWidth: 150,
    padding: 10,
    paddingLeft: 22,
    border: '1px solid #E5E5E5',
    textDecoration: 'none',
    borderRadius: 16
  },
  button: {
    color: AppTheme.primaryColor,
    boxShadow: 'none',
    border: '1px solid #E5E5E5',
    borderRadius: 16,
    backgroundColor: AppTheme.secondaryBg,
    width: '100%',
    fontSize: 16,
    fontWeight: 500,
    padding: 17,
    minWidth: 'unset',
    lineHeight: 'unset',
    textTransform: 'unset',
    '@media (max-width: 767px)': {
      marginTop: 15
    }
  },
  buttonSearchDisabled: {
    color: '#222222',
    boxShadow: 'none',
    border: '1px solid #E5E5E5',
    borderRadius: 16,
    backgroundColor: AppTheme.secondaryColor,
    width: '100%',
    fontSize: 16,
    fontWeight: 500,
    padding: 17,
    minWidth: 'unset',
    lineHeight: 'unset',
    textTransform: 'unset',
    '@media (max-width: 767px)': {
      marginTop: 15
    }
  },
  buttonSearchEnabled : {
    color: AppTheme.buttonTextColor,
    boxShadow: 'none',
    border: '1px solid #E5E5E5',
    // border: '2px solid #0246E5',
    borderRadius: 16,
    backgroundColor: AppTheme.buttonBg,
    width: '100%',
    fontSize: 16,
    fontWeight: 500,
    padding: 17,
    minWidth: 'unset',
    lineHeight: 'unset',
    textTransform: 'unset',
    '@media (max-width: 767px)': {
      marginTop: 15
    }
  },
  filtersButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: AppTheme.primaryColor,
    boxShadow: 'none',
    border: '1px solid #E5E5E5',
    borderRadius: 16,
    backgroundColor: AppTheme.secondaryBg,
    fontSize: 16,
    fontWeight: 500,
    minWidth: 'unset',
    lineHeight: 'unset',
    height: 54,
    width: 54,
    textTransform: 'unset',
    // '@media (max-width: 767px)': {
    //   marginTop: 15
    // }
  },
  filtersButtonSelected: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: AppTheme.primaryColor,
    boxShadow: 'none',
    border: '2px solid #0246E5',
    borderRadius: 16,
    backgroundColor: 'unset',
    fontSize: 16,
    fontWeight: 500,
    minWidth: 'unset',
    lineHeight: 'unset',
    height: 54,
    width: 54,
    textTransform: 'unset',
    // '@media (max-width: 767px)': {
    //   marginTop: 15
    // }
  },
  iconContainerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    color: AppTheme.primaryColor,
    cursor: 'pointer',
    margin: 0,
    border: 'unset',
  },
  selectedIconContainerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    color: AppTheme.primaryColor,
    border: 'unset',
    cursor: 'pointer',
    margin: 0,
  },
  iconStyle: {
    fontSize: 28
  },
  formControl: {
    margin: 0,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '@media (max-width: 320px)': {
      maxWidth: 300,
    },
    maxWidth: AppTheme.viewPort,
    backgroundColor: 'white',
    width: '100%',
  },
  rightGridPadding: {
    paddingLeft: 19,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    }
  },
  buttonText: {
    color: AppTheme.primaryColor
  },
  buttonTextDisabled: {
    color: '#808080'
  },
  buttonTextEnabled: {
    color: AppTheme.buttonTextColor
  },
  innerContainer: {
    display: 'flex',
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  searchContainer: {
    flex: 1
  },
  buttonInnerContainer: {
    paddingLeft: 10,
    minWidth: 130,
    '@media (max-width: 767px)': {
      flex: 1,
      paddingLeft: 0
    }
  },
  buttonFiltersInnerContainer: {
    paddingLeft: 10,
    '@media (max-width: 767px)': {
      flex: 1,
      paddingLeft: 0,
      paddingTop: 10,
    }
  },
  filtersContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    marginBottom: '16px',
    '@media (max-width: 767px)': {
      gap: '4px',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  formControlMain: {
    marginTop: 0,
    marginBottom: 0
  },
  labelContainer: {
    display: 'flex',
    marginBottom: 8
  },
  labelTooltipInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 2,
  },
  labelInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 10,
  },
  isEqualSelect: {
    minWidth: 110,
    paddingLeft: 10,
    paddingRight: 5,
    border: '1px solid #E5E5E5',
    textDecoration: 'none',
    borderRadius: 16,
    fontSize: 14,
    color: '#222222',
  },
  isEqualSelection: {
    color: '#222222',
    ':focus': {
      backgroundColor: 'inherit !important',
    }
  },
  isEqualSelectMenu: {
    color: '#222222',
    marginTop: 3,
  },
  label: {
    fontSize: 14,
    color: 'grey',
    margin: 0,
    // marginBottom: 8,
    display: 'flex'
  },
  tooltip: {
    zIndex: 9999999999
  },
  modalCloseIcon: {
    width: 12,
    height: 12,
    padding: 1,
    borderRadius: 6
  },
  tag: {
    backgroundColor: AppTheme.secondaryColor,
    color: '#222222',
    fontSize: 13,
    borderRadius: 8,
    height: 26
  },
  popupIndicator: {
    marginRight: 5,
  },
  dialog: {
    zIndex: 130000,
    fontSize: 16,
    color: '#222222',
  },
  formControlMainFilters: {
    marginTop: 16,
    '@media (max-width: 767px)': {
      width: '48%',
    },
  },
  formControlFilters: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
    overflow: 'hidden'
  },
  textInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16
  },
  autoCompleteInputRoot: {
    paddingRight: 56
  },
  formInputStyle: {
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  textInput: {
    padding: '4px 0px'
  },
})

const opacityAnimationKeyframes = {
  from: {
    opacity: 0
  },
  to: {
    opacity: 1
  }
}

export const CustomTableComponentStyles = StyleSheet.create({
  content: {
    display: 'grid',
    justifyContent: 'unset',
    paddingBottom: 0
  },
  divTable: {
    display: 'block',
    overflowX: 'auto',
    width: '100%',
    // '@media (max-width: 320px)': {
    //   maxWidth: 310,
    // },
    // '@media (max-width: 767px) and (min-width: 321px)': {
    //   maxWidth: 350,
    // },
    // '@media (min-width: 768px) and (max-width: 1021px)': {
    //   maxWidth: 750,
    // },
  },
  table: {
    borderCollapse: 'separate',
    // borderSpacing: '0 10px',
  },
  empty: {
    marginTop: 100
  },
  entityAddIcon: {
    fontSize: 36,
    color: 'black',
    cursor: 'pointer',
  },
  cellHead: {
    backgroundColor: 'white',
    color: CommonTheme.subTextColor,
    // height: 38,
    fontSize: 16,
    // fontWeight: 'bold',
    padding: '16px 5px',
    lineHeight: '19px'
  },
  cellBody: {
    backgroundColor: 'white',
    color: CommonTheme.textColor,
    // minHeight: 72,
    borderBottom: '1px solid ' + AppTheme.borderColor,
    fontSize: 16,
    padding: '16px 5px',
    verticalAlign: 'middle',
    wordBreak: 'break-word',
  },
  cellBodySwap: {
    animationName: opacityAnimationKeyframes,
    animationDuration: '2s',
  },
  lastcellBody: {
    // borderTopRightRadius: 8,
    // borderBottomRightRadius: 8,
  },
  divIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  headerActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
  },
  checkboxActive: {
    backgroundColor: '#0246E514',
  }
})

export const CustomSEStyles = StyleSheet.create({
  container: {
    background: 'white',
    border: CommonTheme.containerBorderColor,
    height: '100%',
    borderRadius: 16,
  },
  colourPickerContainer: {
    width: 35,
    height: 35,
    borderRadius: 8,
    overflow: 'hidden'
  },
  content: {
    padding: '0px 30px',
    marginBottom: -3,
    '@media (max-width: 767px)': {
      padding: '0px 15px',
    }
  },
  contentForRow: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  heading: {
    // borderBottom: '1px solid lightgray',
  },
  headingContent: {
    padding: '20px 30px 14px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      padding: '20px 15px 14px',
    }
  },
  editButton: {
    color: AppTheme.primaryColor,
    backgroundColor: 'none',
    fontWeight: 600,
    padding: 0,
    fontSize: 16,
    textTransform: 'none',
    minWidth: 'auto',
    ':hover': {
      background: 'none',
      backgroundColor: 'none',
    }
  },
  stepFormTitle: {
    color: CommonTheme.textColor,
    fontSize: 20,
    fontWeight: 600,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    marginRight: 10
  },
  labelContainer: {
    paddingBottom: 20
  },
  labelContainerForRow: {
    paddingBottom: 20,
    width: '46%',
    '@media (max-width: 767px)': {
      width: '100%'
    }
  },
  dividerForRow: {
    width: '8%',
    '@media (max-width: 767px)': {
      width: '0%',
      display: 'none'
    }
  },
  label: {
    fontSize: 14,
    color: CommonTheme.subTextColor,
    marginBottom: 6,
    marginTop: 0
  },
  valueContainer: {
    display: 'grid',
    height: 37,
    alignItems: 'center'
  },
  value: {
    margin: 0,
    fontSize: 16,
    color: CommonTheme.textColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  valueDisplayMultiline: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 5,
    whiteSpace: 'pre-wrap',
  },
  actionIcon: {
    color: AppTheme.primaryColor
  },
  imageContainer: {
    width: 'fit-content',
    height: 35,
    border: CommonTheme.containerBorderColor,
    borderRadius: 8,
    overflow: 'hidden'
  },
  imageContent: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  link: {
    color: AppTheme.primaryColor,
    textDecoration: 'none',
    borderBottom: '0.5px solid ' + AppTheme.primaryColor,
    width: 'fit-content'
  },
  openFileText: {
    color: CommonTheme.subTextColor,
    borderBottom: '0.5px solid ' + CommonTheme.subTextColor,

  },
  buttonText: {
    color: AppTheme.primaryColor,
    fontWeight: 600
  }
})

export const CustomDynamicFormDialogStyles = StyleSheet.create({
  modalHeader: {
    // backgroundColor: 'whitesmoke',
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 14,
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    gap: 20,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalTitle: {
    color: CommonTheme.textColor,
    fontSize: 26,
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    // marginRight: 28,
    flex: 1,
    '@media (max-width: 767px)': {
      marginRight: 43,
    }
  },
  modalContent: {
    paddingLeft: 30,
    paddingRight: 30,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalCloseButton: {
    // position: 'absolute',
    // top: 23,
    // right: 30,
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    border: 'none'
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: 8
  },
  root: {
    zIndex: 13000,
  },
  paper: {
    borderRadius: AppTheme.borderRadius,
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  customRoot: {
    zIndex: 13000
  },
  customPaper: {
    borderRadius: AppTheme.borderRadius,
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  deleteMsg: {
    color: CommonTheme.textColor,
    fontSize: 24,
  },
  formHeaderTags: {
    fontSize: 14,
    padding: '2px 8px',
    border: '1.5px solid #ff0c0d',
    color: '#ff0c0d',
    borderRadius: 16,
    fontWeight: 500
  },
  tooltip: {
    zIndex: 9999999999,
    maxWidth: 250
  },
})

export const CustomBlockSelectionDialogStyles = StyleSheet.create({
  modalHeader: {
    // backgroundColor: 'whitesmoke',
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 14,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalHeaderCompact: {
    paddingBottom: 40
  },
  modalHeaderContent: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  modalTitle: {
    color: '#222222',
    fontSize: 26,
    fontWeight: 600,
    // flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: 89,
    flexShrink: 0
  },
  modalContent: {
    display: 'flex',
    // flex: 1,
    overflowY: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '::-webkit-scrollbar': {
      display: 'none'
    },

    boxSizing:'border-box'
  },
  modalCloseButton: {
    position: 'absolute',
    top: 33,
    right: 30,
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    '@media (max-width: 767px)': {
      top: 23,
      right: 15,
    },
  },
  modalCloseButtonCompact: {
    top: 23,
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius / 2
  },
  customRoot: {
    zIndex: 13000
  },
  customPaper: {
    // borderRadius: AppTheme.borderRadius,
    height: '100%',
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  customPaperCompact: {
    borderRadius: AppTheme.borderRadius,
    height: '90%',
    width: '60%',
    '@media (max-width: 767px)': {
      borderRadius: AppTheme.borderRadius,
      width: '90%'
    },
  },
  blockTitleContainer: {
    minHeight: 70,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0px 10px',
    gap: 10
  },
  imgContainerVariant: {
    width: 130,
    padding: '10px 0px',
    flexShrink: 0,
  },
  titleVariant: {
    padding: '10px 0px',
    wordBreak: 'break-word'
  },
  blockContainer: {
    paddingBottom: 25,
    width: '100%'
  },
  globalBlocksImgViewContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  imgOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  imgContainer: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    overflow: 'hidden',
    padding: 15,
  },
  imageContent: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    overflow: 'hidden',
    borderRadius: 16,
    border: '1.5px solid whitesmoke',
  },
  title: {
    color: '#222222',
    fontSize: 16,
    // fontWeight: 600,
    margin: 0,
    padding: '15px 15px 0px'
  },
  titleSpec: {
    textAlign: 'center',
    padding: 15,
    fontSize:24
  },
  tileContainer: {
    cursor: 'pointer',
    border: '1px solid #FFFFFF',//#DFE4EA',
    borderRadius: AppTheme.borderRadius,
    height: '100%',
    // padding: 15,
    display: 'flex',
    flexDirection: 'column',
    ':hover': {
      border: '1px solid #A9A9A9', //#c9cdd1
      boxShadow: '3px 6px 43px rgba(0, 0, 0, 0.05)',
      transition: '.3s'
    }
  },
  tileContainer2: {
    cursor: 'pointer',
    border: '1px solid #F5F5F5',//#DFE4EA',
    borderRadius: AppTheme.borderRadius,
    height: '100%',
    // padding: 15,
    ':hover': {
      border: '1px solid #A9A9A9', //#c9cdd1
      boxShadow: '3px 6px 43px rgba(0, 0, 0, 0.05)',
      transition: '.3s'
    }
  },
  tileSelectedContainer: {
    border: '2px solid' + AppTheme.primaryColor,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 30,
    padding: '0px 30px',
    justifyContent: 'flex-end',
    '@media (max-width: 767px)': {
      padding: '0px 15px',
    }
  },
  actionsNext: {
    // flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  saveButton: {
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    height: 54,
    minWidth: 150,
    padding: 13,
    fontSize: 16,
    textTransform: 'capitalize',
    color: AppTheme.secondaryBg
  },
  backButton: {
    marginRight: 50,
    cursor: 'pointer',
    color: AppTheme.primaryColor
  },
  marginLeft: {
    color: 'white'
  },

  menuProps: {
    zIndex: 130000,
    marginTop: 5
  },
  paper: {
    zIndex: 130000,
    fontSize: 16,
    color: '#222222',
    borderRadius: AppTheme.borderRadius,
    padding: '0px 10px'
  },
  formControlConatiner: {
    margin: 0,
    marginRight: 40,
    minWidth: 230,
    '@media (max-width: 767px)': {
      marginTop: 10,
      marginRight: 0,
    }
  },
  formControl: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid ' + AppTheme.borderColor,
  },
  formControlSearch:{
    width:350
  },
  clearAll:{
    userSelect:'none',
    fontWeight:600,
    fontSize:13,
    cursor:'pointer',
    padding:'5px 10px',
    border:'1px solid #ffffff',
    borderRadius:10,
    ':hover':{
      border:'1px solid #E5E5E5',
    }
  },
  textInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16,
    margin: 0,
    borderRadius: AppTheme.borderRadius,
    ':focus': {
      backgroundColor: '#ffffff'
    }
  },
  searchFieldContainer: {
    display:'flex',
    alignItems:'center',
    // margin: 0,
    //marginRight: 10,
    '@media (max-width: 767px)': {
      marginTop: 10,
      marginRight: 0,
    },
    gap:10
  },
  selectMenuItemContainer: {
    padding: '5px 10xp',
    borderRadius: AppTheme.borderRadius,
  },
  menuItem: {
    fontSize: 16,
    color: '#222222',
    margin: '6px 0px'
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '25px 0px',
    height: '100%',
    flex: 1
  },
  tagsContainerWidget:{
    display:'flex',
    width:'100%',
    flexWrap:'wrap',
    gap:5,
    cursor:'pointer',
    userSelect:'none',
    padding: '15px 15px 0px',
    alignItems:'center'
  },
  tagsContainerWidgetBlocks:{
    padding:0,
    marginBottom:10,
    marginTop:10
  },
  searchAndTagsouterContainer:{
    display:'flex',
    height:'calc(100vh - 89px)',
    '@media(max-width:768px)':{
      flexDirection:'column'
    }
  },
  searchAndTagsouterContainerCompact:{
    height:'calc(100vh - 150px)',
    '@media(max-width:768px)':{
      height:'calc(100vh - 89px)',
    }
  },
  searchAndTagsContainer:{
    width:350,
    flexShrink:0,
    paddingLeft:20,
    paddingRight:20,
    display:'flex',
    flexDirection:'column',
    '@media(max-width:768px)':{
      width:'100%',
      padding:'0px 15px 15px 15px'
    },
    boxSizing:'border-box',
    // borderRight:'1px solid #c3c1c1'
    borderRight:'1px solid rgba(0, 0, 0, 0.12)'
  },
  customPaperMd:{
    maxWidth:'unset',
  },
  customPaperFullWidth:{
    width:'100vw',
  },
  customPaperScrollPaper:{
    maxHeight:'100vh',
  },
  widgetsContainer:{
    display:'flex',
    width:'100%',
    flexDirection:'column',
    // alignItems:'center'
  },
  tagsContainer:{
    display:'flex',
    width:'100%',
    flexWrap:'wrap',
    gap:10,
    cursor:'pointer',
    userSelect:'none',
    marginTop:16,
  },
  tag:{
    background:'#f5f5f5',
    padding: '5px 15px',
    borderRadius: '10px',
    color: '#000000',
    textDecoration:'none'
  },
  widgetTag:{
    fontSize: '12px',
    background:'#f5f5f5',
    padding: '5px 10px',
    borderRadius: '10px',
    color: '#000000',
    textDecoration:'none'
  },
  tagSelected:{
    background:'#2146E5',
    color: '#ffffff',
  },
  tileTitle:{
    fontSize: 20,
    fontWeight: 600,
    flex: 1
  },
  modalContainer:{
    display: 'flex'
  },
  inputClearIcon:{
    cursor:'pointer',
    color:'#000000',
    fontSize: 21
  },
  tagsOuterContainer:{
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-end',
    paddingBottom:20,
    overflowY: 'scroll',
    height: 'calc(-145px + 100vh)',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    boxSizing:'border-box'
  },
  modalOuterContainer:{
    height:'100%',
    width:'100%'
  },
  clearTags:{
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    marginTop:15,
    marginRight:10,
    border:'1px solid #E5E5E5',
    padding:'5px 10px',
    borderRadius:10
  },
  clearFilterText:{
    fontSize: 14,
    fontWeight:500
  },
  clearFilterIcon:{
    color:'#000000',
    fontSize: 14,
    marginLeft:5
  },
  blockTags:{
    justifyContent:'center',
    paddingTop:0,
    paddingBottom:15
  },
  cbStyle:{
    fontSize:10,
    paddingLeft:15,
    margin:0,
    marginTop:5,
    color: '#7e7e7e'
  },
  cbStyleSelectedBlock:{
    margin:'0 auto 15px auto',
  }
})

export const CustomImageCollectionStyles = StyleSheet.create({
  modalHeader: {
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 20,
    display: 'flex',
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalHeaderContent: {
    display: 'flex',
    flex: 1,
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  modalTitle: {
    color: '#222222',
    fontSize: 26,
    fontWeight: 600,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  modalContent: {
    display: 'flex',
    overflowY: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    justifyContent: 'center',
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalCloseButton: {
    position: 'absolute',
    top: 33,
    right: 30,
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    '@media (max-width: 767px)': {
      top: 23,
      right: 15,
    },
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius / 2
  },
  customRoot: {
    zIndex: 13000
  },
  customPaper: {
    borderRadius: AppTheme.borderRadius,
    height: '100%',
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  title: {
    color: '#222222',
    fontSize: 22,
    fontWeight: 600,
    margin: 0,
    padding: '15px 15px 0px'
  },
  paper: {
    zIndex: 130000,
    fontSize: 16,
    color: '#222222',
    borderRadius: AppTheme.borderRadius,
    padding: '0px 10px'
  },
  formControl: {
    marginRight: 40,
    borderRadius: AppTheme.borderRadius,
    border: '1px solid ' + AppTheme.borderColor,
  },
  textInputStyles: {
    padding: '5px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16,
    margin: 0,
    borderRadius: AppTheme.borderRadius,
    ':focus': {
      backgroundColor: '#ffffff'
    }
  },
  imageOuterContainer: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'white',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    display: 'block',
    overflow: 'hidden',
    inset: '0px',
    borderRadius: 8,
    boxSizing: 'border-box',
    margin: '0px',
    border: '1px solid ' + AppTheme.borderColor
  },
  img: {
    inset: '0px',
    position: 'absolute',
    boxSizing: 'border-box',
    padding: '0px',
    border: 'none',
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
    objectFit: 'containe'
  },
  titlesContainer: {
    flex: 1,
    display: 'grid',
    marginTop: 10,
  },
  itemTitle: {
    margin: 0,
    color: '#222222',
    fontSize: 12,
    textAlign: 'left',
    marginBottom: 5,
    wordBreak: 'break-word',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    paddingTop: 0,
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 200
  },
})

export const CustomMessageDialogStyles = StyleSheet.create({
  modalHeader: {
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 14
  },
  modalTitle: {
    color: CommonTheme.textColor,
    fontSize: 26,
  },
  modalContent: {
    maxWidth: 400,
    paddingTop: 16,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
  },
  modalCloseButton: {
    position: 'absolute',
    top: 23,
    right: 30,
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: 8
  },
  root: {
    zIndex: 13000,
  },
  paper: {
    borderRadius: AppTheme.borderRadius
  },
})

export const CustomPreviewDialogStyles = StyleSheet.create({
  modalContainer: {
    padding: 20,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 14,
    '@media (max-width: 767px)': {
      paddingLeft: 20,
      paddingRight: 20,
    }
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  modalTitle: {
    flex: 1,
  },
  modalTitleContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50% , -50%)',
    color: CommonTheme.textColor,
    fontSize: 22,
    textTransform: 'capitalize',
    margin: 0
  },
  modalCloseButton: {
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    marginLeft: 20
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: 8
  },
  root: {
    zIndex: 13000,
  },
  modalButtonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    minWidth: 180,
    padding: 15,
    marginRight: 20,
    '@media (max-width: 767px)': {
      minWidth: 'unset',
    }
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: 500,
    color: AppTheme.buttonTextColor,
    textTransform: 'capitalize',
    marginLeft: 5,
    lineHeight: '19px'
  },
  iconContainer: {
    display: 'flex',
    marginLeft: 20,
    color: 'white'
  },

  modalDesktopContent: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    //height: '90vh',
    // overflow: 'hidden',
    border: 'none',
    marginLeft: 60,
    marginRight: 60,
    paddingBottom: 50,
    '@media (max-width: 767px)': {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  switch: {
    marginRight: 0,
  },
  tooltip: {
    zIndex: 13000,
    marginTop: -8
  },

  modalMobileOuterContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 50
  },
  iFrameStyle: {
    border: '2px solid #121212',
    width: '100%',
    height: '100%',
    backgroundColor: 'white'
  },
  iFrameStyleMobile: {
    border: '2px solid #121212',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 40,
    overflow: 'visible',
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  imageInnerContainer: {
    width: 108,
    height: 37
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
})

export const CustomMultiImageUploaderDialogStyles = StyleSheet.create({
  modalContent: {
    // paddingTop: 16,
    paddingLeft: 14,
    paddingRight: 14,
    paddingBottom: 14,
  },
  modalHeader: {
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 14
  },
  modalTitle: {
    color: CommonTheme.textColor,
    fontSize: 26,
  },
  modalCloseButton: {
    position: 'absolute',
    top: 23,
    right: 30,
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    border: 'none'
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: 8
  },
  root: {
    zIndex: 13000,
  },
  paper: {
    borderRadius: AppTheme.borderRadius,
    maxWidth: 545
  },
})

export const CustomMultiImageUploaderStyles = StyleSheet.create({
  outerContainer: {
    backgroundColor: 'white',
    height: '100%',
    borderRadius: AppTheme.pagePadding,
  },
  content: {
    padding: '16px 16px 16px 16px',
  },
  stepFormTitle: {
    fontSize: 18,
    lineHeight: '33px',
    fontWeight: 'bold',
    margin: 0,
    marginBottom: 4,
  },
  container: {
    // marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginRight: 10,
    border: '1px solid ' + AppTheme.borderColor,
    borderRadius: AppTheme.borderRadius,
    cursor: 'pointer',
    // backgroundColor: 'whitesmoke',
    height: IMG_RENDER_SIZE,
    width: IMG_RENDER_SIZE,
  },
  fileInput: {
    width: '100%'
  },
  attachFile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 64,
    height: 64,
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    cursor: 'pointer'
  },
  attachFileText: {
    margin: 0,
    cursor: 'pointer'
  },
  text: {
    marginBottom: 5,
    fontSize: 12,
    marginTop: 10,
    textAlign: 'left'
  },
  textUpload: {
    color: 'black',
  },
  textError: {
    color: '#f44336',
  },
  textSuccess: {
    color: 'green',
  },
  imageContainer: {
    marginTop: 10,
    marginRight: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid ' + AppTheme.borderColor,
    borderRadius: AppTheme.borderRadius,
    position: 'relative',
    height: IMG_RENDER_SIZE,
    width: IMG_RENDER_SIZE,
    overflow: 'hidden'
  },
  imageContent: {
    maxWidth: '90%',
    maxHeight: '90%',
  },
  closeIconContainer: {
    position: 'absolute',
    top: 8,
    right: 8,
    backgroundColor: 'white',
    display: 'flex',
    cursor: 'pointer',
    border: '1px solid' + CommonTheme.textColor,
    borderRadius: 8,
    padding: 2
  },
  imgContainer: {
    display: 'none'
  },
  progressIcon: {
    color: AppTheme.secondaryBg
  },
  iconStyle: {
    // fontSize: 20,
    color: AppTheme.secondaryBg
  },
  addImageContainer: {
    color: AppTheme.secondaryBg
  }
})


export const CustomDynamicFormStyles = StyleSheet.create({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // padding: AppTheme.pagePadding,
    '@media (max-width: 320px)': {
      maxWidth: 300,
    }
  },
  leftGridPadding: {
    paddingRight: 10,
    '@media (max-width: 767px)': {
      paddingRight: 0,
    }
  },
  rightGridPadding: {
    paddingLeft: 10,
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    }
  },
  errorMsg: {
    paddingRight: 20,
    color: '#f44336'
  },
  successMsg: {
    paddingRight: 20,
    color: 'green'
  },
  marginLeft: {
    color: 'white'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 20,
    marginBottom: 30
  },
  pendingApprovalLabel: {
    marginTop: 0,
    marginBottom: 0,
    color: 'orange',
    fontSize: 10
  },
  openDoc: {
    marginTop: 0,
    marginBottom: 0,
    color: 'black',
    fontSize: 10,
    cursor: 'pointer',
  },
  uploadFieldLabel: {
    marginTop: 10,
  },
  button: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    height: 54,
    minWidth: 150,
    // flex: 1,
    padding: 13,
    fontSize: 16,
    textTransform: 'capitalize',
    color: AppTheme.secondaryBg
  },
  buttonDisabled: {
    backgroundColor: AppTheme.secondaryColor,
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    height: 54,
    minWidth: 150,
    // flex: 1,
    padding: 13,
    fontSize: 16,
    textTransform: 'capitalize',
    color: '#808080',
    boxShadow: 'none',
    border: '1px solid #E5E5E5',
  },
  buttonText: {
    color: AppTheme.secondaryBg,
  },
  buttonDisabledText: {
    color: '#808080',
  },
  buttonDelete: {
    backgroundColor: '#FF0D0D'
  },
  label: {
    fontSize: 14,
    color: CommonTheme.subTextColor,
    margin: 0,
    // marginBottom: 8
    display: 'inline-block'
  },
  labelInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 2,
  },
  isEqualSelect: {
    minWidth: 110,
    paddingLeft: 10,
    paddingRight: 5,
    border: '1px solid #E5E5E5',
    textDecoration: 'none',
    borderRadius: 16,
    fontSize: 14,
    color: '#222222',
  },
  isEqualSelection: {
    color: '#222222',
    ':focus': {
      backgroundColor: 'inherit !important',
    }
  },
  isEqualSelectMenu: {
    color: '#222222',
    marginTop: 3,
  },
  formControl: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid ' + AppTheme.borderColor,
    overflow: 'hidden'
  },
  textInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: CommonTheme.textColor,
    fontSize: 16
  },
  textInputStylesDisabled: {
    backgroundColor: '#f5f5f5',
  },
  pickerStyle: {
    marginTop: 0
  },
  pickerInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: CommonTheme.textColor,
    fontSize: 16,
    borderRadius: AppTheme.borderRadius,
    border: '1px solid ' + AppTheme.borderColor,
    overflow: 'hidden'
  },
  formInputStyle: {
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  tag: {
    backgroundColor: AppTheme.secondaryColor,
    color: CommonTheme.textColor,
    fontSize: 13,
    borderRadius: 8,
    height: 26
  },
  popupIndicator: {
    marginRight: 5,
  },
  dialog: {
    zIndex: 130000,
    fontSize: 16,
    color: CommonTheme.textColor,
  },
  paper: {
    borderRadius: AppTheme.borderRadius,
  },

  modalCloseIcon: {
    width: 12,
    height: 12,
    padding: 1,
    //border: '1.5px solid' + CommonTheme.textColor,
    borderRadius: 6
  },

  fieldOuterContainer: {
    display: 'flex'
  },
  fieldLeftInnerContainer: {
    flex: 1,
    paddingRight: 10,
    '@media (max-width: 767px)': {
      paddingRight: 0,
    }
  },
  fieldRightInnerContainer: {
    flex: 1,
    paddingLeft: 10,
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    }
  },
  delFieldContainer: {
    width: '25%',
    marginRight: 20
  },
  formControlMain: {
    marginTop: 0,
    marginBottom: 0
  },
  optionImageContainer: {
    width: 20,
    height: 20,
  },
  optionImage: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  optionImageContainerSpec: {
    marginRight: 9,
    marginLeft: 5
  },
  // requiredIndicator: {
  //   display: 'flex',
  //   color: CommonTheme.subTextColor,
  //   lineHeight: 0,
  //   alignItems: 'center',
  //   fontSize: 23,
  //   justifyContent: 'flex-end'
  // },
  requiredIndicator: {
    opacity: 0.5,
    height: 7,
    width: 7
  },
  labelContainer: {
    display: 'flex',
    marginBottom: 8
  },
  tooltip: {
    zIndex: 9999999999
  },
  autoCompleteInputRoot: {
    paddingRight: 56
  },
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
  },
  optionLoaderSpinner:{
    marginRight: 15
  },
  addOptionStyle: {
    color: AppTheme.buttonBg
  },
  advancedTitle: {
    color: '#222222',
    fontSize: 20,
    fontWeight: 500,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    width: 50,
  },
  accordionIcon: {
    padding: 0,
  },
  accordionSummary: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 20,
    width: 125,
  },
  accordionDetails: {
    paddingLeft: 0,
    paddingRight: 0,
  }
})

export const CustomImageUploaderStyles = StyleSheet.create({
  container: {
    //backgroundColor: 'whitesmoke',
    // padding: 5,
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'flex-start',
    // justifyContent: 'flex-start'
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    // marginRight: 10,
    // border: '1px solid ' + AppTheme.borderColor,
    // borderRadius: AppTheme.borderRadius,
    // cursor: 'pointer',
    // backgroundColor: 'whitesmoke',
  },
  fitContainer: {
    height: FIELD_HEIGHT,
    width: 'auto'
  },
  fileInput: {
    width: '100%'
  },
  textButton: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 100,
    minHeight: 24,
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10
  },
  text: {
    marginTop: 10,
    marginBottom: 3,
    fontSize: 12
  },
  textUpload: {
    color: 'black',
  },
  textError: {
    color: '#f44336',
  },
  textSuccess: {
    color: 'green',
  },
  editor: {
    width: 65,
    height: 65,
  },
  imgContainer: {
    display: 'none'
  },
  imageContainer: {
    //marginTop: 10,
    //marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //border: '1px solid black',
    position: 'relative',
  },
  imageContent: {
    width: '100%',
    borderRadius: AppTheme.borderRadius,
    maxWidth: '100%',
    maxHeight: '100%'
  },
  closeIconContainer: {
    // position: 'absolute',
    // top: 8,
    // right: 8,
    backdropFilter: 'blur(6px)',
    backgroundColor: 'white',
    display: 'flex',
    cursor: 'pointer',
    border: '1px solid' + CommonTheme.textColor,
    borderRadius: 8,
    padding: 2,
    margin: '0px 5px 0px 10px'
  },
  closeIconDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
  attachFileText: {
    margin: 0,
    fontSize: 11,
    color: CommonTheme.textColor,
    // marginTop: 13
    marginLeft: 13
  },
  attachFile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10
  },
  labelContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    padding: 0,
    borderRadius: AppTheme.borderRadius,
    alignItems: 'center'
  },
  labelDisableContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: 0,
    backgroundColor: '#f5f5f5',
    cursor: 'unset',
    borderRadius: AppTheme.borderRadius,
    alignItems: 'center'
  },
  attachFileTextBrowse: {
    color: AppTheme.primaryColor,
    fontWeight: 500
  },
  outerMainContainer: {
    display: 'flex'
  },
  outerContainerDisabled: {
    backgroundColor: '#f5f5f5'
  },
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    border: '1px dashed #E5E5E5',
    borderRadius: AppTheme.borderRadius,
    height: FIELD_HEIGHT,
    position: 'relative',
  },
  imageOuterContainer: {
    flex: 'unset',
    border: '1px solid #E5E5E5'
  },
  imageIconContainer: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageIconOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  reuploadIconContainer: {
    right: 34
  },
  fileDrop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    // minHeight: 110,
    position: 'relative'
  },

  targetFileDrop: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  reuploadContainer: {
    fontSize: 16,
    color: AppTheme.primaryColor,
    margin: 20,
    display: 'flex',
    alignItems: 'center'
  },
  reuploadLabel: {
    cursor: 'pointer'
  },
  assetPickerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    backgroundColor: 'whitesmoke',
    flex: 1,
    padding: 0,
    cursor: 'pointer',
    borderRadius: 16,
    maxWidth: 125
  },
  assetPickerContainerDisabled: {
    cursor: 'default'
  },
  assetPickerTitle: {
    textAlign: 'center',
    fontSize: 12
  },
  deleteIconContainer: {
    margin: '0px 10px 0px 5px'
  }
})

export const CustomRichContentEditorStyles = StyleSheet.create({
  outerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: CommonTheme.containerBorderColor,
    borderRadius: AppTheme.borderRadius,
    marginTop: 20,
    marginBottom: 30
  },
  container: {
    position: 'relative',
    display: 'flex',
    width: '70%',
    marginBottom: 100,
    minHeight: 500,
    justifyContent: 'center',
    backgroundColor: 'white',
    '@media (max-width: 767px)': {
      width: '90%',
      marginBottom: 0,
    }
  },
  modalInnerContainer: {
    marginTop: 120,
    marginBottom: 20,
    padding: 10,
    width: '60%'
  },
  modalHeader: {
  },
  modalCloseIcon: {
    width: 28,
    height: 28
  },
  modalCloseButton: {
    position: 'absolute',
    top: 50,
    right: 50,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalOuterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imgContainer: {
    width: '70%',
    backgroundColor: 'white',
    // borderBottom: '1px solid #F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    paddingTop: 40,
    '@media (max-width: 767px)': {
      width: '90%',
    }
  },
  img: {
    width: '100%',
    borderRadius: AppTheme.borderRadius
  },
  blogTitle: {
    fontSize: 46,
    fontWeight: 500,
    // width: '70%',
    textAlign: 'left',
    margin: 0,
    paddingTop: 40,
    paddingBottom: 20
  },
  button: {
    minWidth: 150,
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    // minWidth: 150,
    minHeight: 54,
    fontSize: 16,
    color: AppTheme.secondaryBg
  },
  buttonLabel: {
    textTransform: 'capitalize'
  },
  progressIcon: {
    marginLeft: 10,
    color: 'white'
  },
  blogSummary: {
    color: '#3E4462',
    opacity: 0.7,
    margin: 0,
    fontSize: 20,
    fontWeight: 'normal',
    whiteSpace: 'pre-wrap'
  },
  blogStats: {
    color: '#3E4462',
    opacity: 0.7,
    margin: 0,
    fontSize: 14,
    fontWeight: 'normal',
    whiteSpace: 'pre-wrap'
  },
  aiControls: {
    width: '70%',
    color: '#3E4462',
    opacity: 0.7,
    margin: 0,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14,
  },
  aiEditorLoading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    opacity: '0.05',
    zIndex: '9999',
  },
})

export const CustomDocumentUploaderStyles = StyleSheet.create({
  container: {
    // minHeight: 52,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
  },
  buttonContainer: {
    height: FIELD_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    border: '1px solid ' + AppTheme.borderColor,
    borderRadius: AppTheme.borderRadius,
    position: 'relative'
  },
  buttonContainerDisabled: {
    backgroundColor: '#f5f5f5'
  },
  fileInput: {
    width: '100%'
  },
  button: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    // minWidth: 150,
    padding: '5px 15px',
    fontSize: 10,
    color: AppTheme.secondaryBg
  },
  text: {
    fontSize: 10,
    margin: 0,
    marginRight: 10,
  },
  textUpload: {
    color: 'black',
  },
  textError: {
    color: '#f44336',
  },
  textSuccess: {
    color: 'green',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    cursor: 'pointer',
    borderRadius: AppTheme.borderRadius
  },
  labelDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'unset'
  },
  attachFile: {
    marginRight: 10,
    color: AppTheme.primaryColor,
    minWidth: 160
  },
  closeIconContainer: {
    color: AppTheme.primaryColor,
    marginRight: 10
  },
  openDoc: {
    marginTop: 0,
    marginBottom: 0,
    color: CommonTheme.subTextColor,
    fontSize: 10,
    textDecoration: 'none',
    borderBottom: '0.5px solid ' + CommonTheme.subTextColor
  },
  msgContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5
  },
  imageIconOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  imageIconContainer: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    display: 'flex',
    marginRight: 10
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    padding: '4px 15px'
  },
  targetContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  documentContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center'
  },
  documentImageContainer: {
    width: ICON_SIZE,
    height: ICON_SIZE
  },
  closeImgContainer: {
    display: 'flex',
    cursor: 'pointer',
    // position: 'absolute',
    // top: 12,
    // right: 12,
    // zIndex: 1000,
    color: CommonTheme.textColor,
    border: '1px solid' + CommonTheme.textColor,
    borderRadius: 8,
    padding: 2
  },
  closeImgDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
  documentNameContainer: {
    lineHeight: 'unset',
    margin: 0,
    display: 'grid'
  },
  docName: {
    fontSize: 16,
    color: '#222222',
    margin: 0,
    alignItems: 'center',
    marginLeft: 10,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  attachFileText: {
    fontSize: 11,
    color: CommonTheme.textColor,
    //fontWeight: 500,
    margin: 0
  },
  attachFileTextBrowse: {
    color: AppTheme.primaryColor,
    fontWeight: 500
  },
  reuploadIconContainer: {
    right: 36
  },
  delImgContainer: {
    margin: '0px 10px'
  }
})

export const CustomNoPermissionStyles = StyleSheet.create({
  nfMiddle: {
    maxWidth: 500,
    fontSize: 16,
    padding: 10,
    paddingTop: AppTheme.headerbarHeight,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    alignSelf: 'center'
  },
  text: {
    color: CommonTheme.subTextColor,
    fontSize: 16,
    margin: 0,
    marginBottom: 10
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divider: {
    width: 10
  },
  logoContainer: {
    width: '100%',
    marginTop: 30
  },
  img: {
    width: 250,
    height: 'auto'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    maxWidth: 1310,
    padding: '0px 25px'
  }
})

export const CustomBlogsStyles = StyleSheet.create({
  spanValue: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
  spanValueVariant: {
    fontSize: 16,
    color: AppTheme.primaryColor
  },
})

export const CustomProductsStyles = StyleSheet.create({
  spanValue: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
  valueText: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
})

export const CustomBlogListsStyles = StyleSheet.create({
  spanValue: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
  valueText: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
})

export const CustomBlogTagsStyles = StyleSheet.create({
  spanValue: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
  valueText: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
})

export const CustomOrdersStyles = StyleSheet.create({
  spanValue: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
  valueText: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
})

export const CustomCbDefsStyles = StyleSheet.create({
  spanValue: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  },
  anchorTag: {
    textDecoration: 'none'
  },
  entityTableLinkIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export const CustomCbDefFieldsStyles = StyleSheet.create({
  spanValue: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  },
  titleContainer:{
    display:'flex',
    gap:10,
    alignItems:'center',
  },
  imageContainer:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    aspectRatio:'1200/630',
    width:100,
    flexShrink:0,
    borderRadius:8,
    border: '1.5px solid #e5e5e5',
    overflow: 'hidden'
  },
  sectionImage:{
    height:'100%',
    width:'100%',
    // borderRadius:8,
    objectFit:'contain'
  },
  groupingLabel:{
    fontSize: 12,
    color:'grey'
  }
})

export const CustomCbDatsStyles = StyleSheet.create({
  spanValue: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  }
})

export const CustomCbDefsReadStyles = StyleSheet.create({
  spanValue: {
    fontSize: 16,
    color: CommonTheme.textColor
  },
  line2: {
    fontSize: 12,
    color: 'grey',
    marginTop:2
  },
  linkIcon:{
    textDecoration:'none',
    fontSize: 12,
    cursor:'pointer',
    userSelect:'none',
    color:'#0246E5'
  },
  sectionContainer:{
    display:'flex',
    alignItems:'center',
    gap:10
  },
  imageContainer:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    aspectRatio:'1200/630',
    width:125,
    flexShrink:0,
    borderRadius:8,
    border: '1.5px solid #e5e5e5',
    overflow: 'hidden'
  },
  sectionImage:{
    height:'100%',
    width:'100%',
    // borderRadius:8,
    objectFit:'contain'
  },
  sectionInfoContainer:{
    display:'flex',
    flexDirection:'column'
  }
})

export const CustomNextPageStyles = StyleSheet.create({
  nextPageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
  },
  progress: {
    color: 'white'
  },
  button: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    height: 54,
    minWidth: 150,
    // flex: 1,
    padding: 13,
    fontSize: 16,
    textTransform: 'capitalize',
    color: AppTheme.secondaryBg
  },
})

export const CustomOrgSwitcherStyles = StyleSheet.create({
  customRoot: {
    zIndex: 13000
  },
  customPaper: {
    borderRadius: AppTheme.borderRadius,
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  modalHeader: {
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 14,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalTitle: {
    color: CommonTheme.textColor,
    fontSize: 26,
    fontWeight: 600
  },
  modalContent: {
    paddingLeft: 30,
    paddingRight: 30,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalCloseButton: {
    position: 'absolute',
    top: 23,
    right: 30,
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    border: 'none'
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: 8
  },
  label: {
    fontSize: 14,
    color: CommonTheme.subTextColor,
    margin: 0,
    marginBottom: 8
  },
  menuProps: {
    zIndex: 130000,
    marginTop: 5
  },
  paper: {
    zIndex: 130000,
    fontSize: 16,
    color: CommonTheme.textColor,
    borderRadius: AppTheme.borderRadius,
    padding: '0px 10px'
  },
  formControl: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid ' + AppTheme.borderColor,
  },
  textInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: CommonTheme.textColor,
    fontSize: 16,
    margin: 0,
    borderRadius: AppTheme.borderRadius,
    ':focus': {
      backgroundColor: '#ffffff'
    }
  },
  selectMenuItemContainer: {
    padding: '5px 10xp',
    borderRadius: AppTheme.borderRadius,
  },
  menuItem: {
    fontSize: 16,
    color: CommonTheme.textColor,
    margin: '6px 0px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 20,
    marginBottom: 30
  },
  saveButton: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    height: 54,
    minWidth: 150,
    padding: 13,
    fontSize: 16,
    textTransform: 'capitalize',
    color: AppTheme.secondaryBg
  },
  marginLeft: {
    color: 'white'
  },
})

export const CustomSettingsMenuStyles = StyleSheet.create({
  popoverContainer: {
    borderRadius: AppTheme.borderRadius,
    boxShadow: '3px 6px 43px rgba(0, 0, 0, 0.05)',
    border: '1px solid ' + AppTheme.borderColor,
    marginTop: 10,
    minWidth: 199
  },
  popoverContent: {
    padding: 5,
    paddingBottom: 10,
    display: 'grid'
  },
  popoverTitle: {
    color: CommonTheme.textColor,
    fontSize: 20,
    margin: 0,
    fontWeight: 500,
    padding: 10
  },
  menuItemContainer: {
    borderRadius: AppTheme.borderRadius,
    padding: '6px 10px'
  },
  menuItemContent: {
    margin: 0,
    color: CommonTheme.textColor,
    fontSize: 16,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  version: {
    color: '#7d7d7d',
    marginLeft: 12,
    fontSize: 12
  },
})


export const CustomAppButtonStyles = StyleSheet.create({
  button: {
    backgroundColor: AppTheme.buttonBg,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    minWidth: 150,
    height: 54,
    padding: 15,
    '@media (max-width: 767px)': {
      minWidth: 'unset',
    }
  },
  buttonText: {
    fontSize: 16,
    margin: 0,
    textTransform: 'capitalize',
    color: AppTheme.buttonTextColor,
    fontWeight: 500,
    lineHeight: '19px'
  },
  progressIcon: {
    color: AppTheme.secondaryBg,
  },
  arrowForwardIcon: {
    fontSize: 15,
    color: AppTheme.primaryColor,
    marginLeft: 6
  },
  iconContainer: {
    border: '1px solid' + AppTheme.buttonTextColor,
    borderRadius: 8,
    display: 'flex',
    padding: 2,
    marginRight: 10,
    color: 'white',
  },
  noBorderIconContainer:{
    border:0
  },
  icon: {
    fontSize: 18
  },
})

export const CustomColourPickerStyles = StyleSheet.create({
  pickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'flex-start'
  },
  input: {
    marginRight: 10,
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
    padding: '11px 20px',
    textAlign: 'left',
    color: '#222222',
    fontSize: 16,
    height: 27,
    outline: 'none',
    fontFamily: AppConfig.GFONTNAME
  },
  popOver: {
    borderRadius: 9,
    display: 'flex',
    backgroundColor: '#FFFFFF',
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  colourTile: {
    width: 51,
    height: 51,
    borderRadius: 16,
    border: '1px solid #e5e5e5',
    margin: 5,
    cursor: 'pointer',
    overflow: 'hidden'
  },
  colourTileQuickPick: {
    width: 40,
    height: 40,
    borderRadius: 10,
  },
  swatch2: {
    margin: 0,
    marginRight: 5
  },
  displayColourTile: {
    cursor: 'default'
  },
  customRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
    zIndex: 13000
  },
  customPaper: {
    borderRadius: 40,
    maxWidth: 800,
    backgroundColor: AppTheme.secondaryBg,
    '::-webkit-scrollbar': {
      display: 'none'
    },
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
  },
  picker: {
    width: 300,
    height: 300,
    '@media (max-width: 767px)': {
      width: 300,
      height: 300,
    }
  },
  selectedColour: {
    display: 'flex',
    alignItems: 'center'
  },
  coloursContainer: {
    marginLeft: 30,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '@media (max-width: 767px)': {
      marginTop: 30,
      marginLeft: 0
    },
  },
  modalCloseButton: {
    position: 'absolute',
    top: 33,
    right: 30,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalCloseIcon: {
    width: 18,
    height: 18
  },
  modalHeader: {
    padding: 25,
    paddingBottom: 10
  },
  modalTitle: {
    color: 'black',
    fontSize: 26,
    fontWeight: 600
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#e5e5e5',
    marginBottom: 20,
    marginTop: 10
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 20,
    marginBottom: 30
  },
  brandColourContainer: {
    display: 'flex',
    flexWrap:'wrap'
  },
  closeIconContainer: {
    backdropFilter: 'blur(6px)',
    backgroundColor: 'white',
    display: 'flex',
    cursor: 'pointer',
    border: '1px solid black',
    padding: 2,
    borderRadius: 4,
    margin: '2px 0px',
  },
  modalContent: {
    paddingLeft: 25,
    paddingRight: 25
  },
  title: {
    //marginTop: 10
  },
  spinner:{
    margin:'0  auto'
  }
})

export const CustomAssetsStyles = StyleSheet.create({
  spanValue: {
    fontSize: 12
  },
  imageOuterContainer: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'white',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    display: 'block',
    overflow: 'hidden',
    inset: '0px',
    borderRadius: 8,
    boxSizing: 'border-box',
    margin: '0px',
    border: '1px solid ' + AppTheme.borderColor
  },
  img: {
    inset: '0px',
    position: 'absolute',
    boxSizing: 'border-box',
    padding: '0px',
    border: 'none',
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
    objectFit: 'containe'
  },
  titlesContainer: {
    flex: 1,
    display: 'grid'
  },
  itemTitle: {
    margin: 0,
    color: '#222222',
    fontSize: 12,
    textAlign: 'left',
    marginBottom: 5,
    wordBreak: 'break-word',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    paddingTop: 0,
  }
})

export const CustomCardsStyles = StyleSheet.create({
  productTileContainer: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  itemDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },
  titleAndIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  popover: {
    minWidth: 50,
    gap: 8
  },
  container: {
    paddingBottom: 50,
    overflow: 'hidden',
    flex: 1
  },
})
