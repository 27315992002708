import { Helper } from '../common/helpers/Helper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { UiHelper } from '../common/helpers/UiHelper';
import { AppHelper } from './AppHelper';

const EMAIL_TEMPLATE_VARIABLES = [
  {key: 'friendlyname', value: '{{contact.friendlyname}}', text: 'Friendly Name'},
  {key: 'friendlycompanyname', value: '{{contact.friendlycompanyname}}', text: 'Friendly Company Name'},
  {key: 'name', value: '{{contact.name}}', text: 'Name'},
  {key: 'fistName', value: '{{contact.firstname}}', text: 'First Name'},
  {key: 'lastName', value: '{{contact.lastname}}', text: 'Last Name'},
  {key: 'email', value: '{{contact.email}}', text: 'Email'},
  {key: 'phone', value: '{{contact.phone}}', text: 'Phone'},
  {key: 'company', value: '{{contact.company}}', text: 'Company'},
  {key: 'senderName', value: '{{sender.name}}', text: 'Sender Name'},
  {key: 'senderEmail', value: '{{sender.email}}', text: 'Sender Email'},
]

export class AppUiHelper {

  static async limitEmailImages(fieldDef) {
    fieldDef.steps[0].fields.forEach(i => {
      if(i.imageUpload) {
        i.uploadAccept = 'image/png, image/jpeg, image/gif' // allow only these for emails
      }
    })
  }

  static async handleSingletonWidgetData(caller, current) {
    console.log('onSingletonWidgetData:current:', current)
    let response
    let cbDefData
    const requestPromises = [];
    try {
      // TODO: Ideally show a progress here; which should display the error if any
      // Autoclose the progress on success
      requestPromises.push(await ApiHelper.callAwait({
        method: 'GET',
        paths: ['cbdats'],
        queryParams: { cbId: current._cbScopeId, pb: 'fieldDef' }
      }))
      let cbId = current._cbScopeId.split('-')
      if (cbId.length === 4) {
        cbId = cbId[2] + '-' + cbId[3]
        requestPromises.push(await ApiHelper.callAwait({
          method: 'GET',
          paths: ['cbdefs',cbId],
          queryParams: { cbId: current._cbScopeId, pb: 'fieldDef' }
        }))
      }
      const resultData = await Promise.all(requestPromises)
      response = resultData[0]
      cbDefData = resultData[1]
    } catch (e) {
      console.error('onSingletonWidgetData:error:', e)
      return
    }
    console.log('onSingletonWidgetData:response:', response)
    console.log('onSingletonWidgetData:cbDefData:', cbDefData)
    UiHelper.populateOptions(response.fieldDef, caller.coTypes, 'customObject')
    UiHelper.populateOptions(response.fieldDef, caller.forms, 'form')
    response.fieldDef = AppHelper.preFillWidgetDefaultValues(response.fieldDef,cbDefData)
    // console.log('onSingletonWidgetData:fieldDef:', response.fieldDef)
    response.fieldDef.steps[0].fields =
      response.fieldDef.steps[0].fields.filter(i => !Helper.inArray(['order','state'], i.key))

    if(caller.page && caller.page.coTypeId && caller.entityAttrName === 'cotypeblocks') {
      response.fieldDef.steps[0].fields.forEach(field => {
        if(field.subType === 'image' || field.imageUpload) {
          //make it a text entry for allowing replacement tags
          delete field.subType
          delete field.imageUpload
        } else if(field.subType === 'RICHTEXT_PARAGRAPH') {
          //make it a text entry for allowing replacement tags
          delete field.subType
          delete field.multiline
          field.type = 'edit_text'
        }
      })
    }
    console.log('onSingletonWidgetData:fieldDef:', response.fieldDef)
    caller.formDefinition = response.fieldDef
    if(response.items.length <= 0) {
      caller.formDefinition.formType = 'add'
      Helper.deleteFormDefinitionValues(caller.formDefinition)
      caller.setState({
        apiMethod: 'POST',
        apiData: {
          cbId: current._cbScopeId,
          state: 'LIVE' // only one object and it should be live
        },
        apiEndPoint: ApiHelper.makeUrlPath(['cbdats'], {})
      })
    } else {
      caller.formDefinition.formType = 'edit'
      // if(current._widgetName)
      //   caller.formDefinition.title = 'Edit - ' + current._widgetName
      if(current.cbSource === 'SITE' ||
        (current._singleton === 'YES' && caller.pageTitle === Helper.getString('cborgs'))) {
        caller.formDefinition.steps[0].formHeaderTags = [
          {
            title: Helper.getString('sharedWidget'),
            toolTip: Helper.getString('sharedWidgetInfo'),
          }
        ]
      }
      Helper.populateFormDefinitionValues(caller.formDefinition, response.items[0])
      caller.setState({
        apiMethod: 'PUT',
        state: 'LIVE', // only one object and it should be live
        apiEndPoint: ApiHelper.makeUrlPath(['cbdats', response.items[0].id], {})
      });
    }
    caller.setState({
      modalOpen: true
    })
  }

  static async handleEmailSingletonWidgetData(caller, current, enhanceFieldsFn, patchFieldsFn, isDisabled = false) {
    console.log('handleEmailSingletonWidgetData:current:', current)
    let response
    try {
      // TODO: Ideally show a progress here; which should display the error if any
      // Autoclose the progress on success
      response = await ApiHelper.callAwait({
        method: 'GET',
        paths: ['emailcbdats'],
        queryParams: { cbId: current._cbScopeId, pb: 'fieldDef' }
      })
    } catch (e) {
      console.error('onSingletonWidgetData:error:', e)
      return
    }
    console.log('onSingletonWidgetData:response:', response)
    // console.log('onSingletonWidgetData:fieldDef:', response.fieldDef)
    response.fieldDef.steps[0].fields =
      response.fieldDef.steps[0].fields.filter(i => !Helper.inArray(['order','state', 'navLinkMode'], i.key))
    AppUiHelper.limitEmailImages(response.fieldDef)

    console.log('onSingletonWidgetData:fieldDef:', response.fieldDef)
    caller.formDefinition = response.fieldDef
    const ctaLabelField = caller.formDefinition.steps[0].fields.find(o => o.key === 'cTALabel')
    if(ctaLabelField) {
      ctaLabelField.label = 'Button Label'
    }
    const richTextField = caller.formDefinition.steps[0].fields.find(o => (o.key === 'text' && o.type === 'rich_text'))
    if(richTextField) {
      richTextField.variablePicker =  {
        title: 'Custom Fields',
        options: EMAIL_TEMPLATE_VARIABLES
      }
    }
    if (enhanceFieldsFn) enhanceFieldsFn(caller.formDefinition)
    caller.formDefinition.title = `Data for ${(current && (current.title || current._widgetName)) || 'Widget'}`
    if(isDisabled) {
      caller.formDefinition.formType = 'edit'
      if (!enhanceFieldsFn && response.items.length > 0) Helper.populateFormDefinitionValues(caller.formDefinition, response.items[0])
      if (patchFieldsFn) patchFieldsFn(caller.formDefinition)
    } else {
      if(response.items.length <= 0) {
        caller.formDefinition.formType = 'add'
        if (!enhanceFieldsFn) Helper.deleteFormDefinitionValues(caller.formDefinition)
        caller.setState({
          apiMethod: 'POST',
          apiData: {
            cbId: current._cbScopeId,
            state: 'LIVE' // only one object and it should be live
          },
          apiEndPoint: ApiHelper.makeUrlPath(['emailcbdats'], {})
        })
      } else {
        caller.formDefinition.formType = 'edit'
        if (!enhanceFieldsFn) Helper.populateFormDefinitionValues(caller.formDefinition, response.items[0])
        if (patchFieldsFn) patchFieldsFn(caller.formDefinition)
        caller.setState({
          apiMethod: 'PUT',
          state: 'LIVE', // only one object and it should be live
          apiEndPoint: ApiHelper.makeUrlPath(['emailcbdats', response.items[0].id], {})
        });
      }
    }
    caller.setState({
      modalOpen: true
    })
  }

  static getFieldDefForDuplication() {
    return {
      formType: 'edit',
      steps: [
        {
          fields: [
            {
              hint: 'Action',
              key: 'action',
              label: 'Action',
              type: 'radio',
              options: [
                { key: 'DUPLICATE', label: 'DUPLICATE' },
              ],
              defaultValue: 'DUPLICATE',
              placement: 'left',
              // eslint-disable-next-line camelcase
              v_required: { value: true, err: 'Action required' }
            }
          ]
        }
      ]
    }
  }
}
