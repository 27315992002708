import React, { useState, useEffect, useContext, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Controller } from '../Controller';
import { AppConfig } from '../../AppConfig';
import { AppTheme } from '../../styles/AppTheme';
import clsx from 'clsx';
import { Helper } from '../helpers/Helper';
import Collapse from '@material-ui/core/Collapse';
import { AppContext } from '../contexts/AppContext';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { AppStyleHelper } from '../helpers/AppStyleHelper';
import { UiHelper } from '../helpers/UiHelper';

const ITEM_FONT_SIZE = AppConfig.DRAWER_STYLE === 'TYPE1' ? 30 : 16
const MENU_TITLE_SIZE = AppConfig.DRAWER_STYLE === 'TYPE1' ? 12 : 14

const TEXT_COLOUR = '#7D7D7D'
const useStyles = makeStyles((theme) => {
  const st = {
    drawer: {
      width: AppTheme.drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      backgroundColor: '#FFFFFF',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        width: AppTheme.drawerWidth,
        flexShrink: 0,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%'
      },
      position: 'relative'
    },
    drawerOpen: {
      width: AppTheme.drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflow: 'visible',
      zIndex: 12000,
      [theme.breakpoints.between('100', '1025')]: {
        width: '100%',
        overflowY: 'scroll',
      }
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(10) + 20,
      },
      [theme.breakpoints.between('100', '1025')]: {
        width: 0
      }
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  }
  return AppStyleHelper.modifyStyleSheet(st, { theme, comp: 'drawerMenuDashboard', type: 'drawerStyle' })
});

export function DrawerMenuDashboard(props) {
  const [state, setState] = useState({
    drawerIsOpen: window.innerWidth <= 1024 ? false : true,
  })

  const { appData, appContextStateHandler } = useContext(AppContext)


  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    props.menuItems.map(items => {
      items.map(listItems => {
        listItems.subItems && listItems.subItems.map(subListItems => {
          subListItems.key === appData.pageKey &&
            setState({
              ...state,
              openId: listItems.key
            })
        })
      })
    })
  }, [appData.pageKey])

  const onMenuClick = (item, closeCollapse) => {
    if (closeCollapse)
      setState({
        ...state,
        openId: null
      })
    if (window.innerWidth <= 1024) {
      toggleDrawer()
    }
    if (item.type === 'LOGOUT') {
      Controller.get().logout()
    }
  }

  const toggleDrawer = () => {
    props.drawerIsOpen(!state.drawerIsOpen)
    setState({
      ...state,
      drawerIsOpen: !state.drawerIsOpen
    })
  }

  const styleConditionCheck = (key) => {
    if (appData.pageKey === key) {
      return true
    } else {
      return false
    }
  }

  const accordingListClick = (e) => {
    const openId = state.openId
    if (openId === e) {
      setState({
        ...state,
        openId: null
      });
    } else {
      setState({
        ...state,
        openId: e
      });
    }
  };

  const listItem = (item)=>{
    return <ListItem button className={getListStyle(item)}>
      {AppConfig.DRAWER_STYLE === 'TYPE1' && (
        <ListItemIcon className={css(Styles.linkcolor)}>
          <i
            className='material-icons-outlined'
            style={getListIconStyle(item)}
          >
            {item.icon}
          </i>
        </ListItemIcon>
      )}
      <ListItemText
        disableTypography
        className={css(Styles.listItemText)}
        primary={
          <Typography type='body2' className={getListTitleStyle(item)}>
            {item.title}
          </Typography>
        }
      />
    </ListItem>
  }

  const menuRowItem = (item) => {

    return (
      <Fragment>
        {!(AppConfig.DRAWER_STYLE === 'TYPE1') && (
          <div
            className={
              styleConditionCheck(item.key)
                ? css([Styles.listStyle, Styles.listSelectedStyle])
                : css(Styles.listStyle)
            }
          />
        )}
        {item.route ? <Link to={{
          pathname: item.route,
          state: {isMenuItemClicked : true}
        }} className={css(Styles.itemLink)}>{listItem(item)}</Link> : listItem(item)}
      </Fragment>
    );
  }

  const accordingListItems = (data, index) => {
    return (
      <div key={index}>
        <div onClick={() => accordingListClick(data.key)} className={css(Styles.listInnerContainer)}>
          {menuRowItem(data)}
        </div>
        <Collapse
          className={css(Styles.accordingListContainer)}
          key={data.key}
          component='li'
          in={state.openId === data.key}
          timeout='auto'
          unmountOnExit>
          <List disablePadding>
            {data.subItems.map((nsItem, index) => {
              return (
                <div onClick={() => onMenuClick(nsItem)} key={index} className={css(Styles.listInnerContainer)}>
                  {menuRowItem(nsItem)}
                </div>
              )
            })}
          </List>
        </Collapse>
      </div>
    )
  }

  const getListStyle = (data) => {
    if (styleConditionCheck(data.key)) {
      if (AppConfig.DRAWER_STYLE === 'TYPE1') {
        return css([Styles.listButton2, Styles.listSelectedButton2])
      } else {
        return css([Styles.listButton, Styles.listSelectedButton])
      }
    } else {
      if (AppConfig.DRAWER_STYLE === 'TYPE1') {
        return css(Styles.listButton2)
      } else {
        return css(Styles.listButton)
      }
    }
  }

  const getListTitleStyle = (data) => {
    if (styleConditionCheck(data.key)) {
      if (AppConfig.DRAWER_STYLE === 'TYPE1') {
        return css([Styles.listColor, Styles.listSelectedColorType1])
      } else {
        return css([Styles.listColor, Styles.listSelectedColor])
      }
    } else {
      return css(Styles.listColor)
    }
  }

  const getListIconStyle = (data) => {
    const iconStyle = { fontSize: ITEM_FONT_SIZE }
    if (styleConditionCheck(data.key)) {
      if (AppConfig.DRAWER_STYLE === 'TYPE1') {
        iconStyle.color = AppTheme.primaryColor
        iconStyle.marginBottom = 10
        return iconStyle
      } else {
        return iconStyle
      }
    } else {
      if (AppConfig.DRAWER_STYLE === 'TYPE1') {
        iconStyle.marginBottom = 10
        return iconStyle
      } else {
        return iconStyle
      }
    }
  }

  const listInnerContainerStyle = () => {
    if (AppConfig.DRAWER_STYLE === 'TYPE1') {
      return css([Styles.listInnerContainer, Styles.listInnerContainerType1])
    } else {
      return css([Styles.listInnerContainer])
    }
  }

  const listItems = (data, index) => {
    return (
      <div onClick={() => onMenuClick(data, true)} key={index} className={listInnerContainerStyle()}>
        {menuRowItem(data)}
        {AppConfig.DRAWER_STYLE === 'TYPE1' && styleConditionCheck(data.key) &&
          <div className={css(Styles.menuHighlight)}></div>
        }
      </div>
    )
  }

  const getOrgTitle = () => {
    const currentOrg = Controller.get().roleHelper().getOrg()
    return currentOrg.title || currentOrg.orgId
  }

  const renderList = () => {
    const items = props.menuItems ? props.menuItems : []
    const appUser = Controller.get().userMgr().getAppUser()
    return (
      state.drawerIsOpen &&
      <List className={css(Styles.list)}>
        {items.map((item, index) => {
          return (
            <div key={index}>
              {item.map((data, index) => {
                if (data.subItems && data.subItems.length > 0) {
                  return accordingListItems(data, index)
                } else {
                  return listItems(data, index)
                }
              })}
              <Divider className={AppConfig.DRAWER_STYLE === 'TYPE1' ? css(Styles.dividerType1) : css(Styles.divider)} />
            </div>
          )
        })}

        {props.onLogoutClick &&
          <Fragment>
            <div onClick={props.onLogoutClick} className={AppConfig.DRAWER_STYLE === 'TYPE1' ? css(Styles.lowerListContainerType1) : css(Styles.listInnerContainer)}>
              {AppConfig.DRAWER_STYLE === 'TYPE1' ?
                <p className={css([Styles.listColor, Styles.logoutText])}>{Helper.getString('logout')}</p>
                : menuRowItem({
                  key: 'logout',
                  title: Helper.getString('logout'),
                  icon: 'exit_to_app',
                })}
            </div>
            {!(AppConfig.DRAWER_STYLE === 'TYPE1') &&
              <Divider className={css(Styles.divider)} />
            }
          </Fragment>
        }
        {!Controller.get().userMgr().isProductSU() &&
          <div className={AppConfig.DRAWER_STYLE === 'TYPE1' ? css([Styles.lowerListContainerType1, Styles.versionContainer]) : css([Styles.lowerListContainerType1, Styles.versionContainer, Styles.leftAlign])}>
            <p className={css(Styles.orgInfoText)}>{getOrgTitle()}</p>
          </div>
        }
        {AppConfig.DRAWER_STYLE === 'TYPE1' ?
          <p className={css([Styles.lowerListContainerType1, Styles.versionContainer])}>{Helper.getString('version')} {AppConfig.VERSION}</p>
          :
          <div className={css(Styles.versionContainer2)}>{Helper.getString('version')}
            <span className={css(Styles.version)}>{AppConfig.VERSION}</span>
          </div>
        }
      </List>
    )
  }

  return (
    <Fragment>
      <div className={css(Styles.drawerMobileView)} >
        <div className={css(Styles.drawerButtonMobileView)}>
          <div className={css(Styles.drawerButtonContainer)} onClick={() => toggleDrawer()}>
            {UiHelper.renderImg({ key: 'drawerMenu', drawerIsOpen: state.drawerIsOpen })}
          </div>
        </div>
      </div>
      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: state.drawerIsOpen,
          [classes.drawerClose]: !state.drawerIsOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: state.drawerIsOpen,
            [classes.drawerClose]: !state.drawerIsOpen,
          }),
        }}
        variant='permanent'
      >
        {state.drawerIsOpen &&
          <div className={css(Styles.drawerButtonFixContainer)}>
            <div className={css(Styles.drawerButtonContainer)} onClick={() => toggleDrawer()}>
              {UiHelper.renderImg({ key: 'drawerClose' })}
            </div>
          </div>
        }
        {state.drawerIsOpen ?
          <Link to={Controller.get().getHomeByRole()}>
            <div className={css(Styles.drawerImageContainer)}>
              <div className={css(Styles.drawerImageInnerContainer)}>
                <img
                  src={AppConfig.LOGO}
                  alt='Logo' className={css(Styles.drawerImage)} />
              </div>
            </div>
          </Link>
          :
          <div className={css(Styles.drawerCloseButtonContainer)}>
            <div className={css(Styles.drawerButtonContainer)} onClick={() => toggleDrawer()}>
              {UiHelper.renderImg({ type: 'drawerOpen' })}
            </div>
          </div>
        }

        <div tabIndex={0}
          role='button'
          className={AppConfig.DRAWER_STYLE === 'TYPE1' ? css(Styles.switchableDrawerType1) : css(Styles.switchableDrawer)}>
          {renderList()}
        </div>
      </Drawer>
    </Fragment>
  );
}


const DRAWER_BUTTON_MARGN_TOP = 55//38
const Styles = AppStyleHelper.modifyStyleSheet({
  switchableDrawer: {
    overflowY: 'auto',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0px 30px',
    outline: 'none',
    '@media (max-width: 1024px)': {
      justifyContent: 'center',
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  switchableDrawerType1: {
    overflowY: 'auto',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0px 0px',
    outline: 'none',
    '@media (max-width: 1024px)': {
      justifyContent: 'center',
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  switchableListDrawer: {
    paddingLeft: 0,
  },
  menuButtonAlign: {
    '@media (max-width: 767px)': {
      position: 'fixed',
      right: 0,
      top: 10,
      width: '100%'
    }
  },
  menuButton: {
    color: AppTheme.primaryColor,
    marginLeft: 0,
    marginRight: 5,
  },
  swipe: {
    zIndex: 100000000
  },
  swipedrawer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 20,
    paddingRight: 20,
    overflowX: 'auto',
  },
  list: {
    maxWidth: 215,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    '@media (max-width: 768px)': {
      width: 'unset',
    }
  },
  drawer: {
    marginTop: 35
  },
  lowerListContainerType1: {
    padding: '13px 30px',
    margin: 0,
    fontSize: 12,
    color: TEXT_COLOUR,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  versionContainer: {
    cursor: 'default'
  },
  versionContainer2: {
    padding: '8px 30px',
    margin: 0,
    fontSize: 12,
    color: TEXT_COLOUR,
    paddingBottom: 30,
  },
  orgInfoText: {
    fontSize: 'inherit',
    color: 'inherit',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: 0
  },
  link: {
    textDecoration: 'none',
  },
  itemLink: {
    textDecoration: 'none',
    width:'100%'
  },
  linkcolor: {
    color: '#6E84A3',
    marginRight: 0,
    minWidth: 30
  },
  listitem: {
  },
  listButton: {
    padding: '13px 20px',
    color: TEXT_COLOUR,
    borderRadius: 10,
    ':active': {
      backgroundColor: 'none'
    }
  },
  listButton2: {
    padding: '13px 20px',
    color: TEXT_COLOUR,
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    // borderRight: '2px solid #FFFFFF',
    ':active': {
      backgroundColor: 'none'
    }
  },
  listSelectedButton: {
    backgroundColor: AppTheme.primaryColor
  },
  listSelectedButton2: {
    // borderRight: '2px solid ' + AppTheme.primaryColor
  },
  appVersion: {
    color: '#6E84A3',
    fontSize: MENU_TITLE_SIZE,
    paddingLeft: 28
  },
  dividerType1: {
    color: '#edf2f9',
    margin: '0px 0px 10px'
  },
  divider: {
    color: '#edf2f9',
    margin: '10px 0px'
  },
  version: {
    backgroundColor: 'whitesmoke',
    borderRadius: 8,
    color: 'black',
    marginLeft: 5,
    padding: 5,
    fontSize: 12
  },
  userDetail: {
    margin: 0,
    fontSize: 12,
    color: '#6E84A3'
  },
  noHover: {
    cursor: 'text',
    padding: '0px 30px'
  },
  drawerImageContainer: {
    minHeight: AppTheme.toolbarHeight,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 10px',
    justifyContent: 'center'
  },
  drawerImageInnerContainer: {
    width: 108,
  },
  drawerCloseButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: DRAWER_BUTTON_MARGN_TOP
  },
  drawerButtonContainer: {
    width: 34,
    height: 34,
    cursor: 'pointer'
  },
  drawerButtonImage: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  drawerImage: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
  drawerButtonFixContainer: {
    position: 'absolute',
    right: -20,
    top: DRAWER_BUTTON_MARGN_TOP,
    display: 'none'
  },
  listInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0px',
    position: 'relative'
  },
  listInnerContainerType1: {
    position: 'relative',
    margin: 0
  },
  menuHighlight: {
    width: 2,
    height: '100%',
    position: 'absolute',
    right: 0,
    backgroundColor: AppTheme.primaryColor
  },
  listInnerContainerlogout: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '13px 20px',
    margin: 0
  },
  logoutText: {
    margin: 0
  },
  listStyle: {
    width: 4,
    height: 16,
    borderRadius: 2,
    marginRight: 6,
  },
  listSelectedStyle: {
    backgroundColor: AppTheme.primaryColor,
  },
  listItemText: {
    lineHeight: 'unset',
    margin: 0,
    display: 'grid'
  },
  listSelectedColor: {
    color: AppTheme.secondaryBg,
  },
  listSelectedColorType1: {
    color: AppTheme.primaryColor,
  },
  listColor: {
    color: TEXT_COLOUR,
    textTransform: 'capitalize',
    fontSize: MENU_TITLE_SIZE,
    lineHeight: 'unset',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textDecoration:'none'
  },
  accordingListContainer: {
    marginLeft: 20
  },
  drawerMobileView: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block'
    }
  },
  drawerButtonMobileView: {
    position: 'absolute',
    right: 60,
    top: 5,
    zIndex: 100000000,
    '@media (max-width: 767px)': {
      right: 10,
    }
  },
  leftAlign: {
    justifyContent: 'flex-start'
  }
}, { comp: 'drawerMenuDashboard' })
