import { Controller } from '../common/Controller';
import { Helper } from '../common/helpers/Helper';
import { AppConfig } from '../AppConfig';

const vsprintf = require('sprintf-js').vsprintf

export class AppHelper {

  static getDrawerMenuItems() {
    return AppHelper.isDrawerMenuEnabled()
  }

  static getAdminDrawerMenuItems() {
    return ([
      [
        {
          route: Controller.get().getHomeByRole(),
          title: Helper.getString('orgs'),
          icon: 'dashboard',
          key: 'adminOrgs',
        },
        {
          route: Controller.get().getHomeByRole() + '/cbdefs-tags',
          title: Helper.getString('globalBlocksTags'),
          icon: 'space_dashboard',
          key: 'cbdefs-tags',
        },
        {
          route: Controller.get().getHomeByRole() + '/cbdefs',
          title: Helper.getString('globalBlocks'),
          icon: 'space_dashboard',
          key: 'cbdefs',
        },
        {
          route: Controller.get().getHomeByRole() + '/emailcbdefs',
          title: Helper.getString('emailglobalBlocks'),
          icon: 'space_dashboard',
          key: 'emailcbdefs',
        },
        {
          route: Controller.get().getHomeByRole() + '/emailpages',
          title: Helper.getString('emailpageslib'),
          icon: 'email',
          key: 'emailpages',
        },
      ],
      [
        {
          route: vsprintf('/admin/users'),
          title: Helper.getString('productusers'),
          icon: 'supervisor_account',
          key: 'usersOfOrg',
        }
      ]
    ])
  }

  static getOrgSUDrawerMenuItems(orgId) {
    return ([
      [
        {
          key: 'orgDashboard',
          route: vsprintf('/admin/orgs/%s', [orgId]),
          title: Helper.getString('dashboard'),
          icon: 'dashboard'
        },
        {
          key: 'settingsbranding',
          route: vsprintf('/admin/orgs/%s/branding', [orgId]),
          title: Helper.getString('settingsbranding'),
        },
        {
          key: 'assets',
          route: vsprintf('/admin/orgs/%s/assets', [orgId]),
          title: Helper.getString('assets'),
        },
        // {
        //   key: 'pages',
        //   route: vsprintf('/admin/orgs/%s/pages', [orgId]),
        //   title: Helper.getString('pages'),
        // },
        {
          key: 'site',
          title: Helper.getString('site'),
          subItems: [
            {
              key: 'pages',
              route: vsprintf('/admin/orgs/%s/pages', [orgId]),
              title: Helper.getString('pages'),
            },
            {
              key: 'cborgs',
              route: vsprintf('/admin/orgs/%s/cborgs', [orgId]),
              title: Helper.getString('blocks-widgets'),
            },
            {
              key: 'menus',
              route: vsprintf('/admin/orgs/%s/menus', [orgId]),
              title: Helper.getString('menus'),
            },
            {
              key: 'footernav',
              route: vsprintf('/admin/orgs/%s/footernavs', [orgId]),
              title: Helper.getString('footernav'),
            },
            {
              key: 'cotypes',
              route: vsprintf('/admin/orgs/%s/cos', [orgId]),
              title: Helper.getString('cotypes'),
            },
          ]
        },
        {
          key: 'blogSection',
          title: Helper.getString('blogs'),
          subItems: [
            {
              key: 'blogs',
              route: vsprintf('/admin/orgs/%s/blogs', [orgId]),
              title: Helper.getString('blogs'),
              icon: 'supervisor_account'
            },
            {
              key: 'blogTags',
              route: vsprintf('/admin/orgs/%s/btags', [orgId]),
              title: Helper.getString('tags'),
              icon: 'supervisor_account'
            },
            {
              key: 'blogLists',
              route: vsprintf('/admin/orgs/%s/blists', [orgId]),
              title: Helper.getString('lists'),
              icon: 'supervisor_account'
            },
          ]
        },
        // {
        //   key: 'nav',
        //   title: Helper.getString('nav'),
        //   subItems: [
        //     {
        //       key: 'menus',
        //       route: vsprintf('/admin/orgs/%s/menus', [orgId]),
        //       title: Helper.getString('menus'),
        //     },
        //     {
        //       key: 'footernav',
        //       route: vsprintf('/admin/orgs/%s/footernavs', [orgId]),
        //       title: Helper.getString('footernav'),
        //     },
        //   ]
        // },
        {
          key: 'campaigns',
          title: Helper.getString('campaigns'),
          subItems: [
            {
              key: 'broadcasts',
              route: vsprintf('/admin/orgs/%s/campaigns/broadcasts', [orgId]),
              title: Helper.getString('broadcasts'),
            },
            /*{
              key: 'transactional',
              route: vsprintf('/admin/orgs/%s/campaigns/transactional', [orgId]),
              title: Helper.getString('transactional'),
            },*/
            /*{
              key: 'notifications',
              route: vsprintf('/admin/orgs/%s/campaigns/notifications', [orgId]),
              title: Helper.getString('notifications'),
            },*/
            /*{
              key: 'journeys',
              route: vsprintf('/admin/orgs/%s/journeys/messaging', [orgId]),
              title: Helper.getString('journeys'),
            },*/
            /*{
              key: 'popups',
              route: vsprintf('/admin/orgs/%s/campaigns/popups', [orgId]),
              title: Helper.getString('popups'),
            },*/
            /*{
              key: 'emailpages',
              route: vsprintf('/admin/orgs/%s/emailpages', [orgId]),
              title: Helper.getString('emailpages'),
            },*/
            {
              key: 'segments',
              route: vsprintf('/admin/orgs/%s/segments', [orgId]),
              title: Helper.getString('segments'),
            },
          ]
        },
        {
          key: 'crm',
          title: Helper.getString('crm'),
          subItems: [
            {
              key: 'crm-contacts',
              route: vsprintf('/admin/orgs/%s/crm-contacts', [orgId]),
              title: Helper.getString('crm-contacts'),
            },
            {
              key: 'crm-sequences',
              route: vsprintf('/admin/orgs/%s/campaigns/sequences', [orgId]),
              title: Helper.getString('crm-sequences'),
            },
            {
              key: 'crm-settings',
              route: vsprintf('/admin/orgs/%s/crm-settings', [orgId]),
              title: Helper.getString('crm-settings'),
            },
            {
              key: 'crm-inboxes',
              route: vsprintf('/admin/orgs/%s/crm-inboxes', [orgId]),
              title: Helper.getString('crm-inboxes'),
            },
            {
              key: 'crm-email-templates',
              route: vsprintf('/admin/orgs/%s/crm-email-templates', [orgId]),
              title: Helper.getString('crm-email-templates-short'),
            },
            {
              key: 'crm-user-settings',
              route: vsprintf('/admin/orgs/%s/crm-user-settings', [orgId]),
              title: Helper.getString('crm-user-settings'),
            },
            {
              key: 'forms',
              route: vsprintf('/admin/orgs/%s/forms', [orgId]),
              title: Helper.getString('forms'),
            },
            {
              key: 'jobs',
              route: vsprintf('/admin/orgs/%s/jobscrm', [orgId]),
              title: Helper.getString('jobs'),
            },
          ]
        },
        {
          key: 'catalogSection',
          title: Helper.getString('ecom'),
          subItems: [
            {
              key: 'products',
              route: vsprintf('/admin/orgs/%s/products', [orgId]),
              title: Helper.getString('products'),
              icon: 'layers'
            },
            {
              key: 'catsL1',
              route: vsprintf('/admin/orgs/%s/cats', [orgId]),
              title: Helper.getString('cats'),
              icon: 'category'
            },
            {
              key: 'brands',
              route: vsprintf('/admin/orgs/%s/brands', [orgId]),
              title: Helper.getString('brands'),
              icon: 'loyalty'
            },
            // {
            //   key: 'banners',
            //   route: vsprintf('/admin/orgs/%s/banners', [orgId]),
            //   title: Helper.getString('banners'),
            //   icon: 'view_carousel',
            // },
            {
              key: 'prodLists',
              route: vsprintf('/admin/orgs/%s/lists', [orgId]),
              title: Helper.getString('lists'),
              icon: 'collections_bookmark'
            },
            {
              key: 'orders',
              route: vsprintf('/admin/orgs/%s/orders', [orgId]),
              title: Helper.getString('orders'),
              icon: 'local_mall'
            },
            {
              key: 'coupons',
              route: vsprintf('/admin/orgs/%s/coupons', [orgId]),
              title: Helper.getString('coupons'),
              icon: 'local_offer'
            },
            // {
            //   key: 'testimonials',
            //   route: vsprintf('/admin/orgs/%s/testimonials', [orgId]),
            //   title: Helper.getString('testimonials'),
            //   icon: 'supervisor_account'
            // },
            // {
            //   key: 'benefits',
            //   route: vsprintf('/admin/orgs/%s/benefits', [orgId]),
            //   title: Helper.getString('benefits'),
            //   icon: 'supervisor_account'
            // },
            // {
            //   key: 'faqs',
            //   route: vsprintf('/admin/orgs/%s/faqs', [orgId]),
            //   title: Helper.getString('faqs'),
            //   icon: 'supervisor_account',
            // },
            // {
            //   key: 'faqSections',
            //   route: vsprintf('/admin/orgs/%s/faqsections', [orgId]),
            //   title: Helper.getString('faqsections'),
            //   icon: 'supervisor_account'
            // }
          ]
        },
        AppHelper.getContentDrawerObject(orgId),
      ],
      [
        {
          key: 'settings',
          route: vsprintf('/admin/orgs/%s/settings', [orgId]),
          title: Helper.getString('settings'),
          icon: 'supervisor_account'
        },
        {
          key: 'usersOfOrg',
          route: vsprintf('/admin/orgs/%s/users', [orgId]),
          title: Helper.getString('admins'),
          icon: 'supervisor_account'
        },
      ]
    ])
  }

  static getOrgBloggerDrawerMenuItems(orgId) {
    return (
      [
        // {
        //   key: 'content',
        //   title: Helper.getString('ai-content'),
        //   subItems: [
        //     {
        //       key: 'content-personas',
        //       route: vsprintf('/admin/orgs/%s/content/personas', [orgId]),
        //       title: Helper.getString('content-personas-short'),
        //       icon: 'supervisor_account'
        //     },
        //     {
        //       key: 'content-generator',
        //       route: vsprintf('/admin/orgs/%s/content/generator', [orgId]),
        //       title: Helper.getString('content-blog-generator'),
        //       icon: 'supervisor_account'
        //     },
        //   ],
        // },
        {
          key: 'blogs',
          route: vsprintf('/admin/orgs/%s/blogs', [orgId]),
          title: Helper.getString('blogs'),
          icon: 'supervisor_account'
        },
        AppHelper.getContentDrawerObject(orgId),
      ]
    )
  }

  static getOrgCRMDrawerMenuItems(orgId) {
    return (
      [
        {
          key: 'crm',
          title: Helper.getString('crm'),
          subItems: [
            {
              key: 'crm-contacts',
              route: vsprintf('/admin/orgs/%s/crm-contacts', [orgId]),
              title: Helper.getString('crm-contacts'),
            },
            {
              key: 'crm-sequences',
              route: vsprintf('/admin/orgs/%s/campaigns/sequences', [orgId]),
              title: Helper.getString('crm-sequences'),
            },
            {
              key: 'crm-inboxes',
              route: vsprintf('/admin/orgs/%s/crm-inboxes', [orgId]),
              title: Helper.getString('crm-inboxes'),
            },
            {
              key: 'crm-email-templates',
              route: vsprintf('/admin/orgs/%s/crm-email-templates', [orgId]),
              title: Helper.getString('crm-email-templates-short'),
            },
            {
              key: 'crm-user-settings',
              route: vsprintf('/admin/orgs/%s/crm-user-settings', [orgId]),
              title: Helper.getString('crm-user-settings'),
            },
            // {
            //   key: 'crm-settings',
            //   route: vsprintf('/admin/orgs/%s/crm-settings', [orgId]),
            //   title: Helper.getString('crm-settings'),
            // },
            // {
            //   key: 'forms',
            //   route: vsprintf('/admin/orgs/%s/forms', [orgId]),
            //   title: Helper.getString('forms'),
            // },
            {
              key: 'jobs',
              route: vsprintf('/admin/orgs/%s/jobscrm', [orgId]),
              title: Helper.getString('jobs'),
            },
          ]
        },
      ]
    )
  }

  static isDrawerMenuEnabled() {
    let orgId = Controller.get().userMgr().getOrgId()
    const pName = window.location.pathname
    let menu = []
    if (pName.startsWith('/admin/orgs/')) {
      if (pName.endsWith('/preview')) {
        return null
      }
      orgId = pName.split('/')[3]
      if (Controller.get().userMgr().isProductSU()) {
        menu = AppHelper.getOrgSUDrawerMenuItems(orgId)
      } else if (AppConfig.ORGSU_ACCESS_ALLOWED && Controller.get().userMgr().hasOrgRole(orgId, 'SUPERUSER')) {
        menu = AppHelper.getOrgSUDrawerMenuItems(orgId)
      } else {
        const submenu = []
        if (Controller.get().userMgr().hasOrgRole(orgId, 'BLOGGER')) {
          submenu.push(...AppHelper.getOrgBloggerDrawerMenuItems(orgId))
        }
        if (Controller.get().userMgr().hasOrgRole(orgId, 'CRM')) {
          submenu.push(...AppHelper.getOrgCRMDrawerMenuItems(orgId))
        }
        if (submenu.length > 0) { menu.push(submenu) }
      }
    } else if (pName.startsWith('/admin')) {
      if (Controller.get().userMgr().isProductSU()) {
        menu = AppHelper.getAdminDrawerMenuItems()
      }
    }
    if (AppConfig.ADMIN_DRAWER_MENU_ENABLED && menu.length > 0) {
      return menu
    }
    return null
  }

  static getPublicDrawerMenuItems() {
    return []
  }

  static getContentDrawerObject(orgId) {
    return {
      key: 'content',
      title: Helper.getString('ai-content'),
      subItems: [
        {
          key: 'content-personas',
          route: vsprintf('/admin/orgs/%s/content/personas', [orgId]),
          title: Helper.getString('content-personas-short'),
          icon: 'supervisor_account'
        },
        {
          key: 'content-planner',
          route: vsprintf('/admin/orgs/%s/content/planner', [orgId]),
          title: Helper.getString('content-planner'),
          icon: 'supervisor_account'
        },
        {
          key: 'content-generator',
          route: vsprintf('/admin/orgs/%s/content/generator', [orgId]),
          title: Helper.getString('content-blog-generator'),
          icon: 'supervisor_account'
        },
      ],
    }
  }
  static parseOrgId(pathname) {
    if(pathname) {
      const pathSpilt = pathname.split('/')
      // console.log(pathSpilt,'PATH_SPLIT');
      if(pathname && pathname.length > 2 && pathSpilt[1] === 'admin' && pathSpilt[2] === 'orgs') {
        return pathSpilt[3]
      }
    }
  }

  static modifyCbSiteData(cbSiteList, cbGlobalList,cbTenantList) {

    const orgId = this.parseOrgId(window.location.pathname)
    const widgetsTagsData = {}

    cbGlobalList.items.forEach(widDef=>{
      if(!widgetsTagsData[widDef.id]) widgetsTagsData[widDef.id] = {}
      if(widDef.tags) {
        widgetsTagsData[widDef.id].tags = widDef.tags
      }
      if(widDef.state) {
        widgetsTagsData[widDef.id].state = widDef.state
      }
      if(widDef.cbstyle) {
        widgetsTagsData[widDef.id].cbstyle = widDef.cbstyle
      }
      if(widDef.image) {
        widgetsTagsData[widDef.id].image = widDef.image
      }
      if(widDef.id) {
        widgetsTagsData[widDef.id].id = widDef.id
      }
      if(widDef.singleton) {
        widgetsTagsData[widDef.id].singleton = widDef.singleton
      }
    })

    for (const widgetId in cbTenantList) {
      const widget = cbTenantList[widgetId]
      if(!widgetsTagsData[widgetId]) widgetsTagsData[widgetId] = {}
      if(widget.id) {
        widgetsTagsData[widgetId].id = widget.id
      }
      if(widget.singleton) {
        widgetsTagsData[widgetId].singleton = widget.singleton
      }
    }

    cbSiteList.map(widDef=>{
      if(!widDef.tags && widgetsTagsData[widDef.cbGlobalId] && widgetsTagsData[widDef.cbGlobalId].tags) {
        widDef.tags = widgetsTagsData[widDef.cbGlobalId].tags
      }
      if(!widDef.state && widgetsTagsData[widDef.cbGlobalId] && widgetsTagsData[widDef.cbGlobalId].state) {
        widDef.state = widgetsTagsData[widDef.cbGlobalId].state
      }
      if(!widDef.cbstyle && widgetsTagsData[widDef.cbGlobalId] && widgetsTagsData[widDef.cbGlobalId].cbstyle) {
        widDef.cbstyle = widgetsTagsData[widDef.cbGlobalId].cbstyle
      }
      if(!widDef.image && widgetsTagsData[widDef.cbGlobalId] && widgetsTagsData[widDef.cbGlobalId].image) {
        widDef.image = widgetsTagsData[widDef.cbGlobalId].image
      }

      if(!widDef.reusableTraverseId &&
        widgetsTagsData[widDef.cbGlobalId || widDef.cbTenantId] &&
        widgetsTagsData[widDef.cbGlobalId || widDef.cbTenantId].id) {
        const options = {
          orgId,
          widgetId: widDef.id,
          widgetGlobalId: widgetsTagsData[widDef.cbGlobalId || widDef.cbTenantId].id,
        };
        widDef.reusableTraverseId =  this.resuableWidgetScopeLink(options)
      }

      if(!widDef.singleton &&
        widgetsTagsData[widDef.cbGlobalId || widDef.cbTenantId] &&
        widgetsTagsData[widDef.cbGlobalId || widDef.cbTenantId].singleton) {
        widDef.singleton = widgetsTagsData[widDef.cbGlobalId || widDef.cbTenantId].singleton
      }

      return widDef
    })

    return cbSiteList
  }

  static resuableWidgetScopeLink(options) {
    const orgId = options.orgId || ''
    const widgetId = options.widgetId || ''
    const widgetGlobalId = options.widgetGlobalId || ''

    return 'o' + orgId + '-' + widgetId + '-' + widgetGlobalId
  }

  static preFillWidgetDefaultValues (formDef, cbDef) {//currently done only for subType 'color'
    for (const step of formDef.steps) {
      for (const field of step.fields) {
        if(cbDef.fields[field.key] && cbDef.fields[field.key].defaultVal && field.subType === 'colour') {
          field.defaultValue = cbDef.fields[field.key].defaultVal
        }
      }
    }

    return formDef
  }

  static pageCbId(id) {
    return `#page-cb-${id}`
  }

  static infoTextHelper(data) {
    try {
      // Its a valid json
      const parsedData = JSON.parse(data)
      if(!parsedData.__azformatted)return ''

      let renderText = ''
      parsedData.__azformatted.blocks && parsedData.__azformatted.blocks.forEach(block=>{
        if(block.text) {
          renderText += block.text + ' '
        }
      })
      return renderText.trim()
    } catch (err) {
      // its a plain stiring
      return data
    }
  }

  static widgetStatus(items, options) {
    const allItems = options.cbGlobal || {}
    const tenantItems = options.cbOrg || {}
    const widStatus = {}
    if(allItems.items) {
      allItems.items.forEach(item => {
        if(!widStatus[item.id]) {
          widStatus[item.id] = item.state
        }
      });
    }
    if(tenantItems.items) {
      tenantItems.items.forEach(item => {
        if(!widStatus[item.id]) {
          widStatus[item.id] = item.state
        }
      });
    }
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      const id = item.cbGlobalId || item.cbTenantId
      if(item.id && widStatus[id] && widStatus[id] === 'DISABLED') {
        items.splice(index,1)
      }
    }
  }

  static getWidgetPageId(widgetId, pageBlocks) {
    for (const widget in pageBlocks) {
      const widData = pageBlocks[widget];
      if(widget === widgetId || widData.cbSiteBlockId === widgetId) {
        return widData.id
      }
    }
    return ''
  }

  static generateNewOrder(items) {
    let largest = 0
    let newOrder = 10
    if(items) {
      const orderNumbers = items.map(item => Number(item.order)).filter(order => !Number.isNaN(order));
      if(orderNumbers.length > 0) {
        largest = Math.max(...orderNumbers)
        if(largest > 0 ) {
          newOrder = (Math.floor(largest / 10) * 10) + 10
        }
      }
    }
    newOrder = newOrder.toString().padStart(3, '0')
    return newOrder
  }
}
