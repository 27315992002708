/* eslint-disable camelcase */
import queryString from 'query-string';
import React from 'react';

import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppConfig } from '../../../AppConfig';
import KeywordsTable from './KeywordsTable';
import { addBlogToTopic, csvToLineBreak, extractNumberedListToArray, extractUniqueLines, generateText, lineBreakToCsv, makeRequest } from './ContentUtils';

function getBlogOutlineSubtopicSuggestions(outlineSuggestions, inputValue) {
  const blogOutlineInputItems = extractUniqueLines(inputValue, false);
  const subtopicSuggestions = [];
  outlineSuggestions.forEach(s => {
    const subtopics = extractUniqueLines(s.text, false);
    subtopics.forEach(t => {
      if (subtopicSuggestions.every(f => f.text !== t)) {
        subtopicSuggestions.push({text: t, selected: blogOutlineInputItems.includes(t)});
      }
    });
  })
  return subtopicSuggestions;
}

function getNextBtnTextForState(state) {
  if (state === 'summary') {
    return 'Generate blog';
  } else if (state === 'generatingBlog') {
    return 'Start over';
  }
  return 'Next';
}

function keywordsInputToList(text) {
  return text.split(',').filter(t => t !== '').map(k => k.toLowerCase().trim());
}

function extractQueryStringParams(queryString) {
  const personaId = queryString.personaId || 'none';
  const seedKeywordsInput = queryString.seedKeywordsInput || '';
  const topicId = queryString.topicId;
  const topicPillar = queryString.topicPillar || '';
  const topicClusterTitle = queryString.topicClusterTitle || '';
  const plannerStateIndex = personaId === 'none' ? 0 : (topicPillar && topicClusterTitle) ? 3 : (seedKeywordsInput ? 2 : 1);
  return {
    personaId,
    seedKeywordsInput,
    topicId,
    topicPillar,
    topicClusterTitle,
    plannerStateIndex,
  };
}

const PLANNER_STATES = ['persona', 'seedKeywords', 'relatedKeywords', 'titles', 'intro', 'outline', 'summary', 'generatingBlog']
export class ContentGenerator extends BaseEntityPage {
  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CONTENT_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('content-blog-generator')
    this.queryString = queryString.parse(this.props.location.search)
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('content-blog-generator') },
    ]
    this.toFetch = [
      {
        method: 'GET',
        paths: ['contents', this.orgId],
        queryParams: { groupId: this.orgId, pb: 'fieldDefContentPersonasEntity', limit: 100 },
        ms: this.ms,
      },
    ]
    this.pageKey = 'content-generator'
    this.noAdd = true;
    this.personas = this.personas || [];

    const {personaId, seedKeywordsInput, topicPillar, topicClusterTitle, topicId, plannerStateIndex} = extractQueryStringParams(this.queryString);
    this.state.topicPillar = topicPillar;
    this.state.topicClusterTitle = topicClusterTitle;
    this.state.topicId = topicId;
    this.state.plannerState = PLANNER_STATES[plannerStateIndex];
    this.state.selectedPersonaId = personaId;
    this.state.seedKeywordsInput = seedKeywordsInput;
    this.state.seedKeywords = keywordsInputToList(seedKeywordsInput);
    this.state.seedKeywordsSuggestions = [];
    this.state.relatedKeywords = [];
    this.state.negativeKeywordsInputValue = '';
    this.state.blogTitleInput = '';
    this.state.blogTitleSuggestions = [];
    this.state.blogIntroInput = '';
    this.state.blogIntroSuggestions = [];
    this.state.blogOutlineInput = '';
    this.state.blogOutlineSuggestions = [];
    this.state.blogOutlineSubtopicSuggestions = [];
    this.state.createdBlogId = '';
    this.state.promptTokensConsumed = 0;
    this.state.completionTokensConsumed = 0;
    this.state.totalTokensConsumed = 0;
    this.state.relatedKeywordRequests = 0;

    this.onPersonaChange = this.onPersonaChange.bind(this);
    this.onRelatedKeywordSelectionChange = this.onRelatedKeywordSelectionChange.bind(this);
    this.onAllRelatedKeywordsSelectionChange = this.onAllRelatedKeywordsSelectionChange.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.onNextButtonClick = this.onNextButtonClick.bind(this);
    this.fetchSuggestedSeedKeywords = this.fetchSuggestedSeedKeywords.bind(this);
    this.onSuggestedKeywordClick = this.onSuggestedKeywordClick.bind(this);
    this.onSeedKeywordsInputChange = this.onSeedKeywordsInputChange.bind(this);
    this.onNegativeKeywordsInputChange = this.onNegativeKeywordsInputChange.bind(this);
    this.onBlogTitleInputChange = this.onBlogTitleInputChange.bind(this);
    this.onBlogIntroInputChange = this.onBlogIntroInputChange.bind(this);
    this.onBlogOutlineInputChange = this.onBlogOutlineInputChange.bind(this);
    this.fetchSuggestedTitles = this.fetchSuggestedTitles.bind(this);
    this.fetchSuggestedIntros = this.fetchSuggestedIntros.bind(this);
    this.fetchSuggestedOutlines = this.fetchSuggestedOutlines.bind(this);
  }

  componentDidUpdate() {
    if (this.isUiInitialising && this.state.fetchState === ApiHelper.State.READY) {
      this.setState({
        fetchState: ApiHelper.State.LOADING
      })
    }
  }

  onFetchSuccess(results) {
    this.personas = Object.values(results[0].personas);
    this.topics = Object.values(results[0].topics || {});

    const fieldDefContentPersonasEntity = JSON.parse(JSON.stringify((results[0].fieldDefContentPersonasEntity)));
    this.fieldDef = this.formDefinition = fieldDefContentPersonasEntity;

    this.setState({
      items: this.personas,
    })

    this.initialiseNewPlannerState(this.state.plannerState);
  }

  onPersonaChange(event) {
    const newPersona = event.target.value;
    this.setState(prevState => ({...prevState, selectedPersonaId: newPersona}));
  }

  validateStateTransition(newPlannerState) {
    let isValid = true;
    let errorMsg = '';

    switch(newPlannerState) {
    case 'seedKeywords':
      isValid = this.state.selectedPersonaId !== 'none';
      errorMsg = 'Please select a user persona.';
      break;
    case 'relatedKeywords':
      isValid = this.state.seedKeywordsInput !== '';
      errorMsg = 'Please select at least 1 seed keyword.';
      break;
    case 'titles':
      isValid = this.state.relatedKeywords.filter(k => k.selected).length > 0;
      errorMsg = 'Please select at least 1 related keyword.';
      break;
    case 'intro':
      isValid = this.state.blogTitleInput !== '';
      errorMsg = 'Please select a blog title.';
      break;
    case 'outline':
      isValid = this.state.blogIntroInput !== '';
      errorMsg = 'Please select a blog introduction.';
      break;
    case 'summary':
      isValid = this.state.blogOutlineInput !== '';
      errorMsg = 'Please select a blog outline.';
      break;
    case 'generatingBlog':
      isValid = true;
      break;
    }
    if (!isValid) {
      this.showMessage(errorMsg);
    }
    return isValid;
  }

  async initialiseNewPlannerState(newPlannerState) {
    this.isUiInitialising = true;
    switch(newPlannerState) {
    case 'persona':
      break;
    case 'seedKeywords':
      await this.fetchSuggestedSeedKeywords();
      break;
    case 'relatedKeywords':
      await this.fetchRelatedKeywords();
      break;
    case 'titles':
      this.setState(prevState => ({...prevState, blogTitleInput: ''}));
      await this.fetchSuggestedTitles();
      break;
    case 'intro':
      this.setState(prevState => ({...prevState, blogIntroInput: ''}));
      await this.fetchSuggestedIntros();
      break;
    case 'outline':
      this.setState(prevState => ({...prevState, blogOutlineInput: ''}));
      await this.fetchSuggestedOutlines();
      break;
    case 'summary':
      break;
    case 'generatingBlog':
      this.setState(prevState => ({...prevState, createdBlogId: ''}));
      this.isUiInitialising = false;
      await this.generateBlog();
      break;
    }
    this.isUiInitialising = false;
    this.setState({
      fetchState: ApiHelper.State.READY
    })
  }

  async fetchSuggestedSeedKeywords() {
    const promptSequence = 'blog.seedKeywords';
    const replacements = {
      '${userPersona}': this.personas.find(p => p.id === this.state.selectedPersonaId).title,
      '${seedKeywords}': this.state.seedKeywordsInput,
    }

    const generatedResult = await generateText(this.orgId, this.ms, promptSequence, replacements, this.updateLoaderUi.bind(this));
    const extractedArray = extractNumberedListToArray(generatedResult.text).filter(e => e.length <= 80);
    const inputSeedKeywords = this.state.seedKeywordsInput.split(',').map(k => k.toLowerCase());
    const seedKeywordsSuggestions = extractedArray.map(e => ({keyword: e.toLowerCase(), selected: inputSeedKeywords.includes(e.toLowerCase())}));
    this.setState(prevState => ({ ...prevState, seedKeywordsSuggestions }));
  }

  fetchRelatedKeywords() {
    let seedKeywords = this.state.seedKeywords.filter(k => k !== '').map(k => k.toLowerCase());
    if (seedKeywords.length > 20) {
      seedKeywords = seedKeywords.slice(0, 20);
    }
    const postData = {
      seedKeywords,
      mockResponse: [
        { keyword: 'software architecture', competition: 'LOW', competition_index: 0, high_top_of_page_bid: 0.5, low_top_of_page_bid: 0.2, search_volume: 1000 },
        { keyword: 'software architecture best practices', competition: 'HIGH', competition_index: 100, high_top_of_page_bid: 50, low_top_of_page_bid: 2, search_volume: 500 }
      ],
      isMocked: false,
    }
    return makeRequest(this.ms, ['miscs', 'contentgen-keywords'], postData, {}, 'POST', this.updateLoaderUi.bind(this))
      .then(response => {
        if (response) {
          this.setState(prevState => ({...prevState, relatedKeywordRequests: this.state.relatedKeywordRequests + 1}));
          let relatedKeywords = response;
          if (relatedKeywords.length > 100) {
            relatedKeywords = relatedKeywords.slice(0, 100);
          }
          relatedKeywords = relatedKeywords.map((k, i) => ({...k, relevance: i + 1, selected: false}))
          this.setState(prevState => ({...prevState, relatedKeywords}));
        }
      });
  }

  onRelatedKeywordSelectionChange(target) {
    const index = this.state.relatedKeywords.findIndex(k => k.keyword === target.keyword);
    const relatedKeywords = this.state.relatedKeywords;
    relatedKeywords[index].selected = !relatedKeywords[index].selected;
    this.setState(prevState => ({...prevState, relatedKeywords}));
  }

  onAllRelatedKeywordsSelectionChange(event) {
    let relatedKeywords = [];
    if (event.target.checked) {
      relatedKeywords = this.state.relatedKeywords.map(k => ({...k, selected: true}));
    } else {
      relatedKeywords = this.state.relatedKeywords.map(k => ({...k, selected: false}));
    }
    this.setState(prevState => ({...prevState, relatedKeywords}));
  }

  onNegativeKeywordsInputChange(event) {
    const inputValue = event.target.value;
    this.setState(prevState => ({...prevState, negativeKeywordsInputValue: inputValue}));
  }

  onBackButtonClick(event) {
    const currentStateIndex = PLANNER_STATES.indexOf(this.state.plannerState);
    const toJump = this.state.topicId && this.state.plannerState === 'titles' ? 3 : 1;
    const newPlannerStateIndex = Math.max(0, currentStateIndex - toJump);
    const newPlannerState = PLANNER_STATES[newPlannerStateIndex];
    this.setState(prevState => ({...prevState, plannerState: newPlannerState}));
  }

  onNextButtonClick(event) {
    if (this.state.plannerState === 'generatingBlog') {
      this.startOver();
      return;
    }
    const currentStateIndex = PLANNER_STATES.indexOf(this.state.plannerState);
    const toJump = this.state.topicId && this.state.plannerState === 'persona' ? 3 : 1;
    const newPlannerStateIndex = Math.min(PLANNER_STATES.length - 1, currentStateIndex + toJump);
    const newPlannerState = PLANNER_STATES[newPlannerStateIndex];
    const isValid = this.validateStateTransition(newPlannerState);
    if (!isValid) {
      return;
    }
    this.initialiseNewPlannerState(newPlannerState);
    this.setState(prevState => ({...prevState, plannerState: newPlannerState}));
  }

  onSuggestedKeywordClick(clicked) {
    let newSeedKeywords = this.state.seedKeywords;
    if (clicked.selected) {
      newSeedKeywords = this.state.seedKeywords.filter(k => k !== clicked.keyword);
    } else {
      newSeedKeywords.push(clicked.keyword);
    }
    const newSeedKeywordInout = newSeedKeywords.join(', ');
    const suggestedKeywords = JSON.parse(JSON.stringify(this.state.seedKeywordsSuggestions));
    const findK = suggestedKeywords.find(k => k.keyword === clicked.keyword);
    findK.selected = !findK.selected;
    this.setState(prevState => ({
      ...prevState,
      seedKeywordsInput: newSeedKeywordInout,
      seedKeywords: newSeedKeywords,
      seedKeywordsSuggestions: suggestedKeywords,
    }));
  }

  onToggleMenuClick(clicked, key, inputKey) {
    const newValue = this.state[key];
    newValue.forEach(t => {
      t.selected = clicked.text === t.text;
    });

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[key] = newValue;
      newState[inputKey] = clicked.text;
      return newState;
    });
  }

  onBlogOutlineOptionClick(clicked, inputKey) {
    const oldInputValue = this.state[inputKey];
    const separator = oldInputValue ? '\n' : '';
    const newInputValue = `${oldInputValue}${separator}${clicked.text}`;
    const blogOutlineSubtopicSuggestions = getBlogOutlineSubtopicSuggestions(this.state.blogOutlineSuggestions, newInputValue);
    this.setState(prevState => {
      const newState = { ...prevState, blogOutlineSubtopicSuggestions };
      newState[inputKey] = newInputValue;
      return newState;
    });
  }

  onBlogOutlineSubtopicClick(clicked) {
    let blogOutlineSubtopics = extractUniqueLines(this.state.blogOutlineInput, false);
    if (clicked.selected) {
      blogOutlineSubtopics = blogOutlineSubtopics.filter(t => t !== clicked.text);
    } else {
      blogOutlineSubtopics.push(clicked.text);
    }
    this.state.blogOutlineSubtopicSuggestions.find(k => k.text === clicked.text).selected = !clicked.selected;
    const blogOutlineInput = blogOutlineSubtopics.map(t => `- ${t}`).join('\n');
    const blogOutlineSubtopicSuggestions = getBlogOutlineSubtopicSuggestions(this.state.blogOutlineSuggestions, blogOutlineInput);
    this.setState(prevState => ({
      ...prevState,
      blogOutlineInput,
      blogOutlineSubtopicSuggestions,
    }));
  }

  onBlogTitleInputChange(event) {
    const blogTitleInput = event.target.value;
    const blogTitleSuggestions = JSON.parse(JSON.stringify(this.state.blogTitleSuggestions));
    blogTitleSuggestions.forEach(t => {
      t.selected = blogTitleInput === t.text;
    });
    this.setState(prevState => ({...prevState, blogTitleInput, blogTitleSuggestions}));
  }

  onBlogIntroInputChange(event) {
    const blogIntroInput = event.target.value;
    const blogIntroSuggestions = JSON.parse(JSON.stringify(this.state.blogIntroSuggestions));
    blogIntroSuggestions.forEach(t => {
      t.selected = blogIntroInput === t.text;
    });
    this.setState(prevState => ({...prevState, blogIntroInput, blogIntroSuggestions}));
  }

  onBlogOutlineInputChange(event) {
    const blogOutlineInput = event.target.value;
    const blogOutlineSubtopicSuggestions = getBlogOutlineSubtopicSuggestions(this.state.blogOutlineSuggestions, blogOutlineInput);
    this.setState(prevState => ({...prevState, blogOutlineInput, blogOutlineSubtopicSuggestions}));
  }

  onSeedKeywordsInputChange(event) {
    const seedKeywordsInputValue = event.target.value;
    const newSeedKeywords = keywordsInputToList(seedKeywordsInputValue);
    const suggestedKeywords = JSON.parse(JSON.stringify(this.state.seedKeywordsSuggestions));
    suggestedKeywords.forEach(k => {
      if (newSeedKeywords.includes(k.keyword)) {
        k.selected = true;
      } else {
        k.selected = false;
      }
    });

    this.setState(prevState => ({
      ...prevState,
      seedKeywordsInput: seedKeywordsInputValue,
      seedKeywords: newSeedKeywords,
      seedKeywordsSuggestions: suggestedKeywords,
    }));
  }

  async fetchSuggestedTitles() {
    const promptSequence = 'blog.titles';
    const replacements = {
      '${userPersona}': this.personas.find(p => p.id === this.state.selectedPersonaId).title,
      '${seedKeywords}': this.state.seedKeywordsInput,
      '${relatedKeywords}': this.state.relatedKeywords.filter(k => k.selected).map(k => k.keyword).join(', '),
      '${topicPillar}': this.state.topicPillar,
      '${topicClusterTitle}': this.state.topicClusterTitle,
    }

    const generatedResult = await generateText(this.orgId, this.ms, promptSequence, replacements, this.updateLoaderUi.bind(this));
    const blogTitleSuggestions = extractNumberedListToArray(generatedResult.text).map(e => ({text: e, selected: false}));
    this.setState(prevState => ({ ...prevState, blogTitleSuggestions }));
  }

  async fetchSuggestedIntros() {
    const promptSequence = 'blog.intros';
    const replacements = {
      '${userPersona}': this.personas.find(p => p.id === this.state.selectedPersonaId).title,
      '${seedKeywords}': this.state.seedKeywordsInput,
      '${relatedKeywords}': this.state.relatedKeywords.filter(k => k.selected).map(k => k.keyword).join(', '),
      '${topicPillar}': this.state.topicPillar,
      '${topicClusterTitle}': this.state.topicClusterTitle,
      '${blogTitle}': this.state.blogTitleInput,
    }

    const generatedResult = await generateText(this.orgId, this.ms, promptSequence, replacements, this.updateLoaderUi.bind(this));
    const blogIntroSuggestions = extractNumberedListToArray(generatedResult.text).map(e => ({text: e, selected: false}));
    this.setState(prevState => ({ ...prevState, blogIntroSuggestions }));
  }

  async fetchSuggestedOutlines() {
    const promptSequence = 'blog.outlines';
    const replacements = {
      '${userPersona}': this.personas.find(p => p.id === this.state.selectedPersonaId).title,
      '${seedKeywords}': this.state.seedKeywordsInput,
      '${relatedKeywords}': this.state.relatedKeywords.filter(k => k.selected).map(k => k.keyword).join(', '),
      '${topicPillar}': this.state.topicPillar,
      '${topicClusterTitle}': this.state.topicClusterTitle,
      '${blogTitle}': this.state.blogTitleInput,
      '${blogIntro}': this.state.blogIntroInput,
    }

    const generatedResult = await generateText(this.orgId, this.ms, promptSequence, replacements, this.updateLoaderUi.bind(this));
    const blogOutlineSuggestions = extractNumberedListToArray(generatedResult.text).map(e => csvToLineBreak(e)).map(e => ({text: e, selected: false}));
    const blogOutlineSubtopicSuggestions = getBlogOutlineSubtopicSuggestions(blogOutlineSuggestions, this.state.blogOutlineInput);
    this.setState(prevState => ({ ...prevState, blogOutlineSuggestions, blogOutlineSubtopicSuggestions }));
  }

  async generateBlog() {
    const blogTitle = this.state.blogTitleInput;
    const blogIntro = this.state.blogIntroInput;
    const promptSequence = 'blog.article';
    const replacements = {
      '${userPersona}': this.personas.find(p => p.id === this.state.selectedPersonaId).title,
      '${seedKeywords}': this.state.seedKeywordsInput,
      '${relatedKeywords}': this.state.relatedKeywords.filter(k => k.selected).map(k => k.keyword).join(', '),
      '${topicPillar}': this.state.topicPillar,
      '${topicClusterTitle}': this.state.topicClusterTitle,
      '${blogTitle}': blogTitle,
      '${blogIntro}': blogIntro,
      '${blogOutline}': lineBreakToCsv(this.state.blogOutlineInput),
    }
    const extraPostData = {
      createBlogParams: {
        title: blogTitle,
        metaTitle: blogTitle,
        metaDescr: blogIntro,
        metaKeywords: '',
        usage: {
          promptTokensConsumed: this.state.promptTokensConsumed,
          completionTokensConsumed: this.state.completionTokensConsumed,
          totalTokensConsumed: this.state.totalTokensConsumed,
          relatedKeywordRequests: this.state.relatedKeywordRequests,
        }
      }
    };

    const updateTopicParams = {orgId: this.orgId, topicId: this.state.topicId, topics: this.topics};
    let createdBlogUiUpdated = false;
    const onJobPoll = async (jobResponse, intervalId, resolve, reject) => {
      try {
        if (jobResponse.info.createdBlogId) {
          clearInterval(intervalId);
          createdBlogUiUpdated = true;
          const {orgId, topicId, topics} = updateTopicParams;
          const blogId = jobResponse.info.createdBlogId;
          await addBlogToTopic(orgId, topicId, blogId, topics);
          this.setState(prevState => ({
            ...prevState,
            createdBlogId: blogId,
            fetchState: ApiHelper.State.READY,
          }));
          resolve({createdBlogId: jobResponse.info.createdBlogId});
          return true;
        }
      } catch (err) {
        reject(err);
        return true;
      }
      return false;
    };
    const generatedResult = await generateText(this.orgId, this.ms, promptSequence, replacements, this.updateLoaderUi.bind(this), extraPostData, onJobPoll);
    const {createdBlogId} = generatedResult;
    console.log('GENERATED BLOG ID: ' + createdBlogId);
    //window.location.href = `${this.baseRoute()}/blogs/content/${createdBlogId}`;
  }

  startOver() {
    const {personaId, seedKeywordsInput, topicPillar, topicClusterTitle, topicId, plannerStateIndex} = extractQueryStringParams(this.queryString);
    this.setState(prevState => ({
      ...prevState,
      topicPillar,
      topicClusterTitle,
      topicId,
      plannerState: PLANNER_STATES[plannerStateIndex],
      selectedPersonaId: personaId,
      seedKeywordsInput: seedKeywordsInput,
      seedKeywords: keywordsInputToList(seedKeywordsInput),
      seedKeywordsSuggestions: [],
      relatedKeywords: [],
      negativeKeywordsInputValue: '',
      blogTitleInput: '',
      blogTitleSuggestions: [],
      blogIntroInput: '',
      blogIntroSuggestions: [],
      blogOutlineInput: '',
      blogOutlineSuggestions: [],
      blogOutlineSubtopicSuggestions: [],
      createdBlogId: '',
      promptTokensConsumed: 0,
      completionTokensConsumed: 0,
      totalTokensConsumed: 0,
      relatedKeywordRequests: 0,
    }));
    this.initialiseNewPlannerState(PLANNER_STATES[plannerStateIndex]);
  }

  renderBelowTable() {
    const showSummary = this.state.plannerState !== 'persona';
    const plannerStateIndex = PLANNER_STATES.indexOf(this.state.plannerState);
    const selectedPersona = this.personas.find(p => p.id === this.state.selectedPersonaId);
    const persona = selectedPersona ? selectedPersona.title : '';
    const blogTitle = this.state.blogTitleInput;
    const blogIntro = this.state.blogIntroInput;
    return(
      <div className='tw-ctr w-full max-w-4xl mx-auto'>
        {
          showSummary &&
          <div className='w-full m-2 p-4 border-2 border-gray-100 rounded-xl'>
            <p className='text-sm'>Summary</p>
            <br/>
            <p className='text-sm'>Persona: <strong>{persona}</strong></p>
            {this.state.seedKeywords.length > 0 && plannerStateIndex > PLANNER_STATES.indexOf('seedKeywords') && <p className='text-sm'>Seed keywords: <strong>{this.state.seedKeywords.join(', ')}</strong></p>}
            {this.state.relatedKeywords.length > 0 && plannerStateIndex > PLANNER_STATES.indexOf('relatedKeywords') && <p className='text-sm'>Related keywords: <strong>{this.state.relatedKeywords.filter(k => k.selected).map(k => k.keyword).join(', ')}</strong></p>}
            {this.state.topicPillar && plannerStateIndex >= PLANNER_STATES.indexOf('titles') && <p className='text-sm'>Topic pillar: <strong>{this.state.topicPillar}</strong></p>}
            {this.state.topicClusterTitle && plannerStateIndex >= PLANNER_STATES.indexOf('titles') && <p className='text-sm'>Topic cluster title: <strong>{this.state.topicClusterTitle}</strong></p>}
            {plannerStateIndex > PLANNER_STATES.indexOf('titles') && <p className='text-sm'>Title: <strong>{blogTitle}</strong></p>}
            {plannerStateIndex > PLANNER_STATES.indexOf('intro') && <p className='text-sm'>Introduction: <strong>{blogIntro}</strong></p>}
            {plannerStateIndex > PLANNER_STATES.indexOf('outline') && <p className='text-sm'>Outline: <strong>{lineBreakToCsv(this.state.blogOutlineInput)}</strong></p>}
            {
              /*
            <br/>
            <p className='text-sm'>Prompt tokens: <strong>{this.state.promptTokensConsumed}</strong></p>
            <p className='text-sm'>Completion tokens: <strong>{this.state.completionTokensConsumed}</strong></p>
            <p className='text-sm'>Total tokens: <strong>{this.state.totalTokensConsumed}</strong></p>
            <p className='text-sm'>Related keyword requests: <strong>{this.state.relatedKeywordRequests}</strong></p>
            <p className='text-sm'>OpenAi cost: <strong>{`${(this.state.totalTokensConsumed * 0.002 * 0.001).toFixed(3)}$ (0.002$ / 1K tokens)`}</strong></p>
            <p className='text-sm'>Dataforseo cost: <strong>{`${(this.state.relatedKeywordRequests * 0.075).toFixed(3)}$ (0.075$ / request)`}</strong></p>
            <p className='text-sm'>Total cost: <strong>{`${((this.state.totalTokensConsumed * 0.002 * 0.001) + (this.state.relatedKeywordRequests * 0.075)).toFixed(3)}$`}</strong></p>
            */
            }
          </div>
        }
        {
          this.state.plannerState === 'persona' &&
          <div className='w-full m-2 p-4 border-2 border-gray-100 rounded-xl'>
            <div>
              <select name='personas' onChange={this.onPersonaChange} value={this.state.selectedPersonaId} className='w-full block p-2.5 grow text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-gray-300'>
                <option key='none' value='none'>Select persona</option>
                {this.personas.map(p => <option key={p.id} value={p.id}>{p.title}</option>)}
              </select>
            </div>
          </div>
        }
        {
          this.state.plannerState === 'seedKeywords' &&
          <div className='w-full m-2 p-4 border-2 border-gray-100 rounded-xl'>
            <div className='relative w-full'>
              <input type='search' value={this.state.seedKeywordsInput} onChange={this.onSeedKeywordsInputChange} className='block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-gray-100 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500' placeholder='Enter or select up to 20 seed keywords (comma separated)' required></input>
            </div>
            <div className='mt-4'>
              <div className='flex'>
                <p className='text-sm mr-2.5'>Suggested keywords</p>
                <button type='button' className='text-sm text-blue-400' onClick={this.fetchSuggestedSeedKeywords}>Refresh</button>
              </div>
              <div className='flex flex-wrap'>
                {this.state.seedKeywordsSuggestions.map(k => <div className={`m-1 p-2 rounded bg-${k.selected ? 'yellow' : 'blue'}-100 break-normal cursor-pointer`} key={k.keyword} onClick={() => this.onSuggestedKeywordClick(k)}>{k.keyword}</div>)}
              </div>
            </div>
          </div>
        }
        {
          this.state.plannerState === 'relatedKeywords' &&
          <div className='w-full m-2 p-4 border-2 border-gray-100 rounded-xl'>
            <p className='text-md mr-2.5'>Select up to 100 related SEO keywords to rank</p>
            <br/>
            <div className='relative w-full'>
              <input type='search' value={this.state.negativeKeywordsInputValue} onChange={this.onNegativeKeywordsInputChange} className='block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-gray-100 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500' placeholder='Enter negative keywords (comma separated)' required></input>
            </div>
            <br/>
            <KeywordsTable
              mode='planner'
              keywords={this.state.relatedKeywords}
              excludeKeywords={this.state.negativeKeywordsInputValue.split(',').map(k => k.trim()).filter(k => k !== '')}
              onSelectionChange={this.onRelatedKeywordSelectionChange}
              isAllKeywordsSelected={this.state.relatedKeywords.every(k => k.selected)}
              onSelectAllChange={this.onAllRelatedKeywordsSelectionChange}
            />
          </div>
        }
        {
          this.state.plannerState === 'titles' &&
          <div className='w-full m-2 p-4 border-2 border-gray-100 rounded-xl'>
            <div className='relative w-full'>
              <input value={this.state.blogTitleInput} onChange={this.onBlogTitleInputChange} className='block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-gray-100 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500' placeholder='Enter or select a blog title' required></input>
            </div>
            <div className='mt-4'>
              <div className='flex'>
                <p className='text-sm mr-2.5'>Select a title</p>
                <button type='button' className='text-sm text-blue-400' onClick={this.fetchSuggestedTitles}>Refresh</button>
              </div>
              <div className='w-full flex flex-col'>
                {this.state.blogTitleSuggestions.map(t => <div className={`w-full m-1 p-2 rounded bg-${t.selected ? 'yellow' : 'blue'}-100 break-normal cursor-pointer`} key={t.text} onClick={() => this.onToggleMenuClick(t, 'blogTitleSuggestions', 'blogTitleInput')}>{t.text}</div>)}
              </div>
            </div>
          </div>
        }
        {
          this.state.plannerState === 'intro' &&
          <div className='w-full m-2 p-4 border-2 border-gray-100 rounded-xl'>
            <div className='relative w-full'>
              <textarea value={this.state.blogIntroInput} onChange={this.onBlogIntroInputChange} className='block p-2.5 w-full h-40 z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-gray-100 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500' placeholder='Enter or select a blog intro' required></textarea>
            </div>
            <div className='mt-4'>
              <div className='flex'>
                <p className='text-sm mr-2.5'>Select an introduction</p>
                <button type='button' className='text-sm text-blue-400' onClick={this.fetchSuggestedIntros}>Refresh</button>
              </div>
              <div className='w-full flex flex-col'>
                {this.state.blogIntroSuggestions.map(t => <div className={`w-full m-1 p-2 rounded bg-${t.selected ? 'yellow' : 'blue'}-100 break-normal cursor-pointer`} key={t.text} onClick={() => this.onToggleMenuClick(t, 'blogIntroSuggestions', 'blogIntroInput')}>{t.text}</div>)}
              </div>
            </div>
          </div>
        }
        {
          this.state.plannerState === 'outline' &&
          <div className='w-full m-2 p-4 border-2 border-gray-100 rounded-xl'>
            <div className='relative w-full'>
              <textarea value={this.state.blogOutlineInput} onChange={this.onBlogOutlineInputChange} className='block p-2.5 w-full h-40 z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-gray-100 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500' placeholder='Enter or select a blog outline' required></textarea>
            </div>
            <div className='mt-4'>
              <div className='flex'>
                <p className='text-sm mr-2.5'>Select an outline</p>
                <button type='button' className='text-sm text-blue-400' onClick={this.fetchSuggestedOutlines}>Refresh</button>
              </div>
              {
                this.state.blogOutlineInput === '' &&
              <div className='w-full flex flex-col'>
                {this.state.blogOutlineSuggestions.map(t => <div className={`w-full m-1 p-2 rounded bg-${t.selected ? 'yellow' : 'blue'}-100 break-normal cursor-pointer whitespace-pre-wrap`} key={t.text} onClick={() => this.onBlogOutlineOptionClick(t, 'blogOutlineInput')}>{t.text}</div>)}
              </div>
              }
              {
                this.state.blogOutlineInput !== '' &&
              <div className='flex flex-wrap'>
                {this.state.blogOutlineSubtopicSuggestions.map(k => <div className={`w-full m-1 p-2 rounded bg-${k.selected ? 'yellow' : 'blue'}-100 break-normal cursor-pointer`} key={k.text} onClick={() => this.onBlogOutlineSubtopicClick(k)}>{k.text}</div>)}
              </div>
              }
            </div>
          </div>
        }
        {
          this.state.plannerState === 'generatingBlog' &&
          <div className='w-full m-2 p-4 border-2 border-gray-100 rounded-xl'>
            <div className='mt-4'>
              <p className='text-sm mr-2.5'>Blog generation is in progress and will be available under the 'Blogs' section. You can leave this page or start over while the blog is being generated. <a href={`${this.baseRoute()}/blogs`} target='_blank' className='text-blue-600 hover:text-blue-800'>Click here</a> to view the current status of the blog.</p>
            </div>
          </div>
        }
        <br/>
        <div className='flex justify-center'>
          <button type='button' className='p-2.5 mr-2.5 w-36 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 disabled:opacity-50 disabled:bg-blue-700' disabled={this.state.plannerState === 'persona' || (this.state.plannerState === 'titles' && this.state.topicId)} onClick={this.onBackButtonClick}>Back</button>
          <button type='button' className='p-2.5 mr-2.5 w-36 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 disabled:opacity-50 disabled:bg-blue-700' onClick={this.onNextButtonClick}>{getNextBtnTextForState(this.state.plannerState)}</button>
        </div>
        <br/>
      </div>
    )
  }

  updateLoaderUi(isLoading) {
    this.setState({
      fetchState: isLoading ? ApiHelper.State.LOADING : ApiHelper.State.READY
    });
  }
}
